<section class="paper-pains-section common-padd">
    <div class="container">
        <div class="row">
            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto">

                <div class="paper-pains-box">
                    <div class="row ">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <h2 class="size-fifty">We feel your pain</h2>
                            <div class="paper-box">
                                <div>
                                    <span>
                                        <h3 class="size-fourty mb-0">30-200% ROI</h3>
                                        <!-- <img src="./images/home/red-errow.svg" alt=""
                                            class="img-fluid paper-box-img"> -->
                                    </span>

                                    <p class="common-para mb-0">
                                        Implementing automation in the office generates 30-200% ROI in the first year,
                                        mainly in labor savings
                                    </p>
                                </div>

                            </div>
                            <div class="paper-box">
                                <div>
                                    <span>
                                        <h3 class="size-fourty mb-0 ">4 weeks wasted</h3>
                                        <!-- <img src="./images/home/red-errow.svg" alt=""
                                            class="img-fluid paper-box-img"> -->
                                    </span>

                                    <p class="common-para mb-0">
                                        An average of 4 weeks is lost each year waiting on misfiled, mislabeled,
                                        untracked or lost documents
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="paper-box">
                                <div>
                                    <span>
                                        <h3 class="size-fourty mb-0">52% error reduction</h3>
                                        <!-- <img src="./images/home/yellow-errow.svg" alt=""
                                            class="img-fluid paper-box-img"> -->
                                    </span>
                                    <p class="common-para mb-4">Intelligent Document Processing can reduce the risk of
                                        errors
                                        by 52% or more</p>
                                </div>
                            </div>
                            <div class="paper-box">
                                <div>
                                    <span>
                                        <h3 class="size-fourty mb-0">61 trips per week</h3>
                                        <!-- <img src="./images/home/errow_icon.svg" alt=""
                                            class="img-fluid paper-box-img"> -->
                                    </span>
                                    <p class="common-para mb-0">Typical office worker makes 61 trips per week to the fax
                                        machine, printer and copier
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div class="identify-with-paper-pains">
                            <img src="../../../assets/Image/bg-img-paper-pains.png" alt="" class="img-fluid paper-pains-bg-img">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
