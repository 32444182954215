import { Component, Input } from '@angular/core';
import { productDucomentH } from 'src/app/interfaces/interfaces';
import { Router } from '@angular/router'
@Component({
  selector: 'app-product-l-image-right-text',
  templateUrl: './product-l-image-right-text.component.html',
  styleUrls: ['./product-l-image-right-text.component.scss']
})
export class ProductLImageRightTextComponent {

  constructor(private router : Router){}

  @Input() imgSrc:string=" ";
  @Input() contentH!:productDucomentH;
  blue_circle:string="../../../../assets/Image/document-handling/blue-circle-tick.svg"

  navigateTo() {
    if(this.contentH.textHyper === 'Deep Search') {
      this.navigateTodeepsearch();
    }
    console.log("text", this.contentH.textHyper)

    if(this.contentH.textHyper === 'Join dox2U') {
      this.navigateToGuestaccess();
    }

  }

  navigateTodeepsearch() {
    this.router.navigate(['/deep-search']);
  }

  navigateToGuestaccess() {
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
      const viewportHeight = window.innerHeight;
      const elementHeight = gettingStartedElement.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
    const scrollOptions: ScrollToOptions = {
      top: gettingStartedElement.offsetTop - yOffset,
      behavior: 'smooth',
    };
    window.scrollTo(scrollOptions);
     // gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
