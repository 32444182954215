<section class="workspaces-banner document-handling-banner">
    <app-product-left-text-with-button [imgSrc] = "deephero_img" [contentH]="deepSearchHeroText"></app-product-left-text-with-button >
    </section>
    <!-- <section class="workspaces-banner document-handling-banner overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div class="row d-flex align-items-center">
      
                <div class="col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div class="workspace-banner-content deep-search-banner-content">
                    <h6 class="heading-6">Deep search</h6>
                    <h1 class="heading-1">Introducing our sharp edge,<span class="blue">  precise search technology</span></h1>
                    <p class="common-para">We make it easier for you to search, retrieve, and even organize your documents.</p>
                    <a (click)="scrollToGettingStarted($event)" target="_self"> <button class="button-primary">Start retrieving</button></a>
                  </div>
                </div>
      
                <div class="col-xxl-7 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                  <div data-aos="fade-left" data-aos-duration="2500" class="aos-init aos-animate">
                    <img src="../../../../assets/Image/deepSearch/dox2u_search_banner.svg" alt="document retrieval system" class="img-fluid w-100">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    <section class="deep-search-banner common-padd">
        <div class="container">
          <!-- smart-cabinet-cards -->
          <div class="row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex align-items-center">
              <div class="deep-search-banner-section">
                <h2 class="heading-2">Why Deep Search is <span class="red">NOT</span> just another Search functionality.</h2>
                <p class="common-para">Not all DMSs provide a search that’s smart, convenient, and quick. Here’s why!</p>
              </div>
            </div>
      
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 deep-search-card-container">
              <div class="deep-search-card"> 
                <div>
                  <img src="../../../../assets/Image/deepSearch/grading.svg" alt="" class="img-fluid">
                
                <h4 class="heading-4">Content is the new parameter</h4>
                  <p class="common-para">With Deep Search, you can easily search and get to a document even by searching the smallest bits of content that it contains! Smart, isn’t it?</p>
               </div>
               
              </div>
            </div>
      
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 deep-search-card-container">
              <div class="deep-search-card">
                <div>
                  <img src="../../../../assets/Image/deepSearch/dashboard.svg" alt="" class="img-fluid">
                  <h4 class="heading-4">We don’t create virtual walls</h4>
                  <p class="common-para">Unlike a lot of other repositories and DMSs, dox2U does not create silos. No need to visit folders after folders to reach your document. Convenient, indeed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    
     
     <section class="solutions-fall-flat common-padd">
        <div class="container">
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-xxl-12">
              <div class="solutions-fall-fat-content mx-auto">
                <h3 class="heading-3">dox2U's deep search delivers intelligent document retrieval</h3>
                <p class="common-para">There is a reason as to why the nomenclature of this feature has been the way it is. </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div class="row d-flex align-items-center small-screens">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                  <div class="retrieval-as-easy">
                    <h6 class="heading-6 blue">CONTENT-BASED SEARCH </h6>
                    <h3 class="heading-3">Not entitled to titles only! </h3>
                    <div class="d-flex justify-content-between align-items-start">
                        <span class="line-rect"></span><p class="common-para  feature-align"> dox2U’s Deep Search takes you one step further in the process of document retrieval</p>
                    </div>
                   
                    <!-- new code -->
                    <div class="d-flex justify-content-between align-items-start">                 
                      <span class="line-rect"></span> <p class="common-para  feature-align">The titles of a document are no longer a mandatory search parameter. We got a bunch of them!</p>
                    </div>
                    <div class="d-flex justify-content-between align-items-start">
                      <span class="line-rect"></span> <p class="common-para  feature-align">Search for the smallest bits of content included in your doc, and retrieve the right one at your fingertips.  </p>
                    </div>
      <!-- new code end -->
                 
      
                   
                  </div>
                </div>
      
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                  <img [src]="searchContent_img" alt="digital document retrieval solution" class="img-fluid aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
                </div>
      
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="holistic-view common-padd">
        <div class="container">
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div class="row d-flex align-items-center container-row">
      
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start">
                  <img [src]="searchfiter_img" alt="dox2U’s Deep Search comes loaded with a multitude of retrieval filters making search easy and convenient." class="img-fluid aos-init aos-animate" data-aos="fade-right" data-aos-duration="2500">
                </div>
      
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end ">
                  <div class="retrieval-as-easy">
                    <h6 class="heading-6 blue">FILTER THROUGH </h6>
                    <h3 class="heading-3">Filter it out! </h3>
      <!-- new code -->
      <div class="d-flex justify-content-between align-items-start">
        <span class="line-rect"></span> <p class="common-para  feature-align"> Streamline your document retrieval process by adopting dox2U’s smart search filters.</p>
                    </div>
                   
                    <!-- new code -->
                    <div class="d-flex justify-content-between align-items-start">
                      <span class="line-rect"></span> <p class="common-para  feature-align">With advanced filters, you get to seep through an assortment of documents seamlessly!</p>
                    </div>
                    <div class="d-flex justify-content-between align-items-start">
                      <span class="line-rect"></span> <p class="common-para  feature-align">Filter through all the documents and fetch the ones you need in a matter of seconds!  </p>
                    </div>
      <!-- new code  end -->
      
      
      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="physical-cabinets common-padd">
        <div class="container">
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div class="row d-flex align-items-center small-screens">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                  <div class="retrieval-as-easy">
                    <h6 class="heading-6 blue"> SEARCH  RELEVANCE </h6>
                    <h3 class="heading-3">Sort things out like a pro!</h3> 
      
                    <!-- new code -->
      <div class="d-flex justify-content-between align-items-start">
        <span class="line-rect"></span> <p class="common-para  feature-align">Prioritize which document do you want to see first as you sort them by date, time, content or title.</p>
                    </div>
                   
                    <!-- new code -->
                    <div class="d-flex justify-content-between align-items-start">
                      <span class="line-rect"></span> <p class="common-para  feature-align">This is especially useful when Deep Search retrieves multiple documents with similar underlying content.</p>
                    </div>
                    <div class="d-flex justify-content-between align-items-start">
                      <span class="line-rect"></span> <p class="common-para  feature-align">With multiple view options, get your documents sorted the way you prefer to see them.  </p>
                    </div>
      <!-- new code  end -->
                  </div>
                </div>
      
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                  <img [src]="searchShort_img" alt="document retrieval software" class="img-fluid aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
                </div>
      
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <app-more-feature-four-card ></app-more-feature-four-card> -->
      <section class="more-features g-access" id="check12">
        <div class="container">
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div class="row">
                  <div class="more-features-top-content mx-auto">
                    <h2 class="heading-2">More features</h2>
                  </div>
              </div>
              <div class="row d-flex  justify-content-center feature-card-main">
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 cards-container">
                <a routerLink="/guest-access" class="feature_text">
                  <div class="more-features-box">
                    <img src="../../../assets/Image/security-reliability/guest-sharing.svg" alt="Guest sharing" class="img-fluid">
                    <h4 class="heading-4">Guest sharing</h4>
                    <p>Upload your docs to your Workspace</p>
                  </div>
                </a>
                </div>
      
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <a routerLink="/text-extraction"  class="feature_text">
                  <div class="more-features-box">
                    <img src="../../../../assets/Image/smart-cabinet/doc-handling-green.svg" alt="Text extraction" class="img-fluid">
                    <h4 class="heading-4">Text extraction</h4>
                    <p>Track the status of your documents</p>
                  </div>
                </a>
                </div>
      
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <a routerLink="/security"  class="feature_text">
                  <div class="more-features-box">
                    <img src="../../../../assets/Image/smart-cabinet/workspaces.svg"  alt="Document handling" class="img-fluid">
                    <h4 class="heading-4">Security & reliability</h4>
                    <p>AES-256-GCM powered encryption</p>
                  </div>
                </a>
                </div>
      
                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 "> 
                <a routerLink="/feature"    class="feature_text">
                  <div class="more-features-box view-all-box">
                    <img src="../../../assets/Image/security-reliability/view-all.svg" alt="View all" class="img-fluid view-all-plus">
                    <h4 class="heading-4">View all</h4>
                   
                  </div>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
<section class="footer-bg">
    <app-getting-started id="gettingStarted"></app-getting-started>
</section>

<app-gradient-divider></app-gradient-divider>
