import { Component } from '@angular/core';

@Component({
  selector: 'app-help-business',
  templateUrl: './help-business.component.html',
  styleUrls: ['./help-business.component.scss']
})
export class HelpBusinessComponent {

}
