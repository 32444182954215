<section class="build-bg overflow-hidden">
    <app-product-left-text-with-button [contentH]="buildforEveryone"[imgSrc]="build_banner_img"></app-product-left-text-with-button>

</section>
<section class="dox2u-changed-game common-padd overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
  
          <div class="common-changed-box heading-box">
            <div class="common-changed-box-content">
             
              <h4 class="h2">What makes dox2U so versatile?</h4>
             
            </div>
          </div>
  
          <div class="common-changed-box ">
            <div class="common-changed-box-content">
              <img src="../../../../assets/Image/make-assesible/assignment-turned.svg" alt="" class="img-fluid">
              <h4 class="h4">We don’t change the way you work</h4>
              <p class="common-para">dox2U is cofigurable! We wanted to keep it simple by letiing you define how you want your DMS to run and not the other way around. dox2U does not require any expensive customisations or external consultancy! </p>
            </div>
          </div>
  
        </div>
  
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
  
          <div class="common-changed-box errow-box">
            <div class="common-changed-box-content">
              <img src="../../../../assets/Image/make-assesible/account_balance.svg" alt="" class="img-fluid">
              <h4 class="h4">Industry Agnostic</h4>
              <p class="common-para">dox2U was purpose built to help eliminate document management issues faced by organizations irrespective of nature of your business. Adopting dox2U is simple even if you are a small law firm or a large pharmaceutical manufacturer, we are built for all! </p>
            </div>
          </div>
  
          <div class="common-changed-box">
            <div class="common-changed-box-content">
              <img src="../../../../assets/Image/make-assesible/payment.svg" class="img-fluid">
              <h4 class="h4">Plans that suit your needs</h4>
              <p class="common-para">dox2U offers plans addressing the needs of a wide spectrum of users. While are lifetime free Base plan is specially built for smaller teams, our paid plans - Growth &amp; Pro are especially created for larger teams; loaded with advanced features.</p>
            </div>
          </div>  
  
        </div>
      </div>
    </div>
  </section>
  <section class="management-task common-padd bottom-padd">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="management-task-top-content">
            <h2 class="h4">Unlike Legacy On-Prem DMSs, dox2U does not break the bank</h2>
            <p class="common-para m-0">Most DMSs in the market need expensive customizations and require high technical support to even get started. Being a SaaS based cloud service, dox2U is easy to get started with and does not require any threshold support or customisations! </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="footer-bg " >
    <app-getting-started></app-getting-started>
  </section>
  <app-gradient-divider></app-gradient-divider>

