import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-feature',
  templateUrl: './new-feature.component.html',
  styleUrls: ['./new-feature.component.scss']
})
export class NewFeatureComponent {
  constructor(private router: Router) {}

  scrollTowhatsappUpdateFeature() {
    this.router.navigate(['/feature']).then(() => {
      setTimeout(() => {
        const whatsappIntegrationText = document.getElementById('whatsappIntegrationText');
        if (whatsappIntegrationText) {
          const viewportHeight = window.innerHeight;
          const elementHeight = whatsappIntegrationText.clientHeight;
          const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
          top: whatsappIntegrationText.offsetTop - yOffset + 200,
          behavior: 'smooth',
        };
        window.scrollTo(scrollOptions);
          //elementAfterNavigation.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    });
  }

  navigateToDockets() {
    this.router.navigate(['/dockets']);
  }
  scrollTonewdocviewerFeature() {
    this.router.navigate(['/feature']).then(() => {
      setTimeout(() => {
        const whatsappIntegrationText = document.getElementById('docViewerText');
        if (whatsappIntegrationText) {
          const viewportHeight = window.innerHeight;
          const elementHeight = whatsappIntegrationText.clientHeight;
          const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
          top: whatsappIntegrationText.offsetTop - yOffset + 200,
          behavior: 'smooth',
        };
        window.scrollTo(scrollOptions);
          //elementAfterNavigation.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    });
  }

  

}
