
    <div class="container overflow-hidden">
      <div class="">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="row d-flex align-items-center main-container justify-content-between">
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start startposition endposition p-0">
              <img src={{imgSrc}} alt="document handling" class="img-fluid img-size aos-init aos-animate" data-aos="fade-right" data-aos-duration="2500">
            </div>
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0">
              <div class="workspace-content approve-your-document-content">
                <h6 class="heading-6">{{contentH.heading}} </h6>
                <h3 class="heading-3">{{contentH.subTitle}}</h3>
               
                <ul class="m-0 p-0">
                  <li class="d-flex "><span class="line-rect"></span><p class="common-para">{{contentH.text1}}</p></li>
                  <li class="d-flex "><span class="line-rect"></span><p class="common-para">{{contentH.text2}}</p></li>
                  <li class="d-flex "><span class="line-rect"></span><p class="common-para">{{contentH.text3}}</p></li>
                </ul>
                <div class="errow-btn">
                  <a (click)="navigateTo()">
                    <button class="cabinet-btn">
                    {{contentH.textHyper}}
                      <img src="../../../assets/Image/document-handling/blue-errow.svg" alt="" class="img-fluid">
                    </button>
                  </a>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 