import { Component } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent {
  testimonialItems = [
    {
      imageUrl: 'assets/Image/goal.svg',
      content:
        'Our business processes and workflows have been simplified with dox2U\'s seamless integration and secure storage. It has improved our efficiency, saved time and provided better document control, compliance, and visibility, resulting in better-informed decisions.',
      author: 'Anurag Bajpai, CEO',
      company: 'Autope',

    },
    {
      imageUrl: 'assets/Image/thirdC.svg',
      content:
        'The integration of dox2U with Tally, SAP, and other accounting software simplifies document management and improves audit efficiency. It\'s a game-changer for small and medium businesses, with the potential to revolutionize document management.',
      author: 'CA Vivek Agarwal',
      company: 'Kushal Jindal & Co',

    },
    {
      imageUrl: 'assets/Image/firstc.svg',
      content:
        'dox2U transformed our real estate business document management. Its efficient retrieval system, and user-friendly interface, simplifies our operations, and increases productivity. Text extraction saves time, and we can access and share documents with ease. Thanks, dox2U!',
      author: 'Neeraj Gambhir, Director',
      company: 'Gambhir Housing India',

    },
  ];
}
