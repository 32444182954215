import { Injectable, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
   providedIn: 'root'
})
export class SEOService { 
   constructor(private title: Title, @Inject(DOCUMENT) private doc: any) {
   }
   setPageTitle(title: string) {
      this.title.setTitle(title);
   }   
   getPageTitle() {
      return this.title.getTitle();
   }
   // createLinkForCanonicalURL() {
   //    let link: HTMLLinkElement = this.doc.createElement('link');
   //    link.setAttribute('rel', 'canonical');
   //    this.doc.head.appendChild(link);
   //    link.setAttribute('href', this.doc.URL);
   //    console.log("link", link)
   // }

   createLinkForCanonicalURL() {
      /**
       * Check if the canonical link already exists
       *  */ 
      let existingLink: HTMLLinkElement = this.doc.querySelector('link[rel="canonical"]');
      
      if (existingLink) {
         /**
          * If the canonical link exists, update its href attribute
          */
         existingLink.setAttribute('href', this.doc.URL);
      } else {
         /**
          * If no canonical link exists, create and append it
          */
         let link: HTMLLinkElement = this.doc.createElement('link');
         link.setAttribute('rel', 'canonical');
         link.setAttribute('href', this.doc.URL);
         this.doc.head.appendChild(link);
      }

      console.log("Canonical URL set to:", this.doc.URL);
   }
} 