
<div class="container productLeft overflow-hidden">
    <div class="">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto image-description">
        <div class="row d-flex align-items-center row-direction p-3">

          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center flex-wrap content-feature">
            <div class="workspace-banner-content document-handling-banner-content image-content">
              <h6 class="heading-6 text-uppercase">{{contentDescribeImage.heading}}</h6>
              <h1 class="heading-1">{{contentDescribeImage.subTitle}}</h1>
              <p class="common-para">{{contentDescribeImage.description}}</p>
              

            </div>
          </div>

          <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->

          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">

            <img src={{imgSrc}} alt="document handling system" class="img-fluid img-size">

            <video id="myVideo" width="803" height="452" controls>
              <source src={{videoUrl}} type="video/mp4">
        Your browser does not support the video tag.
            </video>

          </div>
        </div>
      </div>
    </div>
  </div>
