<section class="headerSection">
    <div class="header" id="headerBG">
        <div class="d-flex justify-content-between align-items-center whitehead">
            <a href="https://dox2u.com/" target="_blank"><img src="../assets/Image/footer-logo.svg" width="119"
                    height="59" class="logo" alt="Document Management Software - dox2U"></a>
            <a href="https://calendly.com/dox2u-dms/dox2u-demo" target="_blank"><button
                    class="common-btn">Request a demo</button></a>
        </div>

    </div>
    <div class="header" id="imageWhite" style="display: none;">
        <div class="d-flex justify-content-between align-items-center whitehead">
            <a href="https://dox2u.com/" target="_blank"><img src="../assets/Image/footer-logo.svg" width="119" height="59"
                    class="logo" alt="Document Management Software - dox2U"></a>
            <a href="https://calendly.com/dox2u-dms/dox2u-demo" target="_blank"><button
                    class="common-btn ">Request a demo</button></a>
        </div>

    </div>
</section>

<section class="sceduleDemo ">
    <div class="container contwidth">
        <div class="d-flex justify-content-center align-items-center scheduleDemo w-100">
            <div class="HEADING">

                <!--  -->
                <div id="demo" class="carousel slide headingSlider" data-ride="carousel">

                    <!-- Indicators -->
                    <ul class="carousel-indicators">
                      <button type="button " data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                                              class="active headpara" aria-current="true" aria-label="Slide 1"></button>
                                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                              aria-label="Slide 2" class="headpara"></button>
                                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                              aria-label="Slide 3" class="headpara"></button>
                                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                                              aria-label="Slide 4" class="headpara"></button>
                    </ul>
                    
                    <!-- The slideshow -->
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <h2 class="textWhite heading1">Is your information stored and exchanged on paper?
                                              </h2>
                      </div>
                      <div class="carousel-item">
                         <h2 class="textWhite heading1">Are you dealing with documents scattered across
                                                  different offices?</h2>
                      </div>
                      <div class="carousel-item">
                       <h2 class="textWhite heading1">Do you still print & file documents from your ERP /
                                                  CRM?
                                              </h2>
                      </div>
                      <div class="carousel-item">
                      <h2 class="textWhite heading1">Are your critical documents siloed in individual
                                                  email boxes?
                                              </h2>
                      </div>
                    </div>
                   
                  </div>
            </div>
            <div class="d-flex flex-column width70">
                <div id="formExpertAdvise" *ngIf="showConsultationForm">
                    <h1 class="mb-0 textWhite para">dox2U: Smart Document Management System
                        <br> Get Expert Advice.
                        Schedule Free Consult Now!
                    </h1>
                    <div class="d-flex- flex-column mt-3">
                        <form [formGroup]="consultForm" (ngSubmit)="onSubmit()"
                            id="dox2uform">
                            <div class="d-flex" style="column-gap:16px">
                                <div class="d-flex flex-column height86 mb-2" style="width:100%">
                                    <div class="outerbor">
                                        <label class=" mb-1 fosize">First Name<span
                                                style="color:red">*</span></label>
                                        <input formControlName="firstname" id="Name" class="inputfield username" name="username"
                                        (keypress)="resetFirstNameDiv($event)">
                                    </div>
                                    <p id="errlName" *ngIf="firstNameValidDiv" class="mb-0">
                                        This field cannot be empty
                                    </p>
                                </div>
                                <div class="d-flex flex-column height86 mb-2" style="width:100%">
                                    <div class="outerbor">
                                        <label class=" mb-1 fosize">Last Name<span
                                                style="color:red">*</span></label>
                                        <input formControlName="lastname"  id="lName" class="inputfield username" name="lName"
                                        (keypress)="resetLastNameDiv($event)">
                                    </div>
                                    <p id="errlName" class="mb-0" *ngIf="lastNameValidDiv">
                                        This field cannot be empty
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex" style="column-gap:16px">
                                <div class="d-flex flex-column height86 mb-3" style="width:100%">
                                    <div class="outerbor">
                                        <label class=" mb-1 fosize">Email<span
                                                style="color:red">*</span></label>
                                        <input class="inputfield" formControlName="email" type="text" id="mail" name="useremailId"
                                        (keypress)="resetEmailDiv($event)">
                                    </div>
                                    <p id="errlName" class="mb-0" *ngIf="emailValidDivEmpty">
                                        This field cannot be empty
                                    </p>
                                    <p id="errlName" class="mb-0 meserr text-danger" *ngIf="emailValidDivInvalid">
                                        Please enter valid email
                                      </p>
                                </div>
                                <div class="d-flex flex-column height86 mb-3" style="width:100%">
                                    <div  class="outerbor">
                                        <label class=" mb-1 fosize">Phone No<span
                                                style="color:red">*</span></label>
                                        <!-- <input class="inputfield getting-started-input phn-inp input-border-none inp100" type="phoneD" id="phoneD" name="phoneD"
                                    onkeypress="remErrMsg(this.value,'#errEmail')"> -->
                                        <input name="phoneD" formControlName="phone" type="" id="phoneC" placeholder=""
                                            class="inputfield" (keypress)="resetPhoneDiv($event)"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            maxlength="15">
                                    </div>
                                    <p id="errlName" class="mb-0" *ngIf="phoneValidDiv">
                                        This field cannot be empty
                                    </p>
                                </div>
                            </div>
                            <div class="Center768 align-items-center justify-content-center d-flex">
                                <!-- <button class="schedule-btn" type="submit">Schedule a consult</button> -->
                                <button class="button-primary trydox schedule-btn " type="submit" id="myButton1"><span
                                        class="hideDownload text-white">Schedule a consult</span> </button>
                                <div id="loader3" class="spinner-border text-white"
                                    style="display:none;position:absolute;" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center thankyou " id="thankyouField" *ngIf="submittedForm">
                <h1 class="mb-4 textWhite text-center para">Thank you for reaching out. Someone from our
                    team will
                    connect
                    with you shortly!</h1>
                <div>
                    <img src="../../../assets/Image/thankyou.svg">
                </div>

            </div>

        </div>
    </div>
</section>

<!-- management box -->
<section class="padding80">
    <div class="container">
        <div class="bgImage"><img src="../../../assets/Image/bg-img-paper-pains.png" height="467"></div>
        <div class="d-flex  align-items-center justify-content-center  position-relative managemnetMedia">
            <div class="d-flex flex-column position-relative leftPosition">
                <div class="box2 mb-4">
                    <h2 class="w900 mb-0 box2h1">How can our Document Management System help your business?
                    </h2>
                </div>
                <div class=" d-flex flex-column box rightBox">
                    <h4 class="headeing3 mb-2 pb-1">Get Organized: Centralize Your Files
                    </h4>
                    <p class="para2">Managing scattered documents at different locations is tough. Our document
                        management solution offers a single, secure cloud-based storage, with fast search
                        capabilities for easy access.</p>
                </div>

            </div>
            <div class="d-flex flex-column position-relative rightPosition ">
                <div class=" d-flex flex-column pb-1  box">
                    <h4 class="headeing3 mb-2">Say Goodbye to Paper: Store Information Digitally
                    </h4>
                    <p class="para2">Opt for digital document management to save physical storage, prevent
                        loss/damage and enhance access. Our solution provides secure cloud storage with
                        collaboration tools for remote teams.</p>
                </div>

                <div class=" d-flex flex-column box">
                    <h4 class="headeing3 mb-2 pb-1">Stop Printing and Filing: Digital archiving</h4>
                    <p class="para2">Skip printing and filing by importing docs from ERP/CRM to our cloud-based
                        platform. Work remotely with your team and access them anywhere using our collaboration
                        tools.</p>
                </div>
            </div>
        </div>
    </div>
</section>

 <!-- uniqueness -->
 <section>
    <div class="container paddingtb80 d-flex align-items-center justify-content-center">
        <div class="d-flex flex-column align-items-center justify-content-center ">
            <div class="paddingbottom width100 text-center mb-0">
                <h2 class="heading5 mb-3">What’s unique about dox2U?</h2>
                <p class="para4 mb-0">Cloud based Document Management System-as-a-Service.
                    No setup costs or overheads..</p>
            </div>
            <div class="d-flex align-items-center justify-content-center columnGap uniqueMedia ">


                <div class="d-flex flex-column width28">
                    <p class="docket mb-2">DOCKETS</p>
                    <h4 class="heading4 mb-3">Your process, your collection!</h4>
                    <div>
                        <div class="d-flex align-items-start mb-2">
                            <img src="../../../assets/Image/check.svg" class="margin10">
                            <p class="para5 mb-0">Reinvent document management with dox2U's Dockets. </p>
                        </div>
                        <div>
                            <div class="d-flex align-items-start mb-2">
                                <img src="../../../assets/Image/check.svg" class="margin10">

                                <p class="para5 mb-0">Create virtual collections, organize with familiar
                                    checklists, and group documents by business processes. </p>

                            </div>
                            <div>
                                <div class="d-flex align-items-start  mb-2">
                                    <img src="../../../assets/Image/check.svg" class="margin10">
                                    <p class="para5 mb-0">Experience unmatched convenience and efficiency with
                                        our customizable collection feature. Try it now. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="mediaImageone">
                    <img src="../../../assets/Image/dox2u_dms_dockets.svg" class="mediaIMGAM" style="border: 2px solid #C5CEE0 !important;
border-radius: 16px;" alt="Dockets - dox2U (Best document management software)">
                </div>

            </div>
        </div>
    </div>
</section>

 <!-- connection -->
 <section class="padding50">
    <div class="container d-flex ">
        <div class="d-flex justify-content-center align-items-center columnGap connectionMedia">
            <img src="../../../assets/Image/dox2u_dms_integration.svg" class="coonectionMediaImage"
                alt="Integration with ERP & CRM - dox2U (Best document management software)">
            <div class="d-flex flex-column width28 width50 ">
                <p class="docket mb-2 integration">INTEGRATION</p>
                <h4 class="heading4 mb-3">Connected Information Warehouse!</h4>
                <div>
                    <div class="d-flex align-items-start mb-2">
                        <img src="../../../assets/Image/check.svg" class="margin10">
                        <p class="para5 mb-0">Experience the benefits of a connected information warehouse with
                            dox2U. </p>
                    </div>
                    <div>
                        <div class="d-flex align-items-start mb-2">
                            <img src="../../../assets/Image/check.svg" class="margin10">

                            <p class="para5 mb-0">Seamlessly integrate our solution with your existing ERP and
                                CRM systems using secure REST APIs. </p>

                        </div>
                        <div>
                            <div class="d-flex align-items-start  mb-2">
                                <img src="../../../assets/Image/check.svg" class="margin10">
                                <p class="para5 mb-0">Consolidate your documented information into a common
                                    cloud repository, eliminating the need for printing and filing documents,
                                    and ensuring accessibility at all times. </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

<!-- retrival -->
<section class="paddingbottom">
    <div class="container d-flex align-items-center justify-content-center retrivalMedia ">


        <div class="d-flex flex-column width62 ">
            <p class="docket mb-2 retriveal">RETRIEVAL</p>
            <h4 class="heading4 mb-3">Retrieval made easy!</h4>
            <div>
                <div class="d-flex align-items-start mb-2">
                    <img src="../../../assets/Image/check.svg" class="margin10">
                    <p class="para5 mb-0">With dox2U's Smart Cabinet, document retrieval is effortless.
                    </p>
                </div>
                <div>
                    <div class="d-flex align-items-start mb-2">
                        <img src="../../../assets/Image/check.svg" class="margin10">

                        <p class="para5 mb-0">Our faceted search features nested filters like online
                            shopping, and with Deep Search, you can locate any content, regardless of the
                            title. </p>

                    </div>
                    <div>
                        <div class="d-flex align-items-start  mb-2">
                            <img src="../../../assets/Image/check.svg" class="margin10">
                            <p class="para5 mb-0">Our advanced ICR technology can even extract content from
                                scanned documents. Enjoy the efficiency of Smart Cabinet and Deep Search
                                today. </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- </div> -->
        <div class="align-items-center d-flex justify-content-center">
            <img src="../../../assets/Image/dox2u_dms_upload.svg" class="uploadImg" width="100%" height="460px"
                alt="Easy document retrival - dox2U (Best document management software)">
        </div>

    </div>
</section>

<!-- introducing -->
<section class="paddingbottom30">
    <div class="container">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <p class="mb-2 docket lineheight">dox2U</p>
            <h2 class="mb-3 heading5 mb-3">Introducing dox2U</h2>
            <p class="mb-0 para4">Get a quick glimpse of what dox2U can do for you</p>
            <div class="margint">
                <iframe class="iframeCSS" width="1000" height="600"
                    src="https://www.youtube.com/embed/oW4fhNxgmgg">
                </iframe>

            </div>
        </div>

    </div>
</section>

<app-testimonials></app-testimonials>
<app-eightcards-document></app-eightcards-document>
<section class="getstarted_bg" >
    <app-getting-started #GettingStartedComponent id="gettingStarted"></app-getting-started>
</section>

<section class="feature-comparision margintb common-padd  bottmp">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto">
                <div class="row">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="feature-comparision-top d-flex flex-column align-items-center mb-5 pb-3">
                            <h2 class="mb-3"><b>Frequently asked questions</b></h2>
                            <p class="para4 width469">We are here to explain everything you might want to know. Let us help</p>
                        </div>
                        <div class="feature-comparision-Acordian">
                            <div id="accordion" class="accordion">
                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseOne">
                                    <h4 class="accordian-headings mb-0" style="color: rgb(88, 88, 88);">What are the benefits of a cloud-based document
                                        management
                                        system? </h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseOne" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion" >
                                    <p>Cloud-based document management systems provide secure
                                        and
                                        remote access to documents, reduce physical storage needs, enhance
                                        collaboration,
                                        and offer scalability, flexibility, and cost-effectiveness by eliminating the
                                        need
                                        for on-premise hardware.
                                    </p>
                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseTwo">
                                    <h4 class="accordian-headings mb-0">How is dox2U different from Google drive?</h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                   <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseTwo" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>dox2U is a specialized document management system (DMS)
                                        designed for businesses, with features such as Dockets, Integration, Advanced
                                        search
                                        capabilities, and granular security settings. Google Drive is a cloud storage
                                        platform that offers basic document management capabilities but lacks the
                                        advanced
                                        features of a DMS. 
                                    </p>

                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseThree">
                                    <h4 class="accordian-headings mb-0">Does dox2U have a free trial or plan?</h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseThree" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>Yes, dox2U offers a time-limited free trial for all
                                        plans
                                        and a lifetime free Base Plan with limited features, which is suitable for
                                        individuals and small teams looking to get started on their digital journey</p>
                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFour">
                                    <h4 class="accordian-headings mb-0">How safe are documents stored on cloud?
                                    </h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseFour" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>dox2U is engineered with highest priorities to security
                                        of
                                        information and have adhered to defined practices to make sure your data
                                        remains
                                        strictly for your eyes only. With strong encryptions (both in transit and at
                                        rest)
                                        and multiple access control layers, security measure implemented are in fact
                                        better
                                        than most on-prem deployments.
                                    </p>

                                </div>
                            </div>

                            <div class="acc-section">
                                <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFive">
                                    <h4 class="accordian-headings mb-0"> How secure is dox2U's cloud storage?
                                    </h4>
                                    <img src="../../../assets/Image/plus.svg" class="plus-img">
                                    <img src="../../../assets/Image/minus.svg" class="minus-img">
                                </div>
                                <div id="collapseFive" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                    <p>dox2U has implemented rigorous security measures,
                                        including
                                        strong encryption for data in transit and at rest, and multiple access control
                                        layers to ensure the confidentiality of user data. Our security measures are
                                        better
                                        than many on-premise deployments.

                                    </p>
                             

                          
        
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div>
               
            </div>
            
        </div>
    </div>
</section>

<section class="footer">
    <div class=" d-flex justify-content-between align-items-center footerMedia">
        <div class="d-flex flex-column  footp">
            <p class="mb-2 fPara textWhite">© 2021 - 2023 All rights reserved.</p>
            <p class="mb-2 fPara textWhite">Engineered and marketed by Privet Ventures Pvt Ltd</p>


        </div>
        <div class="d-flex column18">
            <a href="https://twitter.com/Dox2U" target="_blank"><img src="../../../assets/Image/footer-twitter.svg"
                    class="social"></a>
            <a href="https://www.linkedin.com/company/dox-2u/" target="_blank">
                <img src="../../../assets/Image/footer-linkedin.svg" class="social"></a>
            <a href="https://www.instagram.com/dox2U/" target="_blank"> <img src="../../../assets/Image/footer-instagram.svg"
                    class="social"></a>

        </div>
    </div>
</section>