<section class="features-banner problem-solving overflow-hidden">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-10 mx-auto">
        <div class="row">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div
              class="features-banner-content text-center problem-solving-banner-content"
            >
              <h6 class="heading-6">Problems we are solving</h6>
              <h1 class="heading-1">
                Paper Debt is a mess and<br class="line-break" />
                continues to be so
              </h1>

              <p class="banner-text common-para mx-auto">
                Organizations still are heavily dependent on paper documents
                without realizing the costs involved.
              </p>
              <div class="d-flex justify-content-center">
                <!-- <a href="#" class="me-4"><button class="button-blank">Try for free</button></a> -->
                <a (click)="scrollToGettingStarted()">
                  <button class="button-primary" >
                    Go paperless with dox2U!
                  </button></a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row problem-image-content">
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0">
            <div class="bannerImg problem-banner-img">
              <img
                src="../../../../assets/Image/problems-we-are-solving/problem-banner.png"
                class="d-block img-fluid"
                alt="Document Management Problems"
              />
            </div>
          </div>

          <div
            class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 problem-sad-truth"
          >
            <div>
              <img
                src="../../../../assets/Image/problems-we-are-solving/sad-truth-corner.svg"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="banner-image-content">
              <h3 class="heading-3 problem-banner-heading">
                The sad <span> truth</span>
              </h3>
              <div class="banner-image-content-box">
                <h3 class="banner-bottom-border">50%</h3>
                <p class="common-para">
                  of a professional’s time is still spent searching for
                  information
                </p>
              </div>

              <div class="banner-image-content-box">
                <h3 class="banner-bottom-border">10</h3>
                <p class="common-para">
                  copies of the same document is stored in the same location
                </p>
              </div>

              <div class="banner-image-content-box">
                <h3 class="banner-bottom-border">60%</h3>
                <p class="common-para">
                  of professionals lose important information locked on paper,
                  at least thrice a day!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- go paperless -->
<section class="help-business common-padd">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
        <div class="go-paperless-top-bar mx-auto">
            <h6 class="heading-6">GO PAPERLESS!</h6>
            <h2 class="heading-2">What are the common problems with paper documents?</h2>
          </div>

          <!-- <div class="go-paperless-tabs">
            <ul class="nav nav-pills mb-3 nav-bar problem-page-tab-navbar" id="pills-tab" role="tablist">
              <li class="nav-item problem-page-tab">
                <a class="nav-links active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Efficiency &amp; Productivity</a>
              </li>
              <li class="nav-item problem-page-tab">
                <a class="nav-links" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Expensive real estate</a>
              </li>
              <li class="nav-item problem-page-tab">
                <button class="nav-links" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Control over information</button>
              </li>
            </ul>
            <div class="tab-content home-tabs-content problems-tabs-content" id="pills-tabContent">
              <div class="tab-pane fade show active problem-tab-para" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="row d-flex align-items-center small-views pay-as-you-go-small-devices">
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12">
                    <div class="problems-tab-content">
                      <h4 class="h4">Problem:</h4>
                      <p class="common-para">Paper documents are hard to manage, and even harder to retrieve. This causes frustration and brings about non-productivity in dynamic workflows. </p>
  
                      <h4 class="h4">dox2U has the solution</h4>
                      <p class="common-para">With advanced filter capabilties and precise search technology, dox2U makes retrieval of documents easy; letting you access any file that you need the most at a given time.</p>
                    </div>  
                  </div>
                  <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none "></div>
  
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-sm-center">
                    <img src="../../../../assets/Image/problems-we-are-solving/efficiency-productivity.png" alt="Efficiency &amp; Productivity" class="img-fluid drop-shadow aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
                  </div>
                </div>
  
              </div>
  
              <div class="tab-pane fade problem-tab-para active show" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="row d-flex align-items-center small-views pay-as-you-go-small-devices">
                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <div class="problems-tab-content">
                    <h4 class="h4">Problem</h4>
                    <p class="common-para">Organizations fill up files and cabinets at a high pace, without actually realizing it. This exhausts their expensive real estate which could have been otherwise used productively.</p>
  
                    <h4 class="h4">dox2U has the solution</h4>
                    <p class="common-para">With zero requirements of storing your important documents in a physical space, dox2U provides the functionality 
                    of Smart Cabinet which helps you organize your files seamlessly. </p>
                  </div>  
                </div>
                  <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none"></div>
  
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-sm-center">
                    <img src="../../../../assets/Image/problems-we-are-solving/expensive-real-estate.png" alt="Expensive Real Estate" class="img-fluid drop-shadow aos-init aos-animate" data-aos="fade-right" data-aos-duration="2500">
                  </div>
                </div>
              </div>
              <div class="tab-pane fade problem-tab-para" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="row d-flex align-items-center small-views pay-as-you-go-small-devices">
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12">
                    <div class="problems-tab-content">
                      <h4 class="h4">Problem</h4>
                      <p class="common-para">It is simply impossible to control unwanted pilferage and leakage of information with paper documents. You cannot control who gets to access your confidential data.</p>
  
                      <h4 class="h4">dox2U has the solution</h4>
                      <p class="common-para">With bank-grade security and granular access controls, dox2U ensures that all your important and confidential information remains in your control at all times. This automatically prevents the risk of any kind of damage and data leak.</p>
                    </div>
                  </div>
                  <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none"></div>
  
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-sm-center">
                    <img src="../../../../assets/Image/problems-we-are-solving/control-over-information.png" alt="Control over information" class="img-fluid drop-shadow aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
                  </div>
                </div>
              </div>
            </div>
  
          </div> -->
          
          <div class="go-paperless-tabs">
            <ul class="problem-page-tab-navbar nav nav-pills mb-3 d-flex justify-content-center align-items-center" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Efficiency & Productivity</a>
                  
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Expensive real estate</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Control over information</a>
                </li>
              </ul>
              
            <div class="tab-content home-tabs-content" id="pills-tabContent">
                <div class="tab-pane problem-tab-para fade show active" id="pills-home" role="tabpanel"
                    aria-labelledby="pills-home-tab">
                    <div class="row d-flex align-items-center mtop80">
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 TEXTCENTER">
                      <div class="problems-tab-content">
                        <h4 class="h4">Problem:</h4>
                        <p class="common-para">Paper documents are hard to manage, and even harder to retrieve. This causes frustration and brings about non-productivity in dynamic workflows. </p>
    
                        <h4 class="h4">dox2U has the solution</h4>
                        <p class="common-para">With advanced filter capabilties and precise search technology, dox2U makes retrieval of documents easy; letting you access any file that you need the most at a given time.</p>
                      </div>
                      </div>
                        <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none "></div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <img src="../../../../assets/Image/efficiency-productivity.svg"  alt="Save time" class="img-fluid drop-shadow paperless-img-size"
                                data-aos="fade-left" data-aos-duration="1000" loading="lazy">
                        </div>
                    </div>

                </div>

                <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                    aria-labelledby="pills-profile-tab">
                    <div class="row d-flex align-items-center small-views mtop80">
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 TEXTCENTER">
                      <div class="problems-tab-content">
                        <h4 class="h4">Problem</h4>
                        <p class="common-para">Organizations fill up files and cabinets at a high pace, without actually realizing it. This exhausts their expensive real estate which could have been otherwise used productively.</p>
      
                        <h4 class="h4">dox2U has the solution</h4>
                        <p class="common-para">With zero requirements of storing your important documents in a physical space, dox2U provides the functionality 
                        of Smart Cabinet which helps you organize your files seamlessly. </p>
                      </div>
                        </div>
                        <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none"></div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <img src="../../../../assets/Image/expensive-real-estate.svg" alt="Easy Retrieval" class="img-fluid paperless-img-size drop-shadow" 
                                data-aos="" data-aos-duration="2500" loading="lazy">
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                    aria-labelledby="pills-contact-tab">
                    <div class="row d-flex align-items-center small-views mtop80">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 TEXTCENTER">
                          <div class="problems-tab-content">
                            <h4 class="h4">Problem:</h4>
                            <p class="common-para">It is simply impossible to control unwanted pilferage and leakage of information with paper documents. You cannot control who gets to access your confidential data.</p>
        
                            <h4 class="h4">dox2U has the solution</h4>
                            <p class="common-para">With bank-grade security and granular access controls, dox2U ensures that all your important and confidential information remains in your control at all times. This automatically prevents the risk of any kind of damage and data leak.</p>
                          </div>
                        </div>
                        <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none"></div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                            <img src="../../../../assets/Image/control-over-information.svg" alt="Information Security"
                                class="img-fluid paperless-img-size drop-shadow" data-aos="" data-aos-duration="2500"
                                loading="lazy">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </div>
    </div>
  </div>
</section>

<section class="collapsable-box-section common-padd">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="go-paperless-top-bar mx-auto">
          <h6 class="heading-6">PROBLEMS WITH OTHER DMS</h6>
          <h2 class="heading-2">Document management problems and their solutions</h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="collapsable-common-box">
          <div>
            <div class="collapse-card-first-body">
              
              <span class="heading-3 blue">1.</span>
              <h5>Entry Barriers </h5>
              <p class="common-para">Legacy On-Prem DMS solutions require expensive customizations, high CAPEX, cumbersome setup and deployment; making DMS out of reach for smaller teams. </p>
            
            </div>
              
            <div class="collapse  collapse multi-collapse expanded-card-first-body" id="multiCollapseExample1"  >
              <div class="card-body">
                <h5>Easy-to-use</h5>
                <p class="common-para">dox2U is a ready-to-use DMS. All you have to do is sign up and create a Workspace, much like you do while signing up for your e-mail account.</p>
              </div>
            </div>
            <button class=" show-all-button1 expanded-btn" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
              Solution
              </button>
              <button class="  showless  expanded-btn" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                Show Less
                </button>
          </div>
        </div>

        <div class="collapsable-common-box">
          <div>
            <div class="collapse-card-first-body">
              
              <span class="heading-3 yellow card-number">3.</span>
              <span class="heading-3 red card-number-2">2.</span>
              <h5 class="secondHeading">Rigidly non-generic</h5>
              <p class="common-para">Why should you have to change your business process to align with a solution you are looking to implement? Most DMS solutions would need you to undergo process changes to use them effectively.</p>
            
            </div>
              
            <div class="collapse  collapse multi-collapse expanded-card-first-body" id="multiCollapseExample2">
              <div class="card-body">
                <h5 class="secondHeading">Generic DMS</h5>
                <p class="common-para">Unlike existing DMS solutions, dox2U is highly configurable, and easily adapts to varying workflows, making it an easily accessible solution. </p>
              
              </div>
            </div>

            <button class="show-all-button1 expanded-btn"data-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
              Solution 
              </button>
              <button class="  showless  expanded-btn" data-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                Show Less
                </button>
            </div>
        </div>

      </div>

  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="collapsable-common-box">
          <div>
            <div class="collapse-card-first-body">
              
              <span class="heading-3 red card-number">2.</span>
              <span class="heading-3 card-number-3 yellow">3.</span>
              <h5 class="secondHeading">High Maintenance </h5>
              <p class="common-para">Most existing DMS solutions create a high operational overhead either by way of additional personnel required to maintain it or by way of high Annual Maintenance Charges payable to vendor. </p>
              
            </div>
              
            <div class="collapse collapse multi-collapse expanded-card-first-body" id="multiCollapseExample3">
              <div class="card-body">
                <h5>Cost-Efficient </h5>
                <p class="common-para">dox2U does not require any external customizations or consultancy for operations and running. Hence it cuts on unnecessary charges.</p>
              
              </div>
            </div>
            <button class=" show-all-button1 expanded-btn" data-toggle="collapse" href="#multiCollapseExample3" role="button" aria-expanded="false" aria-controls="multiCollapseExample3">
              Solution
              </button>
              <button class="  showless  expanded-btn" data-toggle="collapse" href="#multiCollapseExample3" role="button" aria-expanded="false" aria-controls="multiCollapseExample3">
                Show Less
                </button>
          </div>
        </div>

        <div class="collapsable-common-box">
          <div>
            <div class="collapse-card-first-body">
              
              <span class="heading-3 green">4.</span>
              <h5>Missing intelligence </h5>
              <p class="common-para">Existing DMS’s lack in adoption of newer technologies bringing in additional AI capabilities to process data and provide meaningful insights to help improve your business processes and decision making.</p>
              
            </div>
              
            <div class="  collapse" id="multiCollapseExample5">
              <div class="card-body expanded-card-first-body">
                <h5 class="secondHeading">An Intelligent Solution</h5>
                <p class="common-para">Having closely integrated capabilities like Text Extraction, Smart Cabinet and Deep Search, followed by machine learning based insights, dox2U is simply ahead of the existing DMS solutions</p>
              
              </div>
            </div>
            <button class=" show-all-button1 expanded-btn" data-toggle="collapse" href="#multiCollapseExample5" role="button" aria-expanded="false" aria-controls="multiCollapseExample5">
              Solution
              </button>
              <button class="  showless  expanded-btn" data-toggle="collapse" href="#multiCollapseExample5" role="button" aria-expanded="false" aria-controls="multiCollapseExample5">
                Show Less
                </button>
              
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="footer-bg " >
  <app-getting-started></app-getting-started>
</section>
<app-gradient-divider></app-gradient-divider>

