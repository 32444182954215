import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerIOService {
  private apiUrl = 'https://track.customer.io/api/v1/customers';
  private password = environment.password;
  private username = environment.username;

  constructor(private http: HttpClient) { }

  updateUserLeadSource(email: string, leadSource: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(this.username + ':' + this.password)
    });


    const requestBody = {
      id: email,
      Lead_Source: leadSource
    };

    const url = `${this.apiUrl}/${email}`;

    return this.http.put(url, requestBody, { headers });
      
  }
}
