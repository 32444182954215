import { Component } from '@angular/core';

@Component({
  selector: 'app-relationship-banner',
  templateUrl: './relationship-banner.component.html',
  styleUrls: ['./relationship-banner.component.scss']
})
export class RelationshipBannerComponent {

}
