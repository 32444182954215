import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiServiceService } from './api-service.service';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WSEmailService {
  baseUrlWS = environment.baseUrlWS;
  baseUrlWSIcon = environment.baseUrlWSIcon;
  private apiUrl = 'https://app.dox2u.com:82/api/FindWorkSpace/Search';
  private searchWSurl = this.baseUrlWS + '/api/FindWorkSpaceDetails/SearchWSDetails'
  private sendEmailOTPForWS = this.baseUrlWS + '/api/EmailVerification/SendFindWSOTP'
  private validateOtpsent = this.baseUrlWS + '/api/EmailVerification/Validate'
  private imageUrl = this.baseUrlWSIcon + '/api/OwnedByMe/DownloadFileStreamFromBlob';

  constructor(private http: HttpClient, private apiService:ApiServiceService) {}
    
    sendWorkspaceEmail(data: any): Observable<any> {
         return this.http.post(this.apiUrl, data);
    }

    /**
     * api to update ws icon in find-workspace page
     * @param data 
     * @returns 
     */
    sendImageUrl(data: any): Observable<any> {
      return this.http.post(this.imageUrl, data, {responseType:'blob',observe: 'response'}).pipe(
        retry(0)
      );
    }
 
    /**
     * api to send workspace associated with the user's email id 
     */

    sendWorkspaceList(data: any): Observable<any> {
      return this.http.post(this.searchWSurl, data);
    }

        /**
     * api to send workspace associated with the user's email id 
     */

        sendEmailOTP(data: any): Observable<any> {
          return this.http.post(this.sendEmailOTPForWS, data);
        }

      /**
   * api to validate the OTP
   */

  ValidateOTP(data: any) {
    return this.http.post(this.validateOtpsent, data);
  }

  emailVerify(data: any) {
    return this.apiService.post('/api/EmailVerification/SendFindWSOTP', data);
  }
}
