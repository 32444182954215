import { Component } from '@angular/core';

@Component({
  selector: 'app-paper-pains',
  templateUrl: './paper-pains.component.html',
  styleUrls: ['./paper-pains.component.scss']
})
export class PaperPainsComponent {

}
