import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  windowPrivacy: any;

  constructor(private router: Router) {}
  
  ngOnInit() { 
    AOS.init(); /* for image animation effect */
    this.windowPrivacy = window.location.href;
    if (this.windowPrivacy.includes('privacy-policy')) {
      this.router.navigateByUrl('legal/privacy-policy');
    }

    if(this.windowPrivacy.includes('tally-dms-add-on')) {
      this.router.navigateByUrl('tally');
    }
    
  }
}
