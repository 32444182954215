import { Component } from '@angular/core';

@Component({
  selector: 'app-gradient-divider',
  templateUrl: './gradient-divider.component.html',
  styleUrls: ['./gradient-divider.component.scss']
})
export class GradientDividerComponent {

}
