import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as AOS from 'aos';
import { imgContent7,imgContent8,imgContent9,imgContent10} from 'src/constants/home-content';
import { Meta, Title } from '@angular/platform-browser';
import { docViewerText, whatsappIntegrationText } from 'src/constants/product-content';
import { DOCUMENT } from '@angular/common';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {
  
  readonly imgContent7: typeof imgContent7 = imgContent7;
  readonly imgContent8: typeof imgContent8 = imgContent8;
  readonly imgContent9: typeof imgContent9 = imgContent9;
  readonly imgContent10: typeof imgContent10 = imgContent10;
  


  // imgpath
  imagePathRight_information: string ='../../../../assets/Image/dox2u_feature_information.svg';
  imagePathleft_information: string ='../../../../assets/Image/dox2u_feature__saas.svg';
  Plan_for_team: string ='../../../../assets/Image/dox2u_feature_accessible.svg';
docPreview:string='../../../../assets/Image/doc-preview-desktop.webp';
docPreview1: string='../../../../assets/Image/doc-preview-mobile.webp'
Workspaces:string='../../../../assets/Image/feature/workspaces.svg';
Document_handling:string='../../../../assets/Image/feature/document-handling.svg';
Plug_and_Play:string='../../../../assets/Image/feature/plug-and-play.svg';
text_extraction:string='../../../../assets/Image/feature/text-extraction.svg';
Guest_access:string='../../../../assets/Image/feature/guest-access.svg';
Integration_with_tally='../../../../assets/Image/feature/integration-tally.svg';
smart_cabinet:string='../../../../assets/Image/feature/smart-cabinet.svg';
Deep_search:string='../../../../assets/Image/feature/deep-search.svg';
Docket:string='../../../../assets/Image/feature/dockets-icon.svg';
Bulk_operation:string='../../../../assets/Image/feature/bulk-operations.svg';
security_reliabilty:string='../../../../assets/Image/feature/security-reliability.svg';
message:string='../../../../assets/Image/feature/messaging.svg';
feature_img:string='../../../../assets/Image/feature/dox2u_feature_banner.svg';
group_data:string='../../../../assets/Image/feature/Group-data.svg';
Whatsapp: string='../../../../assets/Image/feature/Whatsapp.svg';
Dockets: string='../../../../assets/Image/feature/Dockets.svg';
readonly whatsappIntegrationText: typeof whatsappIntegrationText =  whatsappIntegrationText;
whatsappIntegrationImg: string=" ";
// readonly desktopappText: typeof desktopappText = desktopappText;
// desktopappImg: string='../../../../assets/Image/feature/desktoppApp_feature.svg';
readonly docViewerText: typeof docViewerText = docViewerText;
docViewerImg: string='../../../../assets/Image/feature/doc-viewer-unique.svg';
videoURL: string = 'https://www.dox2u.com/e-docs/Guest_share_product-fruit.mp4'
videoURL1: string = '';
//videoElement!: HTMLVideoElement;
@ViewChild('myVideo') videoElement!: ElementRef<HTMLVideoElement>;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.videoElement) {
      const isVideoInViewport = this.isElementInViewport(this.videoElement.nativeElement);
      if (!isVideoInViewport) {
        this.videoElement.nativeElement.pause();
      }
    }
  }

  private isElementInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }


  ngOnInit() {
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/feature'});
    this.createLinkForCanonicalURL(); /** to add canonical tags */
    let scriptBreadcrumbListFeature = this.renderer2.createElement('script');
    scriptBreadcrumbListFeature.type = `application/ld+json`;
    scriptBreadcrumbListFeature.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Feature page",
      "item": "https://www.dox2u.com/feature"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbListFeature);
    
    AOS.init();
    this.titleService.setTitle(' Features of Document Management System | dox2U'); /** For Page Title */
    this.typing(this.textIndex, this.textArr[this.textIndex]);

    let accordianBodyFirst = document.getElementById("collapseOne");
    accordianBodyFirst?.classList.add('show');
  }

  private spanText: string;
  private textArr: string[];
  private maxTextIndex: number;
  private sPerChar: number = 0.15;
  private sBetweenWord: number = 1.5;
  private textIndex: number = 0;
  public outputText: string = '';

  constructor(private titleService: Title, private meta: Meta, private seoService: SEOService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) {
    

    this.spanText = "intelligent, innovative, easy-to-use, intuitive";
    this.textArr = this.spanText.split(", ");
    this.maxTextIndex = this.textArr.length;
  }

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  private typing(textIndex: number, text: string): void {
    let charIndex = 0;
    const maxCharIndex = text.length - 1;

    const typeInterval = setInterval(() => {
      this.outputText += text[charIndex];
      if (charIndex === maxCharIndex) {
        clearInterval(typeInterval);
        setTimeout(() => {
          this.deleting(textIndex, text);
        }, this.sBetweenWord * 1000);
      } else {
        charIndex += 1;
      }
    }, this.sPerChar * 1000);
  }

  private deleting(textIndex: number, text: string): void {
    const minCharIndex = 0;
    let charIndex = text.length - 1;

    const typeInterval = setInterval(() => {
      this.outputText = text.substr(0, charIndex);
      if (charIndex === minCharIndex) {
        clearInterval(typeInterval);
        textIndex + 1 === this.maxTextIndex
          ? (textIndex = 0)
          : (textIndex += 1);
        setTimeout(() => {
          this.typing(textIndex, this.textArr[textIndex]);
        }, this.sBetweenWord * 1000);
      } else {
        charIndex -= 1;
      }
    }, this.sPerChar * 1000);
  }

  scrollToGettingStarted() {
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
      const elementAfterNavigation = document.getElementById('gettingStarted');
          if (elementAfterNavigation) {
            const viewportHeight = window.innerHeight;
            const elementHeight = elementAfterNavigation.clientHeight;
            const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
            top: elementAfterNavigation.offsetTop - yOffset,
            behavior: 'smooth',
          };
          window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
    return;
  }
 
}

scrollTowhatsappUpdate() {
  const whatsappIntegrationText = document.getElementById('whatsappIntegrationText');
  if (whatsappIntegrationText) {
      const viewportHeight = window.innerHeight;
      const elementHeight = whatsappIntegrationText.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
      const scrollOptions: ScrollToOptions = {
      top: whatsappIntegrationText.offsetTop - yOffset,
      behavior: 'smooth',
    };
      window.scrollTo(scrollOptions);
    //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
  }
}
}
