import { DefaultUrlSerializer, UrlSerializer, UrlTree, UrlSegmentGroup, UrlSegment } from '@angular/router';

export class LowerCaseUrlSerializer implements UrlSerializer {
  private defaultSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    const urlTree = this.defaultSerializer.parse(url);
    return this.toLowerCase(urlTree);
  }

  serialize(tree: UrlTree): string {
    const url = this.defaultSerializer.serialize(tree);
    return url.toLowerCase();
  }

  private toLowerCase(tree: UrlTree): UrlTree {
    this.convertNodeToLowerCase(tree.root);
    return tree;
  }

  private convertNodeToLowerCase(node: UrlSegmentGroup): void {
    node.segments.forEach((segment: UrlSegment) => (segment.path = segment.path.toLowerCase()));
    Object.keys(node.children).forEach((childName: string) => {
      const child = node.children[childName];
      this.convertNodeToLowerCase(child);
    });
  }
}
