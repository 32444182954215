<section class="streamline-your-document anchor-scroll-margin overflow-hidden" id="upload">
    <div class="container">
      <div class="">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row d-flex align-items-center small-screens p-0">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0">
              <div class="workspace-content streamline-your-document-content">
                <h6 class="heading-6">{{content.heading}} </h6>
                <h3 class="heading-3">{{content.subTitle}}</h3>
  
                <ul class="m-0 p-0">
                  <li class="d-flex "><span class="line-rect"></span><p class="common-para"> {{content.text1}}</p></li>
                  <li class="d-flex "><span class="line-rect"></span><p class="common-para">{{content.text2}} </p></li>
                  <li class="d-flex "><span class="line-rect"></span><p class="common-para">{{content.text3}}</p></li>
                </ul>
                <div class="errow-btn">
                  <a (click)="navigateTo()">
                    <button class="cabinet-btn">
                    {{content.textHyper}}
                      <img src="../../../assets/Image/document-handling/blue-errow.svg" alt="" class="img-fluid">
                    </button>
                  </a>
                </div>
              </div>
            </div>
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end startposition endposition p-0">
              <img src={{imgUrl}} alt="Upload Document Process" class="img-fluid img-size aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </section>