<section class="workspaces-banner document-handling-banner text-extraction-banner overflow-hidden">
    <app-product-left-text-with-button [contentH]="textExtractionText"[imgSrc] = "dox2u_ocr_banner"></app-product-left-text-with-button>
</section>
<section class="text-extraction-ocr overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          
            <div class="text-extraction-ocr-content">
              <div class="workspace-top-content text-extraction-ocr-top-content">
                <h2 class="heading-2">Text Extraction</h2>
                <p class="common-para">Powered by <span> Optical Character Recognition(
                 
                  <span class="tool-tip">
                    <span class="border-dotted-blue ">
                      OCR 
                    </span>
                    <span class="tooltiptext text-extraction-tooltip">
                      Optical Character Recognition (OCR) recognizes &amp; converts text from images and presents it in a usable format. It is commonly used to recognize printed text in scanned documents and images.
                    </span>
                  </span>
                 /
                
                  <span class="tool-tip">
                    <span class="border-dotted-blue">
                      ICR 
                    </span>
                    <span class="tooltiptext text-extraction-tooltip">
                    Intelligent Character Recognition (ICR) is an additional layer of AI over OCR technologies that supports advanced character recognition such as handwritten text from image files.
                    </span>
                  </span>
                  
                  )</span>, dox2U’s innovative text extraction engine lets you convert any piece of text that is locked on a physical sheet of paper into a workable digitized format</p>
              </div>
            </div>
            
          
        </div>
      </div>
    </div>
  </section>
  <app-right-image-left-text [imgUrl]="bringValue_img" [content]="bringValueText"></app-right-image-left-text>
  <app-left-image-right-text [imgUrl]="ocrDynamic_img"[content]="goStatic_content" ></app-left-image-right-text>
  <app-right-image-left-text [imgUrl]="icr_img" [content]="bringValueOff"></app-right-image-left-text>
  <app-left-image-right-text [imgUrl]="handWrite_img"[content]="icrText" ></app-left-image-right-text>
  <!-- <app-more-feature-four-card></app-more-feature-four-card> -->
  <section class="more-features g-access" id="check12">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row">
              <div class="more-features-top-content mx-auto">
                <h2 class="heading-2">More features</h2>
              </div>
          </div>
          <div class="row d-flex  justify-content-center feature-card-main">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 cards-container">
            <a routerLink="/guest-access" class="feature_text">
              <div class="more-features-box">
                <img src="../../../assets/Image/security-reliability/guest-sharing.svg" alt="Guest sharing" class="img-fluid">
                <h4 class="heading-4">Guest sharing</h4>
                <p>Upload your docs to your Workspace</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <a routerLink="/text-extraction"  class="feature_text">
              <div class="more-features-box">
                <img src="../../../../assets/Image/smart-cabinet/doc-handling-green.svg" alt="Text extraction" class="img-fluid">
                <h4 class="heading-4">Text extraction</h4>
                <p>Track the status of your documents</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <a routerLink="/security"  class="feature_text">
              <div class="more-features-box">
                <img src="../../../../assets/Image/smart-cabinet/workspaces.svg"  alt="Document handling" class="img-fluid">
                <h4 class="heading-4">Security & reliability</h4>
                <p>AES-256-GCM powered encryption</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 "> 
            <a routerLink="/feature"    class="feature_text">
              <div class="more-features-box view-all-box">
                <img src="../../../assets/Image/security-reliability/view-all.svg" alt="View all" class="img-fluid view-all-plus">
                <h4 class="heading-4">View all</h4>
               
              </div>
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="footer-bg " >
    <app-getting-started></app-getting-started>
  </section>
  <app-gradient-divider></app-gradient-divider>
