import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-initials-avatar',
  templateUrl: './initials-avatar.component.html',
  styleUrls: ['./initials-avatar.component.scss']
})
export class InitialsAvatarComponent {
  @Input()
  name!: string;
 //randomColor: string = '#' + Math.floor(Math.random()*16777215).toString(16);
 randomColor: string = this.getRandomColor();

 /**
  * 
  * @returns To get the valid hex code from randomColor
  */
 private getRandomColor(): string {
  const randomInt = Math.floor(Math.random() * 16777215);
  const hexColor = randomInt.toString(16).padStart(6, '0');
  return `#${hexColor}`;
}
  /**
   * To get the initials of workspace name
   */
  get initials(): string {
 
    if (!this.name || this.name.trim() === '') {
      return '';
    }
   
    const nameParts = this.name.trim().split(' ');

    let initials = nameParts[0].charAt(0);

    if (nameParts.length > 1) {
      initials += nameParts[1].charAt(0);
    }
    return initials.toUpperCase();
  }
 
  /**
   * To apply style to initials
   */
  get avatarStyle(): any {
    return {
      'background-color': this.randomColor,
      'color': '#FFF'
    };
  }
  
}
