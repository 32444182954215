import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-integrate-document',
  templateUrl: './integrate-document.component.html',
  styleUrls: ['./integrate-document.component.scss']
})
export class IntegrateDocumentComponent {
  constructor(private router: Router) {

  }
  navigateToTallyPage() {
    this.router.navigate(['/tally']);
  }
}
