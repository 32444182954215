<section #GettingStartedComponent id="gettingStarted" class="getstarted overflow-hidden">
    <div class="container">
        <div class="row">
            <div class="getstarteBanner col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto ">
                <div class="row getstarteBannerContent">
                    <div class=" col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center">
                        <div class="d-flex flex-column">
                            <p class="banner-heading banner-24"> Getting started on your <span
                                    class="banner-heading-span banner-heading">Digital
                                    Transformation</span> journey is now just a few clicks away.</p>
                            <p class="banner-para mb-3 color-text">Give your team their own private space to share & collaborate.
                            </p>
                        </div>
                    </div>
                    <div class=" col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center">
                        <p class="banner-heading mb-3 banner-24">Start for free. Upgrade anytime.</p>
                        <p class="banner-para mb-4 ">No Credit Card Required</p>
                        <form name="dox2uform2" id="dox2uform2" [formGroup]="emailForm"
                            class="">
                            <div class="d-flex flex-column  mb-4 height-102  justify-content-start">

                                <label class="mb-1">Email* </label>
                                <!-- <div class="d-fl"> -->
                                <div class="d-flex form-div">

                                 <div class="d-flex flex-column w-70 email-input">
                                 <input class="Startinput-input" type="text" id="mail1" name="useremailId" formControlName="emailInput"
                                 (keypress)="emailInputReset($event)">
                                        
                                        <p id="errEmail1" class="mb-0 text-danger" *ngIf="emailInputInvalid"> Please enter valid email</p>
                                 </div>
                                    <div class="start-now-btn-container">
                                    <!-- <button class="startbtn-button text-align-center justify-content-center ">Start
                                        Now</button> -->
                                        <button class="button-white-bg" (click)="routeToCreateWS()"><span class="hidenow">Start
                                                Now</span> <div id="loadernow" class="spinner-border text-primary" style="display:none;position:absolute;" role="status">
                                        <span class="sr-only"></span>
                                        </div></button>
                                        
                                    </div>

                                </div>
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center mx-auto gap40 scheduleDemo">
                <a href="https://calendly.com/dox2u-dms/dox2u-demo" class="schedule-demo"
                    target="_blank">
                    <div class="d-flex mb-0"><img class="mr-2" src="../../../assets/Image/calender.svg">
                        <p class="mb-0 schedule-demo-text text-white">Schedule a demo</p>
                    </div>
                </a>
                <a [routerLink]="['/pricing']" target="_self">
                    <div class="d-flex mb-0"><img class="mr-2" src="../../../assets/Image/dollar.svg">
                        <p class="schedule-demo-text mb-0 text-white">dox2U pricing</p>
                    </div>
                </a>
            </div>
        </div>

    </div>





