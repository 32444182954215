import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { DocumentHandle_SHARE, DocumentHandle_retrive, insertImageWithDescriptionText, productHanle, shareGuest_access, uploadHandle } from 'src/constants/product-content';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-document-handling',
  templateUrl: './document-handling.component.html',
  styleUrls: ['./document-handling.component.scss']
})
export class DocumentHandlingComponent implements OnInit {

  constructor(private router: Router, private titleService: Title, private seoService: SEOService, private meta: Meta, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) {
      /*
      * Cannonical tag
     */
   
  }
  ngOnInit(): void {
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/document-handling'});
    this.createLinkForCanonicalURL(); /** to add canonical tags */
    let scriptBreadcrumbListDoc = this.renderer2.createElement('script');
    scriptBreadcrumbListDoc.type = `application/ld+json`;
    scriptBreadcrumbListDoc.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Document Handling Page",
      "item": "https://www.dox2u.com/document-handling"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbListDoc);

    this.titleService.setTitle('Document Handling- Document Management System for Better Management| dox2U","The easy-to-use Document Handling System and Software, dox2U is here to ease up your document handling procedure.'); /** For Page Title */
  }
  readonly productHandle: typeof productHanle = productHanle;
  readonly uploadText: typeof uploadHandle  =uploadHandle;
  readonly Share: typeof DocumentHandle_SHARE  =DocumentHandle_SHARE;
  readonly retrive: typeof DocumentHandle_retrive =DocumentHandle_retrive;
  readonly dox2u_share: typeof shareGuest_access =shareGuest_access;
  readonly insertImageWithDescriptionText: typeof insertImageWithDescriptionText =  insertImageWithDescriptionText;
  document_heroIMG:string="../../../assets/Image/document-handling/dox2u_doc_document.svg";
  upload:string="../../../../assets/Image/document-handling/cloud_upload.svg";
  product_Doc_share:string="../../../../assets/Image/document-handling/dox2u_doc_share.svg";
  handling_dot:string="../../../../assets/Image/document-handling/dots.svg";
  handling_tag:string="../../../../assets/Image/document-handling/label.svg"
  handling_share:string="../../../../assets/Image/document-handling/analytics.svg"
  handling_retrive:string="../../../../assets/Image/document-handling/domain_verification.svg"
  handling_upload:string="../../../../assets/Image/document-handling/dox2u_doc_upload.svg"
  handling_DeepSearch:string="../../../../assets/Image/document-handling/dox2u_doc_tag.svg"
  handling_Retrive:string="../../../../assets/Image/document-handling/dox2u_doc_retrive.svg"
  blue_circle:string="../../../../assets/Image/document-handling/blue-circle-tick.svg"
  insertImageWithDescriptionImg: string="../../../../assets/Image/document-handling/docPreview_docHandling.svg"

  navigateToFeature() {
    this.router.navigate(['/feature']);
  }

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  scrollToSectionUpload() {
    const uploadElement = document.getElementById('upload');
    if (uploadElement) {
        const viewportHeight = window.innerHeight;
        const elementHeight = uploadElement.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: uploadElement.offsetTop - yOffset,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // scrollToGettingStarted(event: Event) {
  //   const gettingStartedElement = document.getElementById('gettingStarted');
  //   if (gettingStartedElement) {
  //     const viewportHeight = window.innerHeight;
  //           const elementHeight = gettingStartedElement.clientHeight;
  //           const yOffset = (viewportHeight - elementHeight) / 2;
  //         const scrollOptions: ScrollToOptions = {
  //           top: gettingStartedElement.offsetTop - yOffset,
  //           behavior: 'smooth',
  //         };
  //         window.scrollTo(scrollOptions);
  //     //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }
}