<section class="banner-new overflow-hidden">
    <div class="container-fluid">
    <div class="row">
        <div class=" col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto">
    <div class="row banner-new-container">
        <div class="manage-tally-banner col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12">
            <h2>
                Transform Tally into a cloud based Document Management System with our unique Tally Plugin
            </h2>
            <ul>
                <li>Manage documents using Tally</li>  
                <li>Documents stored & organized on dox2U’s cloud</li>
                <li>Access documents anytime, anywhere</li>
            </ul>

            <div class="start-now-btn-container">
                <!-- <button class="startbtn-button text-align-center justify-content-center ">Start
                    Now</button> -->
                    <button class="button-white-bg learnMore-btn" (click)="navigateToTallyPage()">Learn More
                    </button>
                    
                </div>
        </div>
        <div class="integrate-info-list col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <!-- <div class="integrate-doc integrate-para">
                <p class="common-para1 mb-3 pb-1 "><img src="../../../assets/Image/how.svg" class=" mright">Attach documents in Tally</p>
                <p class="common-para1 mb-3 pb-1"> <img src="../../../assets/Image/how.svg"  class=" mright">Documents auto-sync with dox2U</p>
                <p class="common-para1 mb-3 pb-1"> <img src="../../../assets/Image/how.svg"  class=" mright">Access docs from anywhere</p>
                <p class="common-para1 mt-3 pt-1 mb-0"><a [routerLink]="['/tally']" class="learnMoreTab" id="learnMoreTab">Learn more <img src="../../../assets/Image/homepage/learnmore.svg" ></a></p>
            </div> -->
            <img src="../../../assets/Image/zigzag.svg">
        </div>
    </div>
        </div>
    </div>
    </div>
    </section>
    <div class="gradient-divider"></div>