
<section class="workspaces-banner security-reliability overflow-hidden">
    <app-product-left-text-with-button [contentH]="productSubmenu"[imgSrc] = "security_banner"></app-product-left-text-with-button>
</section>
<!-- ----------- -->
<section class="security-box-section common-padd overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="security-top-bar">
            <h6 class="heading-6">SECURITY &amp; RELIABILITY</h6>
            <h2 class="heading-2">Your safety is our priority</h2>
          </div>
        </div>
      </div>
      <div class="row" style="row-gap: 10px;">
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 security-main-box">
          <div class="security-box">
            <img src="../../../../assets/Image/security-reliability/bullet-abstract.svg" alt="Transparency at its best" class="img-fluid safey-right-box-img">
              <h4 class="h4">Transparency at its best</h4>
              <p class="common-para">dox2U captures every activity in a comprehensive Audit Log that can be accessed by Admins. Our Document Activity Log also lets you track activities at a document level, giving you complete transparency about your team’s activity.</p>
          </div>
        </div>
  
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 security-main-box">
          <div class="security-box">
            <img src="../../../../assets/Image/security-reliability/bullet-abstract.svg" alt="Transparency at its best" class="img-fluid safey-right-box-img">
              <h4 class="h4">You are in charge!</h4>
              <p class="common-para">dox2U lets you be the commander-in-chief for everything within your Workspace. Our multi-level granular control lets you define access rights, sharing rights, and lets you decide who gets to do what in your Workspace.</p>
          </div>
        </div>
  
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 security-main-box">
          <div class="security-box">
            <img src="../../../../assets/Image/security-reliability/bullet-abstract.svg" alt="Transparency at its best" class="img-fluid safey-right-box-img">
              <h4 class="h4">Safe and Secure</h4>
              <p class="common-para">All your data is encrypted in dox2U- both at rest and in transit. Our bank-grade implementation of AES-256 powered encryption along with the TLS-1.2 transport layer solidifies this secure environment. We ensure that any data you put onto dox2U is strictly for your eyes only</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="">
    <app-left-image-right-text [imgUrl]="security_audit" [content]="auditLogs"></app-left-image-right-text>
</section>

<section class="our-certifications">
    <div class="container"> 
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="our-certifications-content-1 text-center">
            <h3 class="h4"> Our certifications </h3>
            <p class="common-para">ISO 27001 : 2013, verified by QPCS &amp; ISO 9001 : 2015 </p>
            <p class="common-para">ISO 9001 : 2015, verified by Otabu Certification Pvt. Ltd.</p>
            
            <div class="our-certifications-content-images">
              <img src="../../../../assets/Image/security-reliability/security-logos-one.png" alt="Our certifications" class="img-fluid">
  
            </div>
          </div>
        </div>
      </div>
  
      <div class="row ">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="our-certifications-content-2 text-center">
            
            <h4 class="h4">dox2U is hosted with cloud platforms complying with the following standards</h4>
            
            <div class="our-certifications-content-images">
              <img src="../../../../assets/Image/security-reliability/security-logos-two.png" alt="ISO 27001" class="img-fluid">          
            </div>
          </div>
        </div>
      </div>
  
  </div>
  </section>
  <!-- <app-more-feature-four-card></app-more-feature-four-card> -->
  <section class="more-features g-access" id="check12">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row">
              <div class="more-features-top-content mx-auto">
                <h2 class="heading-2">More features</h2>
              </div>
          </div>
          <div class="row d-flex  justify-content-center feature-card-main">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <a routerLink="/guest-access" class="feature_text">
              <div class="more-features-box">
                <img src="../../../assets/Image/security-reliability/guest-sharing.svg" alt="Guest sharing" class="img-fluid">
                <h4 class="heading-4">Guest sharing</h4>
                <p>Upload your docs to your Workspace</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <a routerLink="/text-extraction" class="feature_text">
              <div class="more-features-box">
                <img src="../../../assets/Image/security-reliability/text-extraction.svg" alt="Text extraction" class="img-fluid">
                <h4 class="heading-4">Text extraction</h4>
                <p>Track the status of your documents</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <a routerLink="/document-handling" class="feature_text">
              <div class="more-features-box">
                <img src="../../../assets/Image/security-reliability/doc-handling.svg"  alt="Document handling" class="img-fluid">
                <h4 class="heading-4">Document handling</h4>
                <p>Upload, verify, track, edit and distribute your work easily.</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 "> 
            <a routerLink="/feature" class="feature_text">
              <div class="more-features-box ">
                <br><br>
                <img src="../../../assets/Image/security-reliability/view-all.svg" alt="View all" class="img-fluid">
                <h4 class="heading-4">View all</h4>
               
              </div>
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-getting-started #GettingStartedComponent></app-getting-started>

