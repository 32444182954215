

  
<section class="tally-dms-plug partner-hero overflow-hidden">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">

                <div class="row d-flex align-items-center justify-content-center">
                    <!-- <div class="col-xxl-1"></div> -->
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
                        <div class="home-banner-content-partner">
                            <h6 class="heading-6 partner-heading">dox2U PARTNERS </h6>
                            <h1 class="heading-1 heading-partner">Become a dox2U Partner and grow your business
                                exponentially
                            </h1>
                            <div class="hero-text-align">
                                <div class="d-flex textleft  lineheight28px">
                                    <li></li>
                                    <p class=" tally-align fontsize24px-partner">Join dox2U, the fastest growing
                                        Document Management solution for businesses of all sizes</p>
                                </div>
                                <div class="d-flex textleft lineheight28px">
                                    <li></li>
                                    <p class=" tally-align fontsize24px-partner">No investment, no infrastructure, no
                                        training required</p>
                                </div>
                                <div class="d-flex textleft lineheight28px">
                                    <li></li>
                                    <p class="tally-align fontsize24px-partner">Resell to your existing customers and
                                        start earning</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-10 col-sm-12  form768 ">
                        <div id="channelPartnershow" class="formStyle">
                                <form id="channelPartner" [formGroup]="channelPartner" (ngSubmit)="onSubmit()" *ngIf="partnerformShow">
                                <div class="form-group nameInput d-flex flex-column inputsize ">
                                    <label for="exampleInputEmail1">
                                        <p class="label-field mb-1 tunset">First Name<span style="color:red">*</span>
                                        </p>
                                    </label>
                                    <input type="text" class="form-control input-border-none p-0" id="firstnameC" placeholder="Enter first name"
                                    formControlName="firstnameC" (keypress)="resetFirstNameCDiv($event)" (mousedown)="resetFirstNameCDiv($event)">
                                    <p id="errfirstnameC" class="mb-0  meserr" *ngIf="firstNameCValidDiv">
                                        This field cannot be empty
                                    </p>
                                </div>
                               
                                <div class="form-group nameInput d-flex flex-column inputsize">
                                    <label for="exampleInputPassword1">
                                        <p class="label-field mb-1 tunset">Last Name<span style="color:red">*</span>
                                        </p>
                                    </label>
                                    <input type="text" class="form-control input-border-none p-0" id="lastnameC" placeholder="Enter last name"
                                    formControlName="lastnameC" (keypress)="resetLastNameCDiv($event)" (mousedown)="resetLastNameCDiv($event)">
                                    <p id="errlastnameC" class="mb-0  meserr" *ngIf="lastNameCValidDiv">
                                        This field cannot be empty
                                    </p>
                                    </div>
                             
                                <div class="form-group nameInput d-flex flex-column inputsize">
                                    <label for="exampleInputEmail1">
                                        <p class="label-field mb-1 tunset">Organization<span style="color:red">*</span>
                                        </p>
                                    </label>
                                    <input type="text" class="form-control input-border-none p-0" id="organizationC" placeholder="Enter organization name"
                                    formControlName="organizationC" (keypress)="resetOrganizationCDiv($event)" (mousedown)="resetOrganizationCDiv($event)">
                                    <p id="errorganizationC" class="mb-0  meserr" *ngIf="organizationCValidDiv">
                                        Please enter organization name
                                    </p>
                                </div>
                             
                                <div class="form-group nameInput d-flex flex-column inputsize">
                                    <label for="exampleInputPassword1">
                                        <p class="label-field mb-1 tunset">Email<span style="color:red">*</span></p>
                                    </label>
                                    <input  class="form-control input-border-none p-0" id="tallyemailC" placeholder="Enter your email address"
                                    formControlName="tallyemailC" (keypress)="resetTallyEmailCDiv($event)" (mousedown)="resetTallyEmailCDiv($event)">
                                    <p id="errtallyemailC" class="mb-0  meserr" *ngIf="tallyemailCInvalidDiv">
                                        Please enter valid email
                                    </p>
                                    <p id="errtallyemailC" class="mb-0  meserr" *ngIf="tallyemailCEmptyDiv">
                                        This field cannot be empty
                                    </p>
                                    </div>
                               
                                <div class="form-group nameInput d-flex flex-column inputsize">
                                    <label for="exampleInputPassword1 intl-label">
                                        <p class="label-field mb-1 tunset">Phone No.<span style="color:red">*</span>
                                        </p>
                                    </label>
                                    <!-- <input type="tel"  class="form-control intl-input input-border-none p-0" id="phoneC" maxlength="17" placeholder="0987654321"
                                    formControlName="phoneC" (input)="resetPhoneCDiv($event)" (mousedown)="resetPhoneCDiv($event)"> -->

                                    <!-- <input type="tel" id="phone"> -->
                                    
                                    <ngx-intl-tel-input class="getting-started-input phn-inp inp100 form-control" (input)="resetPhoneCDiv($event)"
                                    (mousedown)="resetPhoneCDiv($event)"
                                    [cssClass]="'custom'"
                                  
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [enableAutoCountrySelect]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[
                                      SearchCountryField.Iso2,
                                      SearchCountryField.Name
                                    ]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.India"
                                    [maxLength]="15"
                                    [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode"
                                    [numberFormat]="PhoneNumberFormat.National"
                                    name="phone"
                                    formControlName="phoneC"
                                    (countryChange)="onCountryChangeCP($event)"
></ngx-intl-tel-input>
<!--  -->
    <p id="errphoneP" class="mb-0  meserr" *ngIf="phoneCValidDiv">
        Please enter phone no.
    </p>
    <p id="errphoneP" class="mb-0  meserr" *ngIf="phoneCValidationCheckDiv">
        Please enter the valid number
    </p>

                                                </div>
                      
                                <div class="form-group nameInput d-flex flex-column inputsize">
                                    <label for="exampleInputPassword1">
                                        <p class="label-field mb-1 tunset">Country<span style="color:red">*</span>
                                        </p>
                                    </label>
                                    <!-- <input  id="Country" formControlName="countryC"
                                        class="form-control input-border-none p-0" style="border: none;"
                                        (keypress)="resetCountryCDiv($event)" (mousedown)="resetCountryCDiv($event)"> -->

                                        <select class="form-control input-border-none inputwith100 p-0" formControlName="countryC"   name="Country" (change)="resetCountryCDiv($event)"  id='mySelect' name='country'>
                                            <option value="" selected="">Select your Country</option>
                                            <option class="common" id="country" selected></option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antartica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                            <option value="Croatia">Croatia (Hrvatska)</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="France Metropolitan">France, Metropolitan</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                            <option value="Holy See">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran">Iran (Islamic Republic of)</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Democratic People's Republic of Korea">Korea</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao">Lao People's Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option class="common" value="Macedonia">Macedonia</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia">Micronesia, Federated States of</option>
                                            <option value="Moldova">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
                                            <option value="Saint LUCIA">Saint LUCIA</option>
                                            <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option> 
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia">South Georgia</option>
                                            <option value="Span">Spain</option>
                                            <option value="SriLanka">Sri Lanka</option>
                                            <option value="St. Helena">St. Helena</option>
                                            <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syrian Arab Republic</option>
                                            <option value="Taiwan">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Viet Nam</option>
                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                            <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                            <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                          </select>
                                          <p id="errcontryp" class="mb-0 meserr" *ngIf="countryCValidDiv">
                                            Please enter country name
                                        </p>    
                                    </div>
                             

                                <div class="justify-content-center align-items-center d-flex ">
                                            <button class="button-primary trydox" id="myButton1" type="submit"><span
                                                    class="hideDownload1" style="padding:12px 32px;">I am Intrested!</span> </button>
                                            <div id="loader1" class="spinner-border text-white"
                                                style="display:none;position:absolute;" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                   
                                </form>
                                <div id="chanel-partnerDMShide"
                                class="d-flex  flex-column justify-content-center align-items-center" *ngIf="partnerFormSubmitted"
                                style="height:363px">
                                <p class="common-para text-center">Thank you for reaching out. Someone from our team
                                    will
                                    connect with you shortly! </p>
                                <img src="../../../assets/Image/tallysuccess.svg">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <section class="physical-cabinets top-scale-pad common-padd reselar-partner-bg ">
            <div class="container">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-xxl-12">
                        <div class="reseller-partner mx-auto reseler-top text-center">
                            <h6 class="heading-6">RESELLER PARTNER PROGRAM</h6>
                            <h2 class="heading-2">Scale your business globally
                            </h2>
                        </div>
                    </div>
                </div>
                <app-left-image-right-text [imgUrl]="ResellerPathLeft"[content]="ResellerContent" ></app-left-image-right-text>
            </div>
        </section>

<section class="top-padd physical-cabinets  partner-section common-padd ">
    <div class="container">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="row">
                    <div class="more-features-top-content">
                        <h6 class="heading-6">PARTNER WITH US</h6>
                        <h2 class="heading-2 fontwhy32">Why choose to Partner with Us?</h2>
                        <p class="text-center choose-para">Partnering with dox2U allows you to leverage our rapid growth, affordable and intuitive software, innovative features, top-notch security, and dedicated support. Join our network of successful partners and enhance your business with our cutting-edge document management solution. Here's why you should consider partnering with us:</p>
                    </div>
                </div>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto reseller-image-container">
            <app-left-image-right-text [imgUrl]="imagePathReseller1"[content]="imgContentReseller1" ></app-left-image-right-text>
            <app-right-image-left-text [imgUrl]="imagePathReseller2" [content]="imgContentReseller2"></app-right-image-left-text>
            <app-left-image-right-text [imgUrl]="imagePathReseller3"[content]="imgContentReseller3" ></app-left-image-right-text>
            <app-right-image-left-text [imgUrl]="imagePathReseller4" [content]="imgContentReseller4"></app-right-image-left-text>

        </div>
    </div>
    </div>
</section>

<section class="paper-pains-section common-padd">
    <div class="container">
        <div class="row justify-content-center">
            <div class="more-features-top-content marginp40">
                <h6 class="heading-6">PARTNER BENEFITS</h6>
                        <h2 class="heading-2 fontwhy32 ">Partnering with us provides a wide range of benefits</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto">
                <div class="paper-pains-box">
                    <div class="row ">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12  pCard-center">
                            <div class="paper-box box-card">
                                <div data-aos="fade-up" data-aos-duration="2500">
                                    <span>
                                        <h3 class="size-fourty mb-0 p-card-heading mx-auto heading-3">Business Benefits</h3>
                                    </span>
                                    <div class="common-para mb-0">
                                    <div class="d-flex common-para2 mb-2 pbFont"><li></li>Ability to resell dox2U to end customers</div>
                                    <div class="d-flex common-para2 mb-2 pbFont"><li></li>Unmatched returns with high Partner discounts</div>
                                    <div class="d-flex common-para2 mb-2 pbFont"><li></li>Business planning and sales support</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 cardp-center">
                            <div class="paper-box box-card">
                                <div data-aos="fade-up" data-aos-duration="2500">
                                    <span>
                                        <h3 class="size-fourty mb-0 p-card-heading mx-auto heading-3">Marketing Benefits</h3>
                                        <!-- <img src="./images/home/yellow-errow.svg" alt=""
                                            class="img-fluid paper-box-img"> -->
                                    </span>
                                    <div class="common-para mb-4">
                                    <div class="d-flex common-para2 mb-2 pbFont"><li></li>Partner sponsorships and investments</div>
                                    <div class="d-flex common-para2 mb-2 pbFont"><li></li>Participation at dox2U hosted events</div>
                                    <div class="d-flex common-para2 mb-2 pbFont"><li></li>Co-brandable marketing content and campaign support</div>
                                    </div>
                                </div>
                            </div>
                            <div class="paper-box box-card">
                                <div data-aos="fade-up" data-aos-duration="2500">
                                    <span>
                                        <h3 class="size-fourty mb-0 p-card-heading mx-auto heading-3">Enablement Benefits</h3>
                                        <!-- <img src="./images/home/errow_icon.svg" alt=""
                                            class="img-fluid paper-box-img"> -->
                                    </span>
                                    <div class="common-para mb-0"> <div class="d-flex common-para2 mb-2 pbFont"><li></li>Exclusive dox2u partner sales and technical training</div>
                                    <div class="d-flex common-para2 mb-2 pbFont"><li></li>Channel field sales resources and alignment</div>
                                    <div class="d-flex common-para2 mb-2 pbFont"><li></li>Program onboarding and operational training</div>
                                </div>
                                </div>
                            </div>

                        </div>
                        <div class="identify-with-paper-pains partner-left ptop0px">
                            <img src="../../../assets/Image/demo-bg.svg" alt="" class="img-fluid paper-pains-bg-img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="marginjoine">
    <div class="row ">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                        <p class="text-center rapid-para">Join us today to elevate your customer’s document management capabilities.</p>
                    </div>
                </div>
</section>

<section class="common-padd physical-cabinets bottomPadding  reselar-partner-bg padTop">
    <div class="container ">
        <div class="row justify-content-center">
            <div class="col-xxl-12">
                <div class="reseller-partner mx-auto">
                    <h6 class="heading-6">PREREQUISITES</h6>
                    <h2 class="heading-2"> What are we looking for in a Partner?
                    </h2>
                </div>
            </div>
        </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
            <div class="row d-flex align-items-center  ">


                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center ">
                    <div class="retrieval-as-easy">
                        <!-- <h6 class="heading-6">STEP 2</h6> -->
                        <p class="text-center  lookin-para"> You could be an existing Partner for one of our
                            complimenting
                            solutions (Tally, Busy, Marg ERP, SAP, etc..) or you could be an IT Reseller catering to
                            SMBs & SMEs. As long as you have what it takes to sell effectively, you are an ideal partner
                            for us.</p>
                    </div>

                </div>
      
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start borderpartner ">
                    <div class="retrieval-as-easy">
                        <div>
                            <h6 class="common-para-adone Prerequisites">Prerequisites</h6>
                            <div class="d-flex textleft">
                                <li></li>
                                <p class="common-para-adone tally-align">A designated market, geographical segment, or
                                    expertise that aligns with dox2U</p>
                            </div>
                            <div class="d-flex textleft">
                                <li></li>
                                <p class="common-para-adone tally-align">Track record of solving customer business needs
                                </p>
                            </div>
                            <div class="d-flex textleft">
                                <li></li>
                                <p class="common-para-adone tally-align">Available sales resources to focus on Document
                                    Management customer engagements
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</section>

<div class="height" style="height: 79px;"></div>

<!-- <app-partner-with-us></app-partner-with-us> -->
<section class="partner-with-us top-padd bottom-padd common-padd">
    <div class="container">
        <div class="row">

            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="row d-flex align-items-center ">
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-start ">
                        <div class="pw-anchor">
                            <h3 class="heading-3 ">Partner with us!</h3>
                            <p class="common-para mb-5">Become a part of dox2U’s digital revolution by joining our
                                Channel Partner network.
                                We offer attractive Reseller plans as well as Referral plans!</p>
                                
                        </div>
                    </div>
                    <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div>
                    <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 d-flex justify-content-center partnerFormContainer martopD">
                        <div id="partnerDMshow" style="width:100%">
                            <form id="partnerUsForm" name="partnerUsForm" [formGroup]="partnerForm" (ngSubmit)="onSubmitParther()" *ngIf="partnerFormShow">
                                <div class="d-flex flex-column colmG">
                                    <div class="d-flex gap26 w-100">
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column">
                                                <label>
                                                    <p class="label-field mb-1 tunset">First Name<span
                                                            style="color:red">*</span>
                                                    </p>
                                                </label>
                                                <input class="input-border-none form-control" placeholder="Enter first name" id="firstnameP" formControlName="firstnameP"
                                                (keypress)="resetFirstNamePDiv($event)" (mousedown)="resetFirstNamePDiv($event)">
                                            </div>
                                            <p id="errfirstnameP" class="mb-0  meserr" *ngIf="firstNamePValidDiv">
                                                This field cannot be empty
                                            </p>
                                        </div>
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Last Name<span
                                                            style="color:red">*</span>
                                                    </p>
                                                </label>
                                                <input class="input-border-none form-control" id="lastnameP" placeholder="Enter last name" formControlName="lastnameP"
                                                (keypress)="resetLastNamePDiv($event)" (mousedown)="resetLastNamePDiv($event)">
                                            </div>
                                            <p id="errlastnameP" class="mb-0  meserr" *ngIf="lastNamePValidDiv">
                                                This field cannot be empty
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-flex gap26 w-100">
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Organization<span
                                                            style="color:red">*</span>
                                                    </p>
                                                </label>
                                                <input class="input-border-none form-control" id="organizationP" formControlName="organizationP" placeholder="Enter organization name"
                                                (keypress)="resetOrganizationPDiv($event)" (mousedown)="resetOrganizationPDiv($event)">
                                            </div>
                                            <p id="errorganizationP" class="mb-0  meserr" *ngIf="organizationPValidDiv">
                                                Please enter organization name
                                            </p>
                                        </div>
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Email<span
                                                            style="color:red">*</span></p>
                                                </label>
                                                <input class="input-border-none form-control" id="tallyemailP" formControlName="tallyemailP" placeholder="Enter your email address"
                                                (keypress)="resetTallyEmailPDiv($event)" (mousedown)="resetTallyEmailPDiv($event)">
                                            </div>
                                            <p id="errtallyemailP" class="mb-0  meserr" *ngIf="tallyemailPInvalidDiv">
                                                Please enter valid email
                                            </p>
                                            <p id="errtallyemailP" class="mb-0  meserr" *ngIf="tallyemailPEmptyDiv">
                                                This field cannot be empty
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-flex gap26 w-100">
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Phone No.<span
                                                            style="color:red">*</span>
                                                    </p>
                                                </label>
                                               
                                                    <!-- <input type="tel" id="phoneP" maxlength="17"
                                    class=" input-border-none form-control intl-input  inputwith100" formControlName="phone" placeholder="0987654321" (input)="resetPhoneCPDiv($event)" (mousedown)="resetPhoneCPDiv($event)"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    maxlength="15"> -->
                                
                                    <ngx-intl-tel-input class="getting-started-input phn-inp inp100 form-control" (input)="resetPhoneCPDiv($event)" (mousedown)="resetPhoneCPDiv($event)"
                                    
                                    [cssClass]="'custom'"
                                  
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [enableAutoCountrySelect]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[
                                      SearchCountryField.Iso2,
                                      SearchCountryField.Name
                                    ]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.India"
                                    [maxLength]="15"
                                    [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode"
                                    [numberFormat]="PhoneNumberFormat.National"
                                    name="phone"
                                    formControlName="phone"
                                    (countryChange)="onCountryChangeP($event)"
></ngx-intl-tel-input>

                                                  
                                            </div>
                                            <p id="errphoneP" class="mb-0  meserr" *ngIf="phonePValidDiv">
                                                Please enter phone no.
                                            </p>
                                            <p class="mb-0 meserr text-danger" *ngIf="phoneValidationCheckDiv">
                                                Please enter the valid number
                                              </p>

                                        </div>
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column country-input">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Country<span
                                                            style="color:red">*</span>
                                                    </p>
                                                </label>
                                                <!-- <input class="input-border-none" id="Country" formControlName="countryP"
                                                (keypress)="resetCountryPDiv($event)" (mousedown)="resetCountryPDiv($event)"> -->

                                                <select class="input-border-none form-control inputwith100" formControlName="countryP"  name="Country" (keypress)="resetCountryPDiv($event)"  id='mySelect' name='country'>
                                                    <option value="" selected="">Select your Country</option>
                                                    <option class="common" id="country" selected></option>
                                                    <option value="Afghanistan">Afghanistan</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="American Samoa">American Samoa</option>
                                                    <option value="Andorra">Andorra</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Anguilla">Anguilla</option>
                                                    <option value="Antartica">Antarctica</option>
                                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Aruba">Aruba</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Belize">Belize</option>
                                                    <option value="Benin">Benin</option>
                                                    <option value="Bermuda">Bermuda</option>
                                                    <option value="Bhutan">Bhutan</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Bouvet Island">Bouvet Island</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                    <option value="Burundi">Burundi</option>
                                                    <option value="Cambodia">Cambodia</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Cape Verde">Cape Verde</option>
                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                    <option value="Central African Republic">Central African Republic</option>
                                                    <option value="Chad">Chad</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Christmas Island">Christmas Island</option>
                                                    <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Comoros">Comoros</option>
                                                    <option value="Congo">Congo</option>
                                                    <option value="Cook Islands">Cook Islands</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                                    <option value="Croatia">Croatia (Hrvatska)</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czech Republic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Djibouti">Djibouti</option>
                                                    <option value="Dominica">Dominica</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="East Timor">East Timor</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                    <option value="Eritrea">Eritrea</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                    <option value="Fiji">Fiji</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="France Metropolitan">France, Metropolitan</option>
                                                    <option value="French Guiana">French Guiana</option>
                                                    <option value="French Polynesia">French Polynesia</option>
                                                    <option value="French Southern Territories">French Southern Territories</option>
                                                    <option value="Gabon">Gabon</option>
                                                    <option value="Gambia">Gambia</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Greenland">Greenland</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                                    <option value="Holy See">Holy See (Vatican City State)</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran">Iran (Islamic Republic of)</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Democratic People's Republic of Korea">Korea</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Lao">Lao People's Democratic Republic</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Macau">Macau</option>
                                                    <option class="common" value="Macedonia">Macedonia</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Micronesia">Micronesia, Federated States of</option>
                                                    <option value="Moldova">Moldova, Republic of</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montserrat">Montserrat</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Namibia">Namibia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                    <option value="New Caledonia">New Caledonia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Niue">Niue</option>
                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau">Palau</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Pitcairn">Pitcairn</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Reunion">Reunion</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russia">Russian Federation</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
                                                    <option value="Saint LUCIA">Saint LUCIA</option>
                                                    <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option> 
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="South Georgia">South Georgia</option>
                                                    <option value="Span">Spain</option>
                                                    <option value="SriLanka">Sri Lanka</option>
                                                    <option value="St. Helena">St. Helena</option>
                                                    <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syria">Syrian Arab Republic</option>
                                                    <option value="Taiwan">Taiwan, Province of China</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania">Tanzania, United Republic of</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tokelau">Tokelau</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                    <option value="Uruguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Vietnam">Viet Nam</option>
                                                    <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                                    <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                                    <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                                    <option value="Western Sahara">Western Sahara</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                  </select>
                                            </div>
                                            <p id="errcontryp" class="mb-0  meserr" *ngIf="countryPValidDiv">
                                                Please enter country name
                                            </p>
                                        </div>
                                    </div>
                                   
                                    <div class="justify-content-center align-items-center d-flex">
                                        <button class="button-primary trydox" type="submit" id="myButton2">I am Intrested!</button>
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="partnerDMShide" class="d-flex  flex-column justify-content-center align-items-center" *ngIf="partnerWithUsFormSubmitted"
                            style="height:363px">
                            <p class="common-para text-center">Thank you for reaching out. Someone from our team will
                                connect with you shortly! </p>
                            <img src="../../../assets/Image/tallysuccess.svg">
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<section class=" ">
    <div class="container">
        <div class="row">

            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <p class="tally-para-partner"> <i>Tally is a trademark of its respective owner and is not affiliated,
                        endorsed, connected or sponsored in any way to this website, mobile application or any of our
                        affiliate sites. The same is used in accordance with honest practices and not used with any
                        intention to misguide customers to take unfair advantage of the trademark's distinct character
                        or harm the holder's reputation.</i></p>
            </div>
        </div>
</div></section>
<app-gradient-divider></app-gradient-divider>
        
      

