import { Component, OnInit,Input} from '@angular/core';
import { ContentI } from 'src/app/interfaces/interfaces';
@Component({
  selector: 'app-left-image-right-text',
  templateUrl: './left-image-right-text.component.html',
  styleUrls: ['./left-image-right-text.component.scss']
})
export class LeftImageRightTextComponent implements OnInit{
@Input() imgUrl:string="";
@Input () content!:ContentI;
  ngOnInit(): void {
    console.log(this.content);
  }
}
