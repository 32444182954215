<section class="workspaces-banner why-choose-dox2U-banner overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto ">
          <div class="row d-flex align-items-center">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="workspace-banner-content why-choose-dox2U-banner-content">
                  <h6 class="heading-6">Why choose dox2U?</h6>
                  <h1 class="heading-1">We are 
                  <div class="slidingVertical-2">
                  <span class="color-1">not Google Drive</span>
                  <span class="color-2">not DropBox</span>
                  <span class="color-3">dox2U</span>
                 
                </div>
                  </h1>
                <p class="common-para">dox2U is purpose built to manage documents and be more than a generic storage solution that silos your information.</p>
                <a (click)="scrollToGettingStarted()" target="_self"> <button class="button-primary">Try dox2U</button></a>
              </div>
            </div>
            
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 justify-content-center">
              <img src="../../../../assets/Image/problems-we-are-solving/why-choose-dox2u-banner.png" alt="Benefits of Document Management System (DMS)" class="img-fluid aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
             
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="text-extraction-ocr four-column-pocket-friendly common-padd bottom-padd">
    <div class="container">
          <div class="row">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="2500">
              <div class="features-box guest-access-box grey-box">
                <div class="feature-box-content">
                  <img src="../../../../assets/Image/problems-we-are-solving/Pocket-freindly.svg" alt="Workspaces" class="img-fluid">
                  <h4>Pocket Friendly</h4>
                  <p class="common-para">While other DMSs can be heavy on your pocket, dox2U doesn’t break the bank and offers various subscription plans, including a perpetually free plan, tailored to your needs. You don’t even need to share your credit card details. 
                  </p>
                 
                </div>
              </div>
            </div>
  
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
              <div class="features-box guest-access-box yellow-box">
                <div class="feature-box-content">
                  <img src="../../../../assets/Image/problems-we-are-solving/Team-Oriented.svg" alt="Document handling" class="img-fluid">
                  <h4>Team Oriented </h4>
                  <p class="balanced-text common-para">The current DMS solutions often end up segregating your information into silos. This is not the case with dox2U! We store all your information in a common, accessible space giving you full control over it! </p>
                 
                </div>
              </div>
            </div>
  
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
              <div class="features-box guest-access-box pink-box">
                <div class="feature-box-content">
                  <img src="../../../../assets/Image/problems-we-are-solving/Easy-to-use.svg" alt="Text extraction" class="img-fluid">
                  <h4>Smarter than ever</h4>
                  <p class="balanced-text common-para">Our advanced text extraction capabilities have made working on your docs using dox2U much easier! By converting data locked on paper into workable digital content, we bring you a solution that’s much more advanced.
   
                  </p>
                 
                </div>
              </div>
            </div> 
          </div>
        </div>
      
    
  </section>

  <section class="manage-document-better"> 
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row align-items-center">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="manage-document-img d-flex justify-content-end">
                <img src="../../../../assets/Image/problems-we-are-solving/manage-documents.svg" alt="Manage Documents with dox2U" class="img-fluid">
              </div>
            </div>
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="manage-document-conent">
                <h2 class="heading-2">Simply put, manage documents better</h2>
                <ul class="m-0 p-0">
                  <li class="d-flex "><img src="../../../../assets/Image/problems-we-are-solving/blue-circle-tick.svg" alt="" class="img-fluid"><p class="common-para">dox2U provides you with key functionality to manage your documents more easily and efficiently.</p></li>
                  <li class="d-flex "><img src="../../../../assets/Image/problems-we-are-solving/blue-circle-tick.svg" alt="" class="img-fluid"><p class="common-para">Thanks to our digital replication of a filing cabinet, dox2U’s Smart Cabinet, you can now use filters to quickly locate the files you need. </p></li>
                  <li class="d-flex "><img src="../../../../assets/Image/problems-we-are-solving/blue-circle-tick.svg" alt="" class="img-fluid"><p class="common-para">With easy upload, edit, and share, dox2U has taken doc management to a whole new dimension! </p></li>
                  <li class="d-flex "><img src="../../../../assets/Image/problems-we-are-solving/blue-circle-tick.svg" alt="" class="img-fluid"><p class="common-para">With granular settings like setting naming conventions, say goodbye to documents that outline how to organize your “drive.” </p></li>
                </ul>
              
              </div>
            </div>
          <div>
        </div>
      </div>
    </div>
  </div></div></section>

  <section class="physical-cabinets common-padd">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="solutions-fall-fat-content mx-auto">
            <h2 class="heading-2">What sets us apart from others?</h2>
            <p class="common-para">We are simply the best document management system, and we do <br class="line-break"> not say it just for the sake of it!</p>
            </div>
          </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row d-flex align-items-center small-screens">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
              <div class="retrieval-as-easy">
              <h6 class="heading-6">DO-IT-YOURSELF</h6>
                <h3 class="heading-3">We’re a DIY Solution</h3>
                <p class="common-para">Built to make DMSs mainstream and not just for large enterprises anymore, setting up dox2U is extremely easy. Just sign up in 3 easy steps, invite your team members, upload your first few docs and you’re all set. Getting onto dox2U is as easy as signing up for an email account.</p>
                <button class="icon-button"><a (click)="scrollToGettingStarted()"><img src="../../../../assets/Image/problems-we-are-solving/button-errow.svg"></a></button> <a (click)="scrollToGettingStarted()" class="link-text">Create workspace</a>
  
              </div>
            </div>
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
              <img src="../../../../assets/Image/problems-we-are-solving/do-it-yourself.svg" alt="Create Workspace with dox2U" class="img-fluid drop-shadow aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="physical-cabinets common-padd bottom-padd">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row d-flex align-items-center">
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start">
              <img src="../../../../assets/Image/problems-we-are-solving/built-for-everyone.png" alt="Get Started with dox2U" class="img-fluid drop-shadow aos-init aos-animate" data-aos="fade-right" data-aos-duration="2500">
            </div>
  
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
              <div class="retrieval-as-easy">
              <h6 class="heading-6">BUILT FOR EVERYONE</h6>
                <h3 class="heading-3">We’re built for everyone </h3>
                <p class="common-para">With zero requirements for additional customizations and expensive plug-ins, we are a one-fit-for-all model for all types of firms. Along with features that are useful for you regardless of your team’s size, industry, or use case, we even offer a plan that is free-forever. At dox2U, we truly believe that the sky's the limit; we’re always trying to build to help you achieve more.</p>
  
                <button class="icon-button"><a href="built-for-everyone"><img src="../../../../assets/Image/problems-we-are-solving/button-errow.svg"></a></button> <a href="built-for-everyone" class="link-text">Learn More</a>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="footer-bg " >
    <app-getting-started></app-getting-started>
  </section>
  <app-gradient-divider></app-gradient-divider>
