<section
  class="workspaces-banner guest-access-banner overflow-hidden"
  id="one"
  data-color="#fff"
>
  <app-product-left-text-with-button
    [contentH]="guestAccessHeroText"
    [imgSrc]="guestAccessHero_img"
  ></app-product-left-text-with-button>
</section>
<section class="schedule-demo-banner">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="schedule-banner-content d-flex align-items-center">
          <div class="schedule-banner-text d-flex align-items-center">
            <img
              src="../../../../assets/Image/guest-access/schedule-demo.svg"
              alt=""
              class="img-fluid"
            />
            <div>
              <h6>DEMO</h6>
              <h2 class="heading-2">Let our team help you out.</h2>
            </div>
          </div>

          <div>
            <a
              href="https://calendly.com/dox2u-dms/dox2u-demo"
              target="_blank"
            >
              <button class="demo-btn">Schedule a demo</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="product-guest common-padd top-padd">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="solutions-fall-fat-content mx-auto">
          <h2 class="heading-2">Guest Access</h2>
          <p class="common-para">
            dox2U lets you share documents with external guests in a secure and hassle-free way!
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="">
  <app-right-image-left-text
    [imgUrl]="guest_share_img"
    [content]="shareWithGuest"
  ></app-right-image-left-text>
</section>
<section class="feature-left-bg">
  <app-left-image-right-text
    [imgUrl]="guest_revoke_img"
    [content]="revokeAccess"
  ></app-left-image-right-text>
</section>
<section class="">
  <app-right-image-left-text
    [imgUrl]="guest_safe_img"
    [content]="It_Is_SAFE"
  ></app-right-image-left-text>
</section>
<section>
  <!-- <app-insert-image-description class="whatsapp-integartion-info"
[contentDescribeImage]="whatsappIntegrationText"
[imgSrc]="whatsappIntegrationImg"
></app-insert-image-description> -->
<div class="container productLeft overflow-hidden change-bg-whatsapp whatsapp-integartion-info" id="whatsappIntegrationText">
  <div class="">
    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto image-description">
      <div class="row d-flex align-items-center row-direction p-3">

        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center flex-wrap content-feature">
          <div class="workspace-banner-content document-handling-banner-content image-content">
            <h6 class="heading-6 text-uppercase">WhatsApp Integration</h6>
            <h1 class="heading-1">One click sharing on WhatsApp</h1>
            <p class="common-para">dox2U seamlessly integrates with WhatsApp for secure document sharing through secure links, controlled by OTPs and duration settings. No complex setup—just add <a class="dcoins-hover" title="WhatsApp being a paid service requires you to add dCoins to your dox2U wallet"> dCoins </a> to your wallet and start sharing instantly. Receive status updates on document delivery or reading.</p>
            

          </div>
        </div>

        <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->

        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">

          <img src={{whatsappIntegrationImg}} alt="document handling system" class="img-fluid img-size">

          <video #myVideo id="myVideo" width="803" height="452" controls>
            <source src={{videoURL}} type="video/mp4">
      Your browser does not support the video tag.
          </video>

        </div>
      </div>
    </div>
  </div>
</div>
</section>
<section class="more-features g-access" id="check12">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
        <div class="row">
            <div class="more-features-top-content mx-auto">
              <h2 class="heading-2">More features</h2>
            </div>
        </div>
        <div class="row d-flex  justify-content-center feature-card-main">
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 feature-cards">
          <a routerLink="/security" class="feature_text">
            <div class="more-features-box">
              <img src="../../../../assets/Image/guest-access/security-reliability-green.svg" alt="Guest sharing" class="img-fluid">
              <h4 class="heading-4">Security & reliability</h4>
              <p>AES-256-GCM powered encryption</p>
            </div>
          </a>
          </div>

          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 feature-cards">
          <a routerLink="/text-extraction"  class="feature_text">
            <div class="more-features-box">
              <img src="../../../assets/Image/security-reliability/text-extraction.svg" alt="Text extraction" class="img-fluid">
              <h4 class="heading-4">Text extraction</h4>
              <p>Track the status of your documents</p>
            </div>
          </a>
          </div>

          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 feature-cards">
          <a routerLink="/document-handling" class="feature_text">
            <div class="more-features-box">
              <img src="../../../../assets/Image/guest-access/workspaces.svg"  alt="Document handling" class="img-fluid">
              <h4 class="heading-4">Document handling</h4>
              <p>Upload, verify, track, edit and distribute your work easily.</p>
            </div>
          </a>
          </div>

          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 feature-cards"> 
          <a routerLink="/feature"  class="feature_text">
            <div class="more-features-box ">
              <br><br>
              <img src="../../../assets/Image/security-reliability/view-all.svg" alt="View all" class="img-fluid">
              <h4 class="heading-4">View all</h4>
             
            </div>
          </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="getstart_color">
  <app-getting-started></app-getting-started>
</section>
 
<app-gradient-divider></app-gradient-divider>


