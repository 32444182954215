import { Component, HostListener, Input } from '@angular/core';
import { insertImageDescriptionH } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-insert-image-description',
  templateUrl: './insert-image-description.component.html',
  styleUrls: ['./insert-image-description.component.scss']
})
export class InsertImageDescriptionComponent {
  constructor() {}

  @Input () contentDescribeImage!:insertImageDescriptionH;
  @Input() imgSrc:string=" ";
  @Input() videoUrl:string=" ";
  videoElement!: HTMLVideoElement;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const isVideoInViewport = this.isElementInViewport(this.videoElement);
    console.log("videoElement", this.videoElement)
  //   if(!this.userInteracted) {
    if (!isVideoInViewport) {     
      //this.videoElement.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      this.videoElement.pause();  
    }
  }

  private isElementInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }
}
