import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private apiUrl = 'https://api.customer.io/v1/send/email';
  private apiKey = environment.apiKey;
  isScrollTally = false;
  headerOff = new Subject();

  constructor(private http: HttpClient) {}
    
    sendEmail(to: string, messageId: string, messageData: any, email:any): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.apiKey
      });
    
      const requestBody = {
        to,
        transactional_message_id: messageId,
        message_data: messageData,
        identifiers: {
          id: email
        }
      };
    
      return this.http.post(this.apiUrl, requestBody, { headers });
    }
}
