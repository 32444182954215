<section class="document-handling-hero overflow-hidden">
<app-product-left-text-with-button [imgSrc] = "document_heroIMG"[contentH]="productHandle"></app-product-left-text-with-button >
</section>
<section class="document-handling overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row">
            <div class="workspace-top-content document-handling-top-content">
                <h2 class="heading-2">How does dox2U handle your documents seamlessly?</h2>
                <p class="common-para">dox2U lets you achieve more with your documents and do your best work.</p>
            </div>
          </div>
  
          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div class="document-handling-box">
  
                <a (click)="scrollToSectionUpload()">
                  <div>
                    <div class="problem-img-box">
                      <img [src]='upload'alt="Upload" class="img-fluid">
                        <!-- dots image -->
                      <img [src]='handling_dot' alt="" class="img-fluid problem-box-dots">
                    </div>
                    <h4 class="heading-4">Upload</h4>
                    <p class="common-para">Upload documents to your dox2U workplace.</p>
                  </div>
                </a>
              </div>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div class="document-handling-box">
                <a href="#tag">
                  <div>
                    <div class="problem-img-box">
                      <img [src]='handling_tag'  alt="" class="img-fluid">
                      <!-- dots image -->
                      <img [src]='handling_dot'  alt="" class="img-fluid problem-box-dots">
                    </div>
                    <h4 class="heading-4">Tag</h4>
                    <p class="common-para">Tag your documents with ease as you verify them</p>
                  </div>
                </a>
                </div>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div class="document-handling-box">
                <a href="#share">
                <div>
                  <div class="problem-img-box">
                    <img [src]='handling_share'  alt="" class="img-fluid">
                    <!-- dots image -->
                    <img [src]='handling_dot' alt="" class="img-fluid problem-box-dots">
                  </div>
                  <h4 class="heading-4">Share</h4>
                  <p class="common-para">Control who can share  docs in your workspace </p>
                </div>
              </a>
              </div>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div class="document-handling-box">
                <a href="#retrive">
                  <div>
                    <div class="problem-img-box">
                    <img [src]='handling_retrive'  alt="Get more done" class="img-fluid">
                    </div> 
                    <h4 class="heading-4">Retrieve </h4>
                    <p class="common-para">dox2U lets you retrieve documents easily</p>
              
                  </div>
                </a>
              </div>
            </div>
  
          </div>
  
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
              <div class="details-button text-center">
                <!-- <p class="common-para uploadTab"><a (click)="scrollToSectionUpload()">Details &nbsp;&nbsp;<img src="../../../../assets/Image/document-handling/details-errow.svg" alt="" class="img-fluid details-errow"></a></p> -->
                <a (click)="navigateToFeature()"> <button class="button-primary">See more features</button></a>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </section>
  <section>
    <app-product-r-image-left-text [imgUrl] = "handling_upload" [content]="uploadText"></app-product-r-image-left-text>
  </section>
<section class="approve-your-document anchor-scroll-margin" id="tag">
  <app-product-l-image-right-text [imgSrc] = "handling_DeepSearch"[contentH]="Share" ></app-product-l-image-right-text >
</section>
<section>
  <app-product-r-image-left-text [imgUrl] = "product_Doc_share" [content]="dox2u_share"></app-product-r-image-left-text>
</section>
<section class="everything-your-fingertips anchor-scroll-margin" id="retrive">
  <app-product-l-image-right-text [imgSrc] = "handling_Retrive"[contentH]="retrive" ></app-product-l-image-right-text >
</section>
<section class="document-handling-hero insertImageWithDescription  overflow-hidden">
<app-insert-image-description
[contentDescribeImage]="insertImageWithDescriptionText"
[imgSrc]="insertImageWithDescriptionImg"
></app-insert-image-description></section>
<section class="footer-bg " >
  <app-getting-started id="gettingStarted"></app-getting-started>
</section>
<app-gradient-divider></app-gradient-divider>
