import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  baseOrigin = environment.baseOrigin;
  baseUrl = environment.baseUrl;
  baseUrl97 = environment.baseURL97;

  constructor(private http: HttpClient) { }

  /**
   * get request
   * @param paramter ]
   * @returns 
   */
  get(paramter: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}${paramter}`);
  }

  /**
   * post request
   * @param url ]
   * @param body 
   * @returns 
   */
  post(url:string,body: object): Observable<any> {
    return this.http.post(`${this.baseUrl}${url}`, body);
}
}
