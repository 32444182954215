export const productHanle  = {
    heading: 'DOCUMENT HANDLING',
    subTitle: 'Document Management has never',
    HighlightedText:'been easier!',
    description: "Leave the management of all your documents to dox2U. Upload, verify, tag, edit, and distribute your work easily.",
    buttonText: 'Try dox2U' 
}

export const insertImageWithDescriptionText = {
    heading: 'Doc Preview',
    subTitle: 'Readily view docs quickly!',
    description: "dox2U’s unique first-of-its-kind feature offering a quick preview of documents without having to open & view them. Access this preview from anywhere in your workspace to identify your document quickly. Our contribution to helping you work smartly and efficiently!",
    buttonText: '' 
}

export const whatsappIntegrationText = {
    heading: 'WhatsApp Integration',
    subTitle: 'One click sharing on WhatsApp',
    description: "dox2U seamlessly integrates with WhatsApp for secure document sharing through secure links, controlled by OTPs and duration settings. No complex setup—just add dCoins to your wallet and start sharing instantly. Receive status updates on document delivery or reading.",
    buttonText: '' 
}

// export const desktopappText = {
//     heading: 'dox2U Desktop App',
//     subTitle: 'Shortcut to quick digitization',
//     description: 'Streamline your document management by automating the upload process to the dox2U cloud. Forget about manual file transfers—just deposit your files or scanned documents into your designated folder, and watch as they seamlessly synchronize with your dox2U workspace.',
//     buttonText: '' 
    
// }

export const docViewerText = {
    heading: 'Doc Viewer',
    subTitle: 'New improved Document Viewer',
    description: 'Our enhanced document viewer now features updated sections for swift information retrieval. The new "Tally DMS" section provides quick access to related Tally transaction details. The restructured Viewer simplifies key information editing and sets the stage for upcoming AI-assisted functionalities.',
    buttonText: '' 
    
}

export const improvedCabinetText = {
    heading: 'Improved Cabinet',
    subTitle: 'Faceted precision made simpler',
    description: "dox2U enhances organizational cohesion, breaking down silos with an improved Smart Cabinet experience. Expanded filter options and a dedicated Tally Cabinet revolutionize document management. Finding what you need from thousands of documents is now just a click away!",
    buttonText: '' 
}

export const security  = {
    heading: 'Security & Reliability',
    subTitle: 'Experience safety, reliability,',
    HighlightedText: 'and security!',
    description: "Privacy and security have always been our highest priority. Our features and technology have been carefully crafted to provide you with the most secure experience possible.",
    buttonText: 'Try dox2U' 
}
export const uploadHandle  = {
    heading: 'UPLOAD',
    subTitle: 'Streamline your document upload process',
    text1: "Upload docs from your device onto dox2U swiftly and in bulk numbers.",
   text2:"Assign team-mates who approve uploaded documents before adding them to your workspace.",
   text3:"dox2U supports a variety of image-based and editable formats.",
   textHyper:"Try now"
}
export const shareGuest_access= {
    heading: 'SHARE',
    subTitle: 'Collaborate with your team and do your best work',
    text1: "Share documents with team members, groups or everyone in your workspace.",
   text2:"Easily keep a track of activities on each shared doc.",
   text3:"Share documents with external guests without needing them to sign up for your workspace.",
   textHyper:"See how Guest Access works"
}
export const DocumentHandle_SHARE  = {
    heading: 'TAG',
    subTitle: 'Seamlessly organize your documents using tags',
    text1: "Add unique tags to each doc, making them easier to retrieve.",
   text2:"Create custom templates for tagging that can be used by your entire team.",
   text3:"dox2U’s flexible tagging infrastructure allows you to add custom dropdowns, text or date-type meta tags to your document.",
   textHyper:"Deep Search"
}

export const DocumentHandle_retrive  = {
    heading: 'RETRIEVE',
    subTitle: 'Retrieve documents at your fingertips!',
    text1: "Search for your docs easily through its name or even the smallest bits of content.",
   text2:"Filter through countless documents and get to the doc you want hassle-free!",
   text3:"Edit, print, download and share your docs as your search and retrieve!",
   textHyper:"Join dox2U"
}

export const imgContent_security  = {
    heading: 'AUDIT LOGS',
    subTitle: 'Monitor each activity performed on documents',
    description: "With dox2U’s exhaustive Audit Logs, you can access all information related to different activities performed in your workspace on click of a button. This helps secure your information while maintaining statutory compliances.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
// ------------------smart cabinet content-------------------------?
export const smartCabinet = {
    heading: 'Smart Cabinet',
    subTitle: 'Silo-free document management with dox2u’s',
    HighlightedText: 'Smart Cabinet',
    description: "dox2U’s Smart Cabinet is here to revolutionize the way you think of document management, forever! ",
    buttonText: 'Start organizing',
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const tags_filter = {
    heading: 'TAGS AND FILTERS',
    subTitle: 'Retrieval made easy!',
    description: "Use our unique multi-level nested filters to narrow down your document retrieval; just like you do while shopping online on your favorite e-commerce site",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const dynamic_structure = {
    heading: 'DYNAMIC STRUCTURE',
    subTitle: 'Holistic View of Workspace',
    description: "Unlike other solutions and digital repositories, info within dox2U is not segregated in folders, rather it can be structured dynamically as you choose to see it",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const workLike = {
    heading: 'Break down silos',
    subTitle: 'Common pool of information',
    description: "dox2U has been engineered upon creating a common repository for all documents and not have information siloed for each individual; what gets in becomes part of your information warehouse",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}

// ----------------more feature---------------------?
export const morefeatureCard =[
 
        {
          "heading": "purple",
          "type": "minivan",
          "registration": new Date('2017-01-03'),
          "capacity": 7
        },
        {
          "color": "red",
          "type": "station wagon",
          "registration": new Date('2018-03-03'),
          "capacity": 5
        },
      
    
  
] 



// text-extraction  content-------------------------------------/
export const textExtractionHero  = {
    heading: 'Text Extraction',
    subTitle: "Liberate data from paper and images with dox2U's intelligent",
    HighlightedText: 'Text Extraction engine',
    description: "dox2U facilitates seamless work on both text and image-based documents.",
    buttonText: 'Try dox2U' 
}
export const bringValue = {
    heading: 'Intelligent Recognition',
    subTitle: 'Language is not a barrier!',
    description: "Upload a document with unstructured, non-tangible data and get it converted to useful information regardless of the language. In fact, we support more than 160 of them!",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const goStatic = {
    heading: 'GO STATIC TO DYNAMIC',
    subTitle: 'Get the most out of your data!',
    description: "dox2U converts your static data into formats that bring value to you. Download the extracted text or search, copy and manipulate data from scanned images and PDFs    ",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const bringValue_offering = {
    heading: 'BRING VALUE TO DATA',
    subTitle: 'dox2U: offering unparalleled intelligence',
    description: "With additional layers of intelligence built on top of OCR , dox2U’s text extraction engine provides highly accurate results. Just upload your doc and we’ll instantly take care of the rest. ",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const icr = {
    heading: 'ICR',
    subTitle: 'Hand writing recognition',
    description: "dox2U’s implementation of Intelligent Character Recognition (ICR) can recognize handwritten text from documents and convert them to digital content faciltating further processing.. ",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
// guest access  content-----------------------------------
export const guestAccessHero = {
    heading: 'Guest Access',
    subTitle: 'Share your docs with anyone, ',
    HighlightedText: 'anywhere',
    description: "Using dox2U to share docs with external vendors, customers and guests has made collaboration easier than ever.",
    buttonText: 'Try dox2U' 
}
export const shareWithGuest= {
    heading: 'SHARE WITH GUESTS',
    subTitle: 'Share without asking your guests to sign-up!',
    description: "Your guests can collaborate with you on a shared doc without needing an account on dox2U! invite others to view a document by simply giving them access over email or WhatsApp",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const removeAccess = {
    heading: 'REVOKE ACCESS ANYTIME',
    subTitle: 'Control how long you share for',
    description: "With auto-expiration, dox2U gives you the freedom to define the duration for which a guest can access your doc. Revoke access whenever you need to and take control of your information.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const isitSafe = {
    heading: 'IT IS SAFE!',
    subTitle: 'Stay secure throughout your sharing process',
    description: "Keeping your docs secure is a high priority for us at dox2U. We let you know when a guest views your doc and prevent them from taking screenshots of your confidential information..",
    boldText: ' ' ,
    description1:'',
    boldText1:''  
}

// deep search---------------------------'

export const deepSearchHero = {
    heading: 'Deep Search',
    subTitle: 'Introducing our sharp edge, precise',
    HighlightedText: 'search technology',
    description: "dox2U facilitates seamless work on both text and image-based documents.",
    buttonText: 'Try dox2U',
    boldText: ' ' ,
    description1:'',
    boldText1:''
}

export const docketsHeroText = {
    heading: 'DOCKETS',
    subTitle: 'Organize documents like never before using',
    HighlightedText: 'dox2U’s Dockets',
    description: "Create virtual collections of documents to organize them in a manner you are used to and track them with checklists!",
    buttonText: 'Start Organizing',
    boldText: ' ' ,
    description1:'',
    boldText1:''
}


export const ContentBased  = {
    heading: 'CONTENT-BASED SEARCH',
    subTitle: 'Not entitled to titles only!',
    text1: "dox2U’s Deep Search takes you one step further in the process of document retrieval",
   text2:"The titles of a document are no longer a mandatory search parameter. We got a bunch of them!",
   text3:"Search for the smallest bits of content included in your doc, and retrieve the right one at your fingertips.",
   textHyper:""
}

export const Contentfilture = {
    heading: 'FILTER THROUGH',
    subTitle: 'Filter it out!',
    text1: "Streamline your document retrieval process by adopting dox2U’s smart search filters.",
   text2:"With advanced filters, you get to seep through an assortment of documents seamlessly!",
   text3:"Filter through all the documents and fetch the ones you need in a matter of seconds!.",
   textHyper:""
}

export const Contentshort = {
    heading: 'SEARCH RELEVANCE',
    subTitle: 'Sort things out like a pro!',
    text1: "Prioritize which document do you want to see first as you sort them by date, time, content or title.",
   text2:"This is especially useful when Deep Search retrieves multiple documents with similar underlying content.",
   text3:"With multiple view options, get your documents sorted the way you prefer to see them.",
   textHyper:""
}

// docket----------------------------------------------/
export const convinience = {
    heading: 'CONVENIENCE',
    subTitle: 'Your process, your collection!',
    description: "Get an organized view of documents grouped together as per your business processes like Customer Records, Purchase Transactions, Sales Transactions etc. The convenience is unmatched!",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const flexibility = {
    heading: 'FLEXIBILITY',
    subTitle: 'Organize documents the way you prefer',
    description: "Create customized collections of documents (aka Dockets) that can be molded to fit your business needs. Group documents together using common identifiers and the types of documents you wish to collate.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}

export const tracability = {
    heading: 'TRACEABILITY',
    subTitle: 'No document goes missing',
    description: "Track the health of your process-related documentation using the checklists built into Dockets, which give you a snapshot of the information that is incomplete or missing. Tracking your documents has never been easier!",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const connetedInformation= {
    heading: 'VIRTUAL COLLECTION',
    subTitle: 'Breaking down info Silos',
    description: "Documents tend to get lost with geographically distributed business operations and often end up in individual silos. Now access all information from a single location and organized into collections as per your business process.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}

// resource content---------------------/


export const buildforEveryone = {
    heading: 'Built for everyone',
    subTitle: 'dox2U is specially curated to suit every business type!',
    HighlightedText: '',
    description: "Whether you are a lawyer, charted accountant, SME or any existing business, dox2U serves you well.",
    buttonText: 'Try dox2U' 
}

// morefeature content

export const mfGuest1 = {
    heading: 'Security & reliability',
    subheading:'AES-256-GCM powered encryption',
    cardImg:'../assets/Image/guest-access/security-reliability-green.svg'
}
export const mfGuest2 = {
    heading: 'Text extraction',
    subheading:'Track the status of your documents',
    cardImg:'../assets/Image/guest-access/text-extraction.svg',
}
export const mfGuest3 = {
    heading: 'Workspaces',
    subheading:'A new home for all your team-mates and documents',
    cardImg:'../assets/Image/guest-access/workspaces.svg'
}
