import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-widget-popup',
  templateUrl: './widget-popup.component.html',
  styleUrls: ['./widget-popup.component.scss'],
  animations: [
    trigger('slideInFromLeft', [
      state('visible', style({ transform: 'translateX(0)' })),
      state('hidden', style({ transform: 'translateX(-100%)' })),
      transition('hidden => visible', animate('300ms ease-in')),
    ]),
  ],
})
export class WidgetPopupComponent implements OnInit {
  showWidget = 'hidden';

  ngOnInit() {
    setTimeout(() => {
      this.showWidget = 'visible';
    }, 5000);
  }
}
