import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-eightcards-document',
  templateUrl: './eight-cards.component.html',
  styleUrls: ['./eight-cards.component.scss'],

})
export class EightCardsComponent implements OnInit  {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    center: true,
   // navSpeed: 600,
    //autoplay: false,
    //autoplayTimeout: 1000,
    autoplay: true,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      560: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
  }
  constructor() {
   
  
   }

  
  ngOnInit(): void {
    
    
  }



}

