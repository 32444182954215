import { ContentP } from "src/app/interfaces/interfaces"

export const imgContent = {
    heading: 'SaaS',
    subTitle: 'Secure cloud DMS',
    description: "We are re-defining Document Management by offering it as a Service. No setup fees, no infrastructure expenses, no overheads, no expensive consultants! dox2U is a simple",
    boldText: ' ' ,
    description1:'',
    boldText1:'Solution-as-a-Service' 
}

export const imgContent1 = {
    heading: 'BREAK SILOS',
    subTitle: 'Keep it together',
    description: "dox2U consolidates all your documents, whether paper-based or created in your ERP/HRMS/CRM, into a centralized repository. Our integrations and features, such as",
    boldText: ' Dockets & Smart Cabinet,' ,
    description1:'streamline your data unification',
    boldText1:'' 

}

export const imgContent2 = {
    heading: 'SECURE',
    subTitle: 'Encrypted & controlled',
    description: "Our top priority is to give you control over your information. We ensure its security using",
    boldText: ' bank-grade encryption' ,
    description1:' & safety protocols and provide complete transparency through granular access controls and audit logs',
    boldText1:'' 

    
}

export const PWSContent1 = {
    heading: '',
    subTitle: 'Rapid Growth',
    description: "Join the fastest-growing document management software for SMBs. Our success is driven by a large network of partners across India, and we have ambitious plans for further expansion.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 

    
}

export const PWSContent2 = {
    heading: '',
    subTitle: 'Rapid Growth',
    description: "Join the fastest-growing document management software for SMBs. Our success is driven by a large network of partners across India, and we have ambitious plans for further expansion.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 

    
}

export const PWSContent3 = {
    heading: '',
    subTitle: '',
    description: "Join the fastest-growing document management software for SMBs. Our success is driven by a large network of partners across India, and we have ambitious plans for further expansion.",
    boldText: ' Solution-as' ,
    description1:'',
    boldText1:'' 

}

export const PWSContent4 = {
    heading: '',
    subTitle: 'Rapid Growth',
    description: "Join the fastest-growing document management software for SMBs. Our success is driven by a large network of partners across India, and we have ambitious plans for further expansion.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 

}

export const imgContentH = {
    heading: '',
    textStart: 'Work',
    underline: 'smarter',
    textEnd: ', not harder',
    text: 'Secure and simplified document management, bringing scattered & siloed information together',
  

}

export const ContentList = {
    heading: 'Easy one-click access to documents',
    description: ['Effortlessly link and view documents related to Tally transactions and masters',
                  'Stop dependency on accountants or manual document searches',
                  'Convenience of having all important documents readily available at your fingertips']
}

export const ContentListR = {
    heading: 'Stop printing & filing',
    description: ['Create a digital vault for all your financial records and stop storage on paper',
                  'Store documents on secure cloud for as many years to meet audit compliances',
                  'Easily find and retrieve organized documents']
}

export const ContentList2 = {
    heading: 'Anytime, anywhere information',
    description: ['Instantly access and retrieve your documents anytime, anywhere',
                  'Enhance collaboration and communication by easily sharing relevant documents with colleagues or clients']
}

export const ContentList3 = {
    heading: 'Security and protection of documents',
    description: ['Experience peace of mind knowing that your documents are securely stored on cloud and backed up',
                  'Documents are protected against theft, physical damage, or natural disasters']
}

export const ContentList4 = {
    heading: 'Go digital. Get Paperless. Work smarter',
    description: ['Increase productivity and save time by efficiently managing and organizing your documents within Tally',
                  'Optimize workflow and decision-making by having all necessary documents readily available for analysis and planning.']
}

export const ContentList5 = {
    heading: 'Reduce cost',
    description: ['Effortlessly integrate cloud DMS into your existing Tally setup, without any complex configurations or installations.',
                  'Streamline document-related processes, minimizingb errors and costly mistakes',
                'Avoid the expenses associated with physical document storage, such as printing, paper, and filing cabinets.']
}

export const ResellerContent = {
    heading: '',
    subTitle: '',
    description: "At dox2U, we partner with the most innovative companies worldwide to facilitate businesses on their digital transformation journey. Our diverse, trusted set of partners serve customers of all sizes, industries, and geographies. As part of our partner program, organizations earn exclusive benefits — such as marketing and sales support, plus technical enablement — to power business transformation.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}

export const imgContentReseller1 = {
    heading: '',
    subTitle: 'Rapid Growth',
    description: "Join the fastest-growing document management software for SMBs. Our success is driven by a large network of partners across India, and we have ambitious plans for further expansion.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}

export const imgContentReseller2 = {
    heading: '',
    subTitle: 'Affordability & Simplicity',
    description: "Join the fastest-growing document management software for SMBs. Our success is driven by a large network of partners across India, and we have ambitious plans for further expansion.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}

export const imgContentReseller3 = {
    heading: '',
    subTitle: 'Features & Security',
    description: "Our solution stands out with its innovative features and robust security measures. We continuously introduce new capabilities to help businesses improve their operations.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}

export const imgContentReseller4 = {
    heading: '',
    subTitle: 'Dedicated Support',
    description: "Our support teams are dedicated to providing prompt assistance to both customers and partners. We prioritize efficient issue resolution to minimize any disruptions.",
    boldText: ' ' ,
    description1:'',
    boldText1:''  
}

export const imgContentDMSAddon1 = {
    heading: 'STEP 1',
    subTitle: 'Install the Plugin',
    description: "Once the download finishes, just follow the instructions on the installer to install the Tally Plugin. If you want to configure the TDL locally, our installer will take care of everything for you. Alternatively, if you want to configure the plugin as an account TDL, upload the extracted TCP to your Tally Portal and update your Tally License. ",
    boldText: ' ' ,
    description1:'For detailed instructions on installing as an "Account TDL", please ',
    boldText1:''
}

export const imgContentDMSAddon2 = {
    heading: 'STEP 2',
    subTitle: 'Enable the Tally Plugin',
    description: "From Tally, navigate to Help > TDL & AddOns > Add-on Features (or press Ctrl + Alt + T followed by F6), to open the Add-on Features menu, from where you can enable the Tally Plugin! ",
    boldText: ' ' ,
    description1:'',
    boldText1:''
}

export const imgContentDMSAddon3 = {
    heading: 'STEP 3',
    subTitle: 'Create your dox2U Workspace',
    description: "To access the dox2U Tally Plugin, you will need to have a dox2U Workspace where all your supporting documents can be stored. You can create the Workspace from our Website or by clicking the Create Workspace option from within the Tally DMS Plugin",
    boldText: ' ' ,
    description1:'',
    boldText1:''
}

export const imgContentDMSAddon4 = {
    heading: 'STEP 4',
    subTitle: 'Enter your Workspace Credentials',
    description: "Once you have created your Workspace, you must verify your access by entering your Workspace credentials within the Tally DMS Plugin",
    boldText: ' ' ,
    description1:'',
    boldText1:''
}

export const imgContentDMSAddon5 = {
    heading: 'STEP 5',
    subTitle: 'Attach documents to a Voucher / Master',
    description: "Use the Tally interface to attach supporting documents to your Voucher or Master. Each document you attach will automatically get pushed to dox2U and maintained in a secure cloud storage.",
    boldText: ' ' ,
    description1:'',
    boldText1:''
}

export const imgContentDMSAddon6 = {
    heading: 'STEP 6',
    subTitle: 'Unleash the power of dox2U’s Tally DMS Plugin!',
    description: "Invite your teammates onto the Tally DMS Plugin or add as many free Tally Viewers to collaborate on documented information. Use the embedded URL to view the document within seconds directly from the Voucher / Master! Working with documents on Tally has never been easier",
    boldText: ' ' ,
    description1:'',
    boldText1:''
}


export const pricingCardBS: ContentP = {
    planName: 'Business Starter',
    planIntro: 'Suitable for personal and small businesses',
    annualPMPriceINR: '₹292',
    annualPMPriceUS: '$4.17',
    twoYearPMPriceINR: '₹263',
    twoYearPMPriceUS: '$3.75',
    threeYearPMPriceINR: '₹248',
    threeYearPMPriceUS: '$3.56',
    anuualPriceINRTooltip: '₹3,499',
    anuualPriceUSTooltip: '$50',
    twoyearsPriceINRTooltip: '₹6,299',
    twoyearsPriceUSTooltip: '$90',
    threeyearsPriceINRTooltip: '₹8,923',
    threeyearsPriceUSTooltip: '$128',
    usersCount: '1 User included',
    addonUsersCount: 'No Add-on Users',
    addOnPriceAnnualINR: 'No Add-on Users',
    addOnPriceTwoYearsINR: '₹60',
    addOnPriceThreeYearsINR: '₹70',
    addOnPriceAnnualUS: '',
    addOnPriceTwoYearsUS: '',
    addOnPriceThreeYearsUS: ''
   }

   export const pricingCardB: ContentP = {
    planName: 'Business',
    planIntro: 'Document storage & retrieval for small teams',
    annualPMPriceINR: '₹417',
    annualPMPriceUS: '$6.00',
    twoYearPMPriceINR: '₹375',
    twoYearPMPriceUS: '$5.42',
    threeYearPMPriceINR: '₹355',
    threeYearPMPriceUS: '$5.12',
    anuualPriceINRTooltip: '₹4,999',
    anuualPriceUSTooltip: '$72',
    twoyearsPriceINRTooltip: '₹8,999',
    twoyearsPriceUSTooltip: '$130',
    threeyearsPriceINRTooltip: '₹12,748',
    threeyearsPriceUSTooltip: '$184',
    usersCount: '1 User included',
    addonUsersCount: 'Add-on Users: ₹84',
    addOnPriceAnnualINR: '₹84',
    addOnPriceTwoYearsINR: '₹75',
    addOnPriceThreeYearsINR: '₹71',
    addOnPriceAnnualUS: '$1.25',
    addOnPriceTwoYearsUS: '$1.13',
    addOnPriceThreeYearsUS: '$1.03'
   }

   export const pricingCardBP: ContentP = {
    planName: 'Business Plus',
    planIntro: 'Cross-over plan for teams with advanced document management',
    annualPMPriceINR: '₹667',
    annualPMPriceUS: '$9.59',
    twoYearPMPriceINR: '₹600',
    twoYearPMPriceUS: '$8.63',
    threeYearPMPriceINR: '₹567',
    threeYearPMPriceUS: '$8.17',
    anuualPriceINRTooltip: '₹7,999',
    anuualPriceUSTooltip: '$115',
    twoyearsPriceINRTooltip: '₹14,399',
    twoyearsPriceUSTooltip: '$207',
    threeyearsPriceINRTooltip: '₹20,398',
    threeyearsPriceUSTooltip: '$294',
    usersCount: '1 User included',
    addonUsersCount: 'Add-on Users: ₹134',
    addOnPriceAnnualINR: '₹134',
    addOnPriceTwoYearsINR: '₹120',
    addOnPriceThreeYearsINR: '₹114',
    addOnPriceAnnualUS: '$1.92',
    addOnPriceTwoYearsUS: '$1.75',
    addOnPriceThreeYearsUS: '$1.64'
   }

   export const pricingCardT: ContentP = {
    planName: 'Teams',
    planIntro: 'Curated for teams needing intelligent doc management',
    annualPMPriceINR: '₹917',
    annualPMPriceUS: '$13.34',
    twoYearPMPriceINR: '₹825',
    twoYearPMPriceUS: '$12',
    threeYearPMPriceINR: '₹780',
    threeYearPMPriceUS: '$11.34',
    anuualPriceINRTooltip: '₹10,999',
    anuualPriceUSTooltip: '$160',
    twoyearsPriceINRTooltip: '₹19,799',
    twoyearsPriceUSTooltip: '$288',
    threeyearsPriceINRTooltip: '₹28,048',
    threeyearsPriceUSTooltip: '$408',
    usersCount: '1 User included',
    addonUsersCount: 'Add-on Users: ₹184',
    addOnPriceAnnualINR: '₹184',
    addOnPriceTwoYearsINR: '₹165',
    addOnPriceThreeYearsINR: '₹156',
    addOnPriceAnnualUS: '$2.67',
    addOnPriceTwoYearsUS: '$2.42',
    addOnPriceThreeYearsUS: '$2.28'
   }

// feature component Content


export const imgContent7  = {
    heading: 'CONTROL YOUR INFORMATION',
    subTitle: 'Secure and portable',
    description: "With dox2U's cloud-based solution, you have the flexibility to manage your information anytime anywhere, while maintaining complete control over it. Our system offers advanced security features such as audit logs, granular access control, and bank-grade encryption, ensuring that your data remains secure and compliant with regulations.",
    boldText: '' ,
    description1:'',
    boldText1:'' 
}



export const imgContent8  = {
    heading: 'NO CUSTOMIZATIONS NEEDED',
    subTitle: 'We are a one-fit model for all!',
    description: "At dox2U, we understand that different teams have unique requirements based on their industry and business verticals. That's why we built our system with flexibility in mind, offering a DIY approach that allows you to configure the system to suit your workflows and processes.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}

export const imgContent9  = {
    heading: 'AFFORDABLE AND ACCESSIBLE',
    subTitle: 'Plans for every team',
    description: "dox2U is a cloud-based Solution-as-a-Service that provides unparalleled advantages to both small and large teams. With a distinctive pay-as-you-go approach, you are only charged for the resources you use, allowing you to control costs while maintaining the flexibility to scale up or down as needed.",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}
export const imgContent10  = {
    heading: 'DOC PREVIEW',
    subTitle: 'Readily view docs quickly!',
    description: "dox2U’s unique first-of-its-kind feature offering a quick preview of documents without having to open & view them. Access this preview from anywhere in your workspace to identify your document quickly. Our contribution to helping you work smartly and efficiently!",
    boldText: ' ' ,
    description1:'',
    boldText1:'' 
}




