<section class="eight-cards-section common-padd top-padd bottom-margin overflow-hidden">
  <div class="container">
      <div class="row">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="eight-cards-top-bar text-center">

                  <span class="dot blue-bg"></span>
                  <span class="dot green-ball"></span>
                  <span class="dot yellow-ball"></span>
                  <span class="dot red-ball"></span>

                  <h6 class="heading-6">FEATURES</h6>
                  <h3 class="size-fourty">dox2U: An intelligent document management system (DMS)
                  </h3>
              </div>
                
                
                      <!-- <carousel id="myCarousel" class="displayCarouselDefault" [activeSlide]="activeImageIndex" (activeSlideChange)="updateActiveImageIndex($event)" [itemsPerSlide]="3" [singleSlideOffset]="true"  [interval]="5000" [noPause]="false"> -->
                        <!-- <carousel id="myCarousel" class="displayCarouselDefault carousel" [activeSlide]="activeImageIndex"[singleSlideOffset]="true" (activeSlideChange)="updateActiveImageIndex($event)"  [itemsPerSlide]="3" [interval]="3000">
                        <slide *ngFor="let set of imageSets">
                          <img [src]="set.src" class="d-block w-100" alt="Image">
                          <div class="carousel-captions">
                            <h4 class="image-caption">{{ set.caption }}</h4>
                          </div>
                        </slide>
                      </carousel> -->
                     
                      
                      <div>
                        <owl-carousel-o [options]="customOptions">

                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_dockets1.svg" alt="img 1" class="item" id="img1"/>
                              <h4 class="image-caption">Dockets</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_redundancy.svg" alt="img 2" class="item" id="img2"/>
                              <h4 class="image-caption">Reliability & redundancy</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_audit.svg" alt="img 3" class="item" id="img3"/>
                              <h4 class="image-caption">Administrative & Audit Controls</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_search.svg" alt="img 4" class="item" id="img4"/>
                              <h4 class="image-caption">Intelligent search</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_security.svg" alt="img 5" class="item" id="img5"/>
                              <h4 class="image-caption">Secure Guest Sharing</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/sharing_over_whatsapp.svg" alt="img 6" class="item" id="img6"/>
                              <h4 class="image-caption">Sharing over WhatsApp</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_workspace.svg" alt="img 7" class="item" id="img7"/>
                              <h4 class="image-caption">Workspaces for teams</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_secure.svg" alt="img 8" class="item" id="img8"/>
                              <h4 class="image-caption">Bank Grade Security</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_ocr.svg" alt="img 9" class="item" id="img9"/>
                              <h4 class="image-caption">Advanced OCR / ICR</h4>
                            </div>
                          </ng-template>
                          <ng-template carouselSlide>
                            <div class="slide">
                              <img src="../../../assets/Image/home/dox2u_home_cabinet.svg" alt="img 10" class="item" id="img10"/>
                              <h4 class="image-caption">Administrative & Audit Controls</h4>
                            </div>
                          </ng-template>
                          
                        </owl-carousel-o>
                      </div>
                     

                   
</div>
</div>
</div>
</section>