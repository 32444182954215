<section class="left-img-section common-padd top-padd overflow-hidden">
    <div class="container">
        <div class="row d-flex align-items-center content-container">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start justcent startposition endposition">
                <img alt="dms document management system" src={{imgUrl}} class="img-fluid img-size"data-aos="fade-right" data-aos-duration="2500" loading="lazy"
                loading="lazy"draggable="false">
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0">
                <div class="content-box">
                    <h6 class="heading-6 blue">{{content.heading}}</h6>
                    <h3 class="size-fourty">{{content.subTitle}}</h3>
                    <p class="common-para">{{content.description}}<b>{{content.boldText}}</b>{{content.description1}}<b>{{content.boldText1}}</b></p>
                </div>
            </div>
        </div>
    </div>
</section>
