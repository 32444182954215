// geo-location.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  constructor(private http: HttpClient) {}

  getCountryInfo() {
    return this.http.jsonp('https://geolocation-db.com/json/geoip.php?jsonp=JSONP_CALLBACK', 'callback');
  }
}
