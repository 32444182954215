import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerIOService } from 'src/app/customer-io.service';
import { EmailService } from 'src/app/email.service';
import { ZohoService } from 'src/app/zoho.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-management-system',
  templateUrl: './document-management-system.component.html',
  styleUrls: ['./document-management-system.component.scss']
})
export class DocumentManagementSystemComponent implements OnInit {
  consultForm: FormGroup;
  lastNameValidDiv: boolean = false;
  firstNameValidDiv: boolean = false;
  emailValidDivEmpty: boolean = false;
  emailValidDivInvalid: boolean = false;
  phoneValidDiv: boolean = false;
  submittedForm: boolean = false;
  showConsultationForm: boolean = true;
  constructor(private fb: FormBuilder, private zohoService: ZohoService, private emailService: EmailService, private customerIOService: CustomerIOService) {
    /*
     form validations
    */
    this.consultForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email,
                  Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
      phone: ['', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(15)]],
    });
  }
  ngOnInit(): void {
    let accordianBodyFirst = document.getElementById("collapseOne");
    accordianBodyFirst?.classList.add('show');
  }

   /*
  resetting error message of last name input on keypress
  */
  resetLastNameDiv(event: Event) {
    this.lastNameValidDiv = false;
  }

  /*
  resetting error message of first name input on keypress
  */
  resetFirstNameDiv(event: Event) {
    this.firstNameValidDiv = false;
  }

  /*
  resetting error message of email input on keypress
  */
  resetEmailDiv(event: Event) {
    this.emailValidDivEmpty = false;
    this.emailValidDivInvalid = false;
  }

  /*
  resetting error message of phone input on keypress
  */
  resetPhoneDiv(event: Event) {
    this.phoneValidDiv = false;
  }

   /*
  This method will send the payload for zoho, customer.io and email service on successful validations of all input fields
  */
  onSubmit() {
    if(this.consultForm.get('firstname')?.hasError('required')) {
      this.firstNameValidDiv = true;
    }
  
    if(this.consultForm.get('lastname')?.hasError('required')) {
      this.lastNameValidDiv = true;
    }
  
    if(this.consultForm.get('email')?.hasError('required')) {
      this.emailValidDivEmpty = true;
    }
  
    if(this.consultForm.get('email')?.hasError('email')) {
      this.emailValidDivInvalid = true;
    }
  
    if(this.consultForm.get('phone')?.hasError('required')) {
      this.phoneValidDiv = true;
    }

    let countryName = localStorage.getItem("CountryName");
    let dialCode = localStorage.getItem("DialCode");
    let contactNumber = '';
  
      if (this.consultForm.valid) {
          this.submittedForm = true;
          this.showConsultationForm = false;
          countryName = countryName || "India";
          dialCode = dialCode || "91";
          contactNumber = "+" + dialCode + this.consultForm.value.phone;
  
          /*
          payload for zoho service
        */
          const firstName = this.consultForm.value.firstname;
          const lastName = this.consultForm.value.lastname;
          const fullName = `${firstName} ${lastName}`;
          const email = this.consultForm.value.email;
          const WS_SubscriptionStatus = "Unpaid Customer";
          const leadSource = 'WS-ConsultForm';
          const address1 = '';
          const address2 = '';
          const phoneNo = contactNumber;
          const imgContent_security = '';
          const state = '';
          const country = countryName;
          const orgName = '';
          const companySize = '';
          const jobTitle = '';
          const salutation = '';
          const message1 = '';
          const message2 = '';
  
          /*
            function to send data to zoho service
          */
  
          this.zohoService.sendUserData(firstName, lastName, fullName, 
            email, 
            WS_SubscriptionStatus, 
            leadSource, 
            address1,
            address2,
            phoneNo,
            imgContent_security,
            state,
            country,
            orgName,
            companySize,
            jobTitle,
            salutation,
            message1,
            message2)
          .subscribe(
            (response) => {
              console.log('zoho lead sent:', response);
            },
            error => {
              console.error('Email Error:', error);
            }
          );
  
          /*
          payload for email service
          */
          const recipientEmail = this.consultForm.value.email;
          const messageId = environment.ConsultationFormMessageID;
          const messageData = { First_Name: this.consultForm.value.firstname };
  
            /*
            function to send data to email service
          */
          this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
            .subscribe(
              (response) => {
                console.log('Email sent:', response);
              },
              (error) => {
                console.error('Email Error:', error);
              }
            );
  
              
          /*
            function to send data to customer.io
          */
              this.customerIOService.updateUserLeadSource(email, leadSource)
                .subscribe(
                  (response) => {
                    console.log('User lead source updated successfully:', response);
                  },
                  (error) => {
                    console.error('Error updating user lead source:', error);
                  }
                );
        }
  
        
      }

}
