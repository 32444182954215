<section class="workspaces-banner document-handling-banner overflow-hidden">
    <app-product-left-text-with-button [contentH]="smartcabinetHero"[imgSrc] = "smartcabinetHero_img"></app-product-left-text-with-button>
</section>

<section class="smart-cabinet common-padd overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="smart-cabinet-top-card text-center mx-auto">
            <img src="../../../../assets/Image/feature/unique-dots.svg" class="img-fluid unique-dots">
            <h2 class="heading-3">What makes Smart Cabinet the next <span class="blue">BIG</span> thing?</h2>
          </div>
        </div>
      </div>
      <!-- smart-cabinet-cards -->
      <div class="row">
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 card-container">
          <div class="smart-cabinet-card electronic-cabinet-card"> 
            <div>
              <img src="../../../../assets/Image/smart-cabinet/electronic-cabinet.svg" alt="Electronic Cabinet" class="img-fluid">
            
            <h4 class="heading-4">Electronic Cabinet</h4>
              <p class="common-para">Can other solutions give you a physical-cabinet-like capability, which is not only smart but also lets you work like you’re used to? </p>
           </div>
     
          </div>
        </div>
  
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 card-container">
          <div class="smart-cabinet-card easy-navigation-card">
            <div>
              <img src="../../../../assets/Image/smart-cabinet/navigation.svg" alt="Easy navigation" class="img-fluid">
              <h4 class="heading-4">Easy Navigation</h4>
              <p class="common-para">Can you easily navigate and locate through hundreds and thousands of documents just like you’re scrolling through physical files in a cabinet?</p>
            </div>
          </div>
        </div>
  
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 card-container">
          <div class="smart-cabinet-card pinpoint-documents-card">
            <div>
              <img src="../../../../assets/Image/smart-cabinet/push-pin.svg" alt="Pinpoint documents" class="img-fluid">
              <h4 class="heading-4">One-click Retrieval</h4>
              <p class="common-para">Can you reach a specific file that you consider important, without having to recall their names and other information?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="solutions-fall-flat common-padd smart-cabinet">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="solutions-fall-fat-content mx-auto"> 
            <h2 class="heading-3">Discover the unique offerings of Smart Cabinet</h2>
            <p class="common-para">dox2U redefines search with Faceted Precision
  </p>
          </div>
        </div>
      </div>

    </div>
    <app-right-image-left-text [imgUrl]="tags_filter_img" [content]="smartTags_filter"></app-right-image-left-text>
    <app-left-image-right-text [imgUrl]="dynamic_img"[content]="Dynamic" ></app-left-image-right-text>
    <app-right-image-left-text [imgUrl]="cabinetPhysical_img" [content]="smartLike"></app-right-image-left-text>
  </section>

  <section class="improved-cabinet">
    <app-insert-image-description class="change-bg-whatsapp"
      [contentDescribeImage]="improvedCabinetText"
      [imgSrc]="improvedCabinetImg"
      [videoUrl]="videoURL"
    ></app-insert-image-description>
  </section>
  
  <!-- <app-more-feature-four-card></app-more-feature-four-card> -->
  <section class="more-features g-access" id="check12">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
          <div class="row">
              <div class="more-features-top-content mx-auto">
                <h2 class="heading-2">More features</h2>
              </div>
          </div>
          <div class="row d-flex  justify-content-center feature-card-main">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 cards-container">
            <a routerLink="/guest-access" class="feature_text">
              <div class="more-features-box">
                <img src="../../../assets/Image/security-reliability/guest-sharing.svg" alt="Guest sharing" class="img-fluid">
                <h4 class="heading-4">Guest sharing</h4>
                <p>Upload your docs to your Workspace</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <a routerLink="/text-extraction"  class="feature_text">
              <div class="more-features-box">
                <img src="../../../../assets/Image/smart-cabinet/doc-handling-green.svg" alt="Text extraction" class="img-fluid">
                <h4 class="heading-4">Text extraction</h4>
                <p>Track the status of your documents</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <a routerLink="/security"  class="feature_text">
              <div class="more-features-box">
                <img src="../../../../assets/Image/smart-cabinet/workspaces.svg"  alt="Document handling" class="img-fluid">
                <h4 class="heading-4">Security & reliability</h4>
                <p>AES-256-GCM powered encryption</p>
              </div>
            </a>
            </div>
  
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 "> 
            <a routerLink="/feature"    class="feature_text">
              <div class="more-features-box view-all-box">
                <img src="../../../assets/Image/security-reliability/view-all.svg" alt="View all" class="img-fluid view-all-plus">
                <h4 class="heading-4">View all</h4>
               
              </div>
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="footer-bg " >
    <app-getting-started></app-getting-started>
  </section>
  <app-gradient-divider></app-gradient-divider>
