import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  productItems: boolean = true;
  integrationsItems: boolean = true;
  pricingItems: boolean = true;
  partnersItems: boolean = true;
  resourcesItems: boolean = true;
  legalItems: boolean = true;
  companyItems: boolean = true;
  //windowSize = window.innerWidth;
  setSize = 992;

  windowSize = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router) {}

  ngOnInit(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.toggleItems();
    // }
  } 

  ngAfterViewInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.toggleItems();
    // }
    this.setWindowSize();
    this.toggleItems();
  }

  private setWindowSize() {
    if (isPlatformBrowser(this.platformId)) {
      this.windowSize = window.innerWidth;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.setWindowSize();
  }

  navigateToPrivacyPolicy(elementId: string): void {
    const urlMap: { [key: string]: string } = {
      'scroll-to-term-usage': 'legal',
      'scroll-to-Policy': 'legal',
      'section-head-disclaimer': 'legal/disclaimer',
  };

  const url = urlMap[elementId];
  if(url) {
    this.router.navigateByUrl(url).then(() => {
      setTimeout(() => {
        const hideElement = document.getElementById('section-head');
        const disclaimerElement = document.getElementById('section-head-disclaimer')
        const element = document.getElementById(elementId);
        if (element) {
          if(elementId == 'section-head-disclaimer') {
          element.classList.add('displayChange')
          hideElement?.classList.add('displayNone');
          const viewportHeight = window.innerHeight;
          const elementHeight = element.clientHeight;
          const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
            top: element.offsetTop - elementHeight,
          behavior: 'smooth',
        };
          window.scrollTo(scrollOptions);
        }
        else {
          hideElement?.classList.remove('displayNone');
          disclaimerElement?.classList.remove('displayChange')
          const viewportHeight = window.innerHeight;
          const elementHeight = element.clientHeight;
          const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
            top: element.offsetTop - elementHeight,
          behavior: 'smooth',
        }
        window.scrollTo(scrollOptions);
        };
          
        }
      }, 100); 
    });
  }
}

  private toggleItems() {
    if (isPlatformBrowser(this.platformId) && this.windowSize < this.setSize) {
      this.productItems = false;
      this.integrationsItems = false;
      this.pricingItems = false;
      this.partnersItems = false;
      this.resourcesItems = false;
      this.legalItems = false;
      this.companyItems = false;
    }
  }

  /**
   * Thie method will expand & collapse the 'product' option and show its submenu in responsive for screen width < 992px
   */
  expandProduct() {
    if (isPlatformBrowser(this.platformId) && this.windowSize < this.setSize) {
      this.productItems = !this.productItems;
    }  
  }

  /**
   * Thie method will expand & collapse the 'Integrations' option and show its submenu in responsive for screen width < 992px
   */
  expandIntegrations() {
    if (isPlatformBrowser(this.platformId) && this.windowSize < this.setSize) {
      this.integrationsItems = !this.integrationsItems;
    }  
  }

  /**
   * Thie method will expand & collapse the 'Pricing' option and show its submenu in responsive for screen width < 992px
   */
  expandPricing() {
    if (isPlatformBrowser(this.platformId) && this.windowSize < this.setSize) {
      this.pricingItems = !this.pricingItems;
    }
  }

  /**
   * Thie method will expand & collapse the 'Partners' option and show its submenu in responsive for screen width < 992px
   */
  expandPartners() {
    if (isPlatformBrowser(this.platformId) && this.windowSize < this.setSize) {
      this.partnersItems = !this.partnersItems;
    }
  }

  /**
   * Thie method will expand & collapse the 'Resources' option and show its submenu in responsive for screen width < 992px
   */
  expandResources() {
    if (isPlatformBrowser(this.platformId) && this.windowSize < this.setSize) {
      this.resourcesItems = !this.resourcesItems;
    }
  }

  /**
   * Thie method will expand & collapse the 'Legal' option and show its submenu in responsive for screen width < 992px
   */
  expandLegal() {
    if (isPlatformBrowser(this.platformId) && this.windowSize < this.setSize) {
      this.legalItems = !this.legalItems;
    }
  }

  /**
   * Thie method will expand & collapse the 'Company' option and show its submenu in responsive for screen width < 992px
   */
  expandCompany() {
    if (isPlatformBrowser(this.platformId) && this.windowSize < this.setSize) {
      this.companyItems = !this.companyItems;
    }
  }
  
}




