import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ZohoService {
  baseOrigin = environment.baseOrigin;
  baseUrl = environment.baseUrl;
  baseUrl97 = environment.baseURL97;
//   private apiUrl = 'https://flow.zoho.in/60019711889/flow/webhook/incoming?zapikey=1001.8075b79cd71f4a6bc667863ee577bfdb.830d936e25582bf5fa23d48e4676305f&isdebug=false';
  //private apiKey = 'Bearer 1001.ccb9247b60f290703a1c0308e15c2c8e.1b142e981d191bb6c43f871ff40e368e';
  private apiUrl = '/api/Workspace/ZohoFlowWebsiteLead';

  constructor(private http: HttpClient) {}

  sendUserData(firstName: string, 
               lastName: string, 
               fullName: string, 
               email:any, 
               WS_SubscriptionStatus: string, 
               leadSource: string, 
               address1 : string,
               address2 : string,
               phoneNo : string,
               city : string,
               state : string,
               country: string,
               orgName : string,
               companySize : string,
               jobTitle : string,
               salutation : string,
               message1 : string,
               message2 : string

               
               ): Observable<any> {
                const headers = new HttpHeaders({
                  'Content-Type': 'application/json',
                });
    //const apiUrl = 'https://flow.zoho.in/60019711889/flow/webhook/incoming?zapikey=1001.ba1d729fb73a251acb7cca2e3e03fb26.a7765a6753d34e335a9c1ab0d16ba10a&isdebug=false';
    //const apiUrl = '/api/Workspace/ZohoFlowWebsiteLead'
    

    const requestBody = {
          
          "FirstName": firstName,
          "LastName": lastName,
          "FullName": fullName,
          "Email" : email,
          "WS_SubscriptionStatus" : WS_SubscriptionStatus,
          "LeadSource": leadSource,
          "Address1"  : address1,
          "Address2"  : address2,
          "PhoneNo"  : phoneNo,
          "City"  : city,
          "State"  : state,
          "Country"  : country,
          "Orgname"  : orgName,
          "CompanySize" : companySize,
          "JobTitle"  : jobTitle,
          "Salutation"  : salutation,
          "Message1"  : message1,
          "Message2"  : message2
    
  };
  console.log("request", JSON.stringify(requestBody));
    //return this.http.post(this.apiUrl, JSON.stringify(requestBody), { headers });
    return this.http.post(`${this.baseUrl}${this.apiUrl}`, JSON.stringify(requestBody), { headers });
  }
}
