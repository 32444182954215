<!-- <div class="myDox2U"><p class="myDox2U-content">My dox2U</p></div> -->
<section class="find-ws overflow-hidden" *ngIf="!workspaceListDisplay">



    <div class="container">

        <div class="row container-info">

         <div class=" find-ws-container" *ngIf="findWS">

               <h1 class="text-center w-100">Sign in to My dox2U</h1>

               <p class="text-center w-100 find-ws-info">Enter your email address and we will show you a list of workspaces you are registered with.</p>

              <div class="w-100 mx-auto ws-parent">

               <div class="enter-email" *ngIf="!hidefoundemail">

                   <form [formGroup]="workspaceForm">

                       <div class="d-flex flex-column form-group position-relative mb-0">

                        <label class=""><p class="label-field mb-1 tunset">Email address<span _ngcontent-ng-c4028038701="" class="text-danger">*</span></p></label>

                       <input type="email" id="FindWsMailId" class="input-ws form-control" formControlName="email" (click)="resetMailmsg($event)" (keypress)="resetMailmsg($event)" (touchstart)="resetMailmsg($event)">

                       <p class="text-danger mb-0 meserr" *ngIf="showInvalidmailmsg">Invalid Email</p>
                       <p class="text-danger mb-0 meserr" *ngIf="showEmptymailmsg">This field cannot be empty</p>

                       </div>


                       <button type="submit" class="FindWsSubmit" (click)="sendWorkspaceInfo()">Go to My dox2U</button> 

                       <!-- <p class="text-right text-danger loader position-absolute">Processing... </p>                    -->

                   </form>

                   <p class="text-center newtodox-para">New to dox2U? <a href="https://app.dox2u.com/onboarding/create-workspace/47" target="_blank">Create a workspace - It’s free</a></p>

               </div> 
               </div>
            </div>

                           <!-- card for found work space --------------------------------------------**************-->

                           <div class="found-WS" *ngIf="foundWS">


                            <h1 class="text-center w-100">Verify your email</h1>
                            <p class="text-center w-100 found-ws-info">We’ve sent a 5 character code to
                                <span class="appendmailid"><a>{{useremail}}</a>
                                <img src="../../../assets/Image/edit.svg" class="edit-icon" (click)="editEmail()"></span>
                            </p>
                            
                            <p class="text-center w-100 found-ws-info marginTop-8">
                                The code is valid for 15:00 mins 
                                <span class="appendmailid"><a (click)="sendWorkspaceInfo()">Resend code</a></span>
                            </p>                
            
                            <div class="found-email">
            
                                <form [formGroup]="form">
             
                                    <div class="d-flex flex-column  position-relative mb-0 align-items-center">
             
                                     <label class="mb-0"><p class="label-field mb-1 tunset">Verification code</p></label>
             
                                     <div class="d-flex input-box-main">
                                        <div class="inputs d-flex align-items-center justify-content-center input-class pt-3 flex-wrap">
                                          <div class="inputs pt-1 " *ngFor="let input of formInput;  index as i last as isLast">
                                            <input class="otp-input text-center form-control font-family-default rounded p-0" #formRow type="text" 
                                              formControlName={{input}} id="input{{i}}" maxlength="1"
                                              (keyup)="keyUpEvent($event, i)" (input)="getPos($event, i)" (keypress)="removeSpecialCharactersCode($event)"
                                                          (paste)="onPaste($event)" [ngClass]="{ 'last-input': isLast }">
                                          </div>
                                          
                                          <!-- <div *ngIf="!showErrorMessage && showSpinner" class="box-1 button--loading"></div> -->
                                        </div>
                                        <p class="err-msg text-left text-danger" *ngIf="showErrorMessage">Invalid OTP</p>
                                        
                                      </div>
             
                                    </div>
             
                                </form>
             
                            </div> 

                            <div
                            class="sign-in-para icons text-basic social-icon">
                            <a href="https://mail.google.com/" class="blue-text text-decoration-none mr-1 d-flex align-items-center"
                              target="_blank"> <img class="social text-decoration-none"
                                src="https://img.icons8.com/color/48/000000/gmail-new.png"
                                style="height: 25px; width: 27px; margin-right:8px;" />Open Gmail</a>
                            <a href="https://outlook.live.com/" class="blue-text text-decoration-none d-flex align-items-center"
                              target="_blank"> <img class="social text-decoration-none ml-4"
                                src="../../../assets/Image/outlook.svg" style="height: 27px; width: 25px;margin-right:8px;" />Open
                              Outlook</a>
                          </div>
            
                           </div>
               

<!------------------------ card for ws not found--------------------------------------------------------------------------- -->
               
               <div class="ws-not-found w-100" *ngIf="wsNotFound">

               <div class="user-mail">
                <img src="../../../assets/Image/ws-not-found.svg" alt="Workspace not found">
                       <h1 class="w-100 text-center margin-48">Oops! Email not found</h1>

                       <p>Looks like your email is not registered with any workspace on dox2U.</p>

                   </div>

                   <p>We would love to have you on-board with us. Go ahead and create your own <br>workspace and try us out </p>
                   <div>
                   <a  href="https://app.dox2u.com/onboarding/create-workspace/47" target="_blank">
                     <button class="button-primary createWS-btn">Create a Workspace</button>
                     
                   </a>
                   <p class="wrong-email">Entered wrong email? <a (click)="editEmail()">Try with a different Email</a></p>
                </div>
                </div>


                <div class="spinner-border text-primary" role="status" *ngIf="isLoading">
                    <span class="sr-only">Loading...</span>
                  </div>
            </div>

        </div>
    
    </section>
                   
            <!-- workpsace list display section -->
            
            <section class="workspace-list row" *ngIf="workspaceListDisplay" id="view-top">
                <div class="ws-header col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h1 class="">Workspaces</h1>
                    <p>Here is a list of all the workspaces <a>{{useremail}}</a> is a part of:</p>
                </div>

                <div class="workspace-info" *ngFor="let workspace of documents">
                    <div class="ws-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="ws-detail col-xl-5 col-lg-5 col-md-4 col-sm-6 col-xs-5">
                        <ng-container *ngIf="workspace.showInitialSAvatar">
                            <app-initials-avatar [name]="workspace.wsname" class="ws-img"></app-initials-avatar>
                        </ng-container>
                        <ng-container *ngIf="!workspace.showInitialSAvatar"> <img  [src]= "workspace.iconPath" class="ws-img" alt="Workspace Icon">
                        </ng-container>
                        
                        <div class="ws-name-container">
                            <p class="ws-name">{{ workspace.wsname }}</p>
                            <p class="ws-mail mb-0" id="wsmail-title" [title]="workspace.wslink">{{ workspace.wslink }}</p>
                          
                        </div>
                    </div>

                    <div class="ws-status col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-2">
                        <ng-container *ngIf="(workspace.wsStatus === 'Enabled' && workspace.userStatus === 'Enabled') ||  (workspace.wsStatus === 'Active')">
                           <img src="../../../assets/Image/active_status.svg">
                        </ng-container>
                        <ng-container *ngIf="workspace.wsStatus === 'Enabled' && workspace.isJoined === '0'">
                            <img src="../../../assets/Image/invited_status.svg">
                        </ng-container>
                        <ng-container *ngIf="workspace.wsStatus === 'Deleted' ||  workspace.wsStatus === 'To Be Deleted'">
                            <img src="../../../assets/Image/deleted_status.svg">
                         </ng-container>
                         <ng-container *ngIf="workspace.userStatus === 'Deleted' && workspace.wsStatus === 'Enabled'">
                            <img src="../../../assets/Image/deleted_status.svg">
                         </ng-container>
                         <ng-container *ngIf="workspace.wsStatus === 'Suspended'">
                            <img src="../../../assets/Image/suspended_status.svg">
                         </ng-container>
                         <ng-container *ngIf="workspace.wsStatus === 'Disabled'">
                            <img src="../../../assets/Image/disabled_status.svg">
                         </ng-container>
                         <ng-container *ngIf="workspace.wsStatus === 'Enabled' && workspace.userStatus === 'Disabled'">
                            <img src="../../../assets/Image/disabled_status.svg">
                         </ng-container>
                         <ng-container *ngIf="workspace.wsStatus === 'No Work Space Found'">
                            <img src="../../../assets/Image/deleted_status.svg">
                         </ng-container>
                         <ng-container *ngIf="workspace.userStatus === 'Reset Password' && workspace.wsStatus === 'Enabled'">
                            <img src="../../../assets/Image/resetPassword_status.svg" class="showResetImg">
                            <img src="../../../assets/Image/reset-pswd-blockImg.svg" class="showResetBlock">
                         </ng-container>
                         <!-- <img [src]="workspace.imgSrc" alt="Workspace Status"> -->
                        </div>
                         
                        
                    <div class="ws-role col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-2">{{ workspace.roleName }}</div>

                    <ng-container *ngIf="workspace.wsStatus === 'Enabled' && workspace.isJoined === '0'">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-button"><button class="button-primary padding-btn" (click)="redirectToSignUp(workspace.wslink)">Sign Up</button></div>
                    </ng-container>
                    <ng-container *ngIf="workspace.wsStatus === 'Enabled' && workspace.userStatus === 'Enabled'">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-button"><button class="button-primary padding-btn" (click)="redirectToSignIn(workspace.wslink)">Sign in</button></div>
                    </ng-container>
                    <!-- <ng-container *ngIf="workspace.wsStatus === 'Suspended' || workspace.wsStatus === 'Disabled'">
                        <div *ngIf="workspace.roleName === 'Owner'" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-button"><button class="button-primary padding-btn" (click)="redirectToSignIn(workspace.wslink)">Sign In</button></div>
                        <div *ngIf="workspace.roleName !== 'Owner'" class=" col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-button"><p class="">Your account is disabled. Please contact your WS Owner / Admin</p></div>
                    </ng-container> -->
                    <ng-container *ngIf="workspace.wsStatus === 'Disabled'">
                        <div *ngIf="workspace.roleName === 'Owner'" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 p-0 ws-button pswd-btn">
                            <span class="tooltip-info">
                                <img src="../../../assets/Image/info.svg" class="tooltip-hover">
                                <span class="tooltip-text">Your Workspace is currently disabled. As the Workspace Owner, you can log in to enable the Workspace</span></span>
                            <button class="button-primary padding-btn" (click)="redirectToSignIn(workspace.wslink)">Sign In</button></div>
                    </ng-container>
                    <ng-container *ngIf="workspace.wsStatus === 'Deleted' || workspace.wsStatus === 'To Be Deleted'">
                        <div *ngIf="workspace.roleName === 'Owner'" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-para"><p class="">Your Workspace has been Deleted</p></div>
                        <div *ngIf="workspace.roleName !== 'Owner'" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-para"><p class="">Your Workspace has been Deleted</p></div>
                    </ng-container>
                    <ng-container *ngIf="workspace.wsStatus === 'Enabled' && workspace.userStatus === 'Deleted'">
                        
                        <div *ngIf="workspace.roleName !== 'Owner'" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-para"><p class="">Your account has been deleted from this Workspace</p></div>
                    </ng-container>
                    <!-- <ng-container *ngIf="workspace.wsStatus === 'To Be Deleted' || workspace.wsStatus === 'Deleted'">
                        <div *ngIf="workspace.roleName === 'Owner'" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-button"><p class="">Workspace Deleted</p></div>
                        <div *ngIf="workspace.roleName !== 'Owner'" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-button"><p class="">Your account has been deleted from this Workspace</p></div>
                    </ng-container> -->
                    <ng-container *ngIf="workspace.userStatus === 'Reset Password' && workspace.wsStatus === 'Enabled'">
                        <div *ngIf="workspace.roleName !== 'Owner'" class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 p-0 ws-button pswd-btn">
                            <span class="tooltip-info">
                            <img src="../../../assets/Image/info.svg" class="tooltip-hover">
                            <span class="tooltip-text">Your account has been marked for a mandatory password reset. Please change your password before logging in to your Workspace</span></span>
                            <button class="button-primary padding-btn" (click)="redirectToChangePassword(workspace.wslink)">Change Password</button></div>
                    </ng-container>

                    <ng-container *ngIf="workspace.wsStatus === 'Disabled'">
                        <div *ngIf="workspace.roleName !== 'Owner'" class=" col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-para"><p class="">Your Workspace has been Disabled. Please contact your WS Owner / Admin</p></div>
                    </ng-container>

                    <ng-container *ngIf="workspace.wsStatus === 'Enabled' && workspace.userStatus === 'Disabled'">
                        <div *ngIf="workspace.roleName !== 'Owner'" class=" col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-para"><p class="">Your account is disabled. Please contact your WS Owner / Admin</p></div>
                    </ng-container>

                     <ng-container *ngIf="workspace.wsStatus === 'Suspended'">
                        <div *ngIf="workspace.roleName !== 'Owner'" class=" col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-para"><p class="">Your Workspace has been Suspended. Please contact your WS Owner / Admin</p></div>
                    </ng-container>

                    <!-- <ng-container *ngIf="workspace.wsStatus === 'Suspended' && workspace.isJoined === '1'">
                        <div *ngIf="workspace.roleName !== 'Owner'" class=" col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-para"><p class="">Your Workspace has been Suspended. Please contact your WS Owner / Admin</p></div>
                    </ng-container> -->

                    <ng-container *ngIf="workspace.wsStatus === 'Suspended'">
                        <div *ngIf="workspace.roleName === 'Owner'" class=" col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-3 ws-button pswd-btn">
                            <span class="tooltip-info">
                                <img src="../../../assets/Image/info.svg" class="tooltip-hover">
                                <span class="tooltip-text">Your Workspace is currently suspended. Please upgrade or clear any dues to regain access to your Workspace</span></span>
                            <button class="button-primary padding-btn" (click)="redirectToSignIn(workspace.wslink)">Sign In</button>
                        </div>
                    </ng-container>
                    </div>
                </div>

                <hr style="width: 41px; height: 8px; border-radius: 10px; background: #D9D9D9; margin-bottom: 16px;">

                <div class="workspace-info m-0 row justify-content-center">
                    <a class="create-ws" (click)="goTocreateWS()"> <img src="../../../assets/Image/add.svg"> <span> <p class=""> Create a new workspace</p></span></a>
                </div>
            </section>

         

    



<div class="gradient-divider"></div>


