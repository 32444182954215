import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { CreateWorkspaceI, ValidateSubDomainI, WorkspaceNameI, getWsIdI } from './interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CreateWorkspaceService {

  constructor(private _apiService: ApiServiceService) { }

  workSpaceName(data: WorkspaceNameI) {
    return this._apiService.post('/api/SubDomain/DomainSuggestion', data);
  }

  /**
   * 
   * @param data 
   * @returns validate the ws url of the user
   */
  validateSubDomain(data: ValidateSubDomainI) {
    return this._apiService.post('/api/SubDomain/Validate', data);
  }

  /**
   * create workspace ID
   */
  getWsId(data: getWsIdI) {
    return this._apiService.post('/api/wstowner/WSCreate', data);
  }

   /**
   * update password created for workspace
   */
   createWorkspace(data: CreateWorkspaceI) {
    return this._apiService.post('/api/wstowner/WSUpdatePassword', data);
  }
}
