<section class="error-page-section">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                <div class="eror-content">
                    <img src="../../../assets/Image/error-404.svg" alt="error-img" class="img-fluid">
                        <p>Here are some helpful links instead : </p>
                    <div class="error-page-nav">
                        <ul>
                            <li><a href="/">Home</a></li>
                            <!--<li><a href="#">Help center</a></li>-->
                            <li><a href="contact-us">Contact us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
