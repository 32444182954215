import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { WSEmailService } from 'src/app/find-workspace.service';
import { EmailService } from 'src/app/email.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-find-workspace',
  templateUrl: './find-workspace.component.html',
  styleUrls: ['./find-workspace.component.scss']
})
export class FindWorkspaceComponent implements OnInit {
  workspaceForm: FormGroup;
  form: FormGroup;
  toFormGroup(elements: any) {
    const group: any = {};

    elements.forEach((key: any) => {
      group[key] = new FormControl("", Validators.required);
    });
    return new FormGroup(group);
  }
  foundWS : boolean = false; /** instance for card for found work space */
  hidefoundemail: boolean = false; /** instance to show the card with email input */
  showInvalidmailmsg: boolean=false; /** instance to show error msg if email is invalid */
  showEmptymailmsg: boolean = false; /** instance to show error msg if email input is empty */
  useremail: any = ''; /** instance to store email id entered by the user */
  wsNotFound: boolean = false; /** instance for the case if card for ws not found */
  isWSLoaded: boolean = false; /** variable set to true if ws info has been found */
  findWS: boolean = true; /** instance for the find workspace page */
  showErrorMessage: boolean = false; /** instance to show error msg */
  formInput = ["input1", "input2", "input3", "input4", "input5"];
  otp: any; /** instance to store otp */
  otpVerificationCompleted: boolean = false; /** variable to maintain the session  */
  @ViewChild('formRow') rows: any;
  otpSend: any; /** instance for checking if otp send is successful */
  vcid: any; /** instance for postdata while validating otp */
  workspaceListDisplay: boolean = false; /** instace to show the workspace details page */
  documents: any[] = []; /** instance to store the response from workspace details api */
  documentStatus: any; /** isntance to store the status of the workspace */
  documentsImage: any[] = [] /** instance to store the workspace image */
  wsname: any; /** instance to store the workspace name */
 imgSrc: any; /** instance to display the image for workspace based on its status */
 isLoading: boolean = false;
 //iconpathname: any;
 showInitialSAvatar: boolean = false; /** instance to check if workspace image exists or not */
 emailId: any; /** instance to maintain emailid of user in session till session is active */

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, public titleService: Title, public findWorkspaceService: WSEmailService, public emailService: EmailService, public fb: FormBuilder, public data: WSEmailService){
    
    /**
     * form validations
     */
    this.workspaceForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]),
    });

    this.form = this.fb.group({
      input1: ['', Validators.required],
      input2: ['', Validators.required],
      input3: ['', Validators.required],
      input4: ['', Validators.required],
      input5: ['', Validators.required],
    });

    this.form = this.toFormGroup(this.formInput);

        /**
     * This method will check if session is maintained then not to ask otp again from the user
     */
        if(sessionStorage.getItem("otpVerificationCompletedCheck")) {
          this.otpVerificationCompleted = true;
           this.findWS = false;
          // this.workspaceListDisplay = true; 
          this.emailId =  sessionStorage.getItem("emailId");
          this.getWorkspaceDetails();
          
        }
  }
  ngOnInit(): void {
    /**
     * To set the header card off
     */
    this.emailService.headerOff.next(true);

    /** For Page Title */
    this.titleService.setTitle("Find your Workspace with dox2U - Document Management System, Forgot your URL? Don't worry! dox2U will help you Find your Workspace in just a few clicks. Search out your Workspace with dox2U today!"); 

    this.useremail =  sessionStorage?.getItem("emailId");

  }

 


  /**
   * 
   * @returns This method will scroll down to getting-started component when clicked on Get Started button from header.
   */
  scrollToGettingStarted() {
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
      const viewportHeight = window.innerHeight;
      const elementHeight = gettingStartedElement.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
      const scrollOptions: ScrollToOptions = {
      top: gettingStartedElement.offsetTop - yOffset,
      behavior: 'smooth',
    };
    window.scrollTo(scrollOptions);
    }
    return;
  }

  /**
   * 
   * @param event Resetting of email error message on keypress
   */
  resetMailmsg(event: Event) {
    this.showInvalidmailmsg = false;
    this.showEmptymailmsg = false;
  }

  /**
   * This method will be called if user wnats to change the entered email id 
   */
  changeEmail() {
    this.foundWS = false;
    this.hidefoundemail = false;
    this.wsNotFound = false;
  }
   

  /**
   * This method will send the otp to the user on email after checking email validations
   */
  sendWorkspaceInfo() {
    if (this.workspaceForm.get('email')?.hasError('required')) {
      this.showEmptymailmsg = true;
    }
    if(this.workspaceForm.get('email')?.hasError('pattern')) {
      this.showInvalidmailmsg = true;
    }

    if (this.workspaceForm.valid) {
      this.isWSLoaded = true;
      this.useremail = this.workspaceForm.value.email;

        this.form.patchValue({
          input1: '',
          input2: '',
          input3: '',
          input4: '',
          input5: ''
        })

        let postData: any;

    
        postData = {
          "vcid": 0,
          "emailId": this.workspaceForm.value.email.toLowerCase(),
          "verificationCode": "",
          "createdDtm": "2024-04-11T13:23:09.421Z",
          "expiryDtm": "2024-04-11T13:23:09.421Z",
          "isActive": true,
          "deliveryStatus": "",
          "description": "",
          "reSend": "",
          "mailHeader": "",
          "returnMessage": ""
        };
        this.data.sendEmailOTP(postData).subscribe((response: any) => {
          this.vcid = response.data.vcid;
          if (response.isSuccess) {
    
           console.log("email verified")
          }
        });
      
      let email = this.workspaceForm.value.email;
      sessionStorage.setItem("emailId", email);


      /**
       * payload for email service
       */
      const requestData = {
        emailId: email,
        reSend:""
      };

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });

      /**
       * This method will send user's workspace on his/her email and display message on the screen
       */
      this.findWorkspaceService.sendWorkspaceList(requestData)
        .subscribe(
          (response) => {
            this.isWSLoaded = false;
            console.log('Workspace information sent successfully:', response);
            this.hidefoundemail = true;
           if (response.returnMessage === 'No Workspace found') {
            // this.foundWS = false;
            this.wsNotFound = true;
            this.findWS = false;
            
          }
          else {
            this.isWSLoaded = false;
            this.wsNotFound = false; 
            if(!this.otpVerificationCompleted) {
            this.foundWS = true;
            this.findWS = false;
            this.hidefoundemail = true;
            }
          }
          },
          (error) => {
            console.error('Error sending workspace information:', error);
          }
        );
    }
  }

  /**
   * This method will be caleed if user wants to edit the email id
   */
  editEmail() {
    this.hidefoundemail = false;
    this.findWS = true;
    this.foundWS = false;
    this.wsNotFound = false;
  }

  resendOtp() {

  }

  /**
   * This method will be called when user enters the otp to move cursor on next postion on every keyup event 
   * and will call the next method to validate the otp.
   * @param event 
   * @param index 
   * @returns 
   */

  keyUpEvent(event: any, index?: any) {
    this.showErrorMessage = false;
    const regex = new RegExp("^[0-9]*$");
    let key = event.key;
    let pos = index;


    if (key === "Backspace") {
      pos = index - 1;
    } else if (regex.test(key)) {
      pos = index + 1;
    }

    if (key === "ArrowRight") {
      pos = index + 1;
    } else if (key === "ArrowLeft") {
      pos = index - 1;
    }

    if (key === "Delete") {
      let input = index + 1;
      input = 'input' + input;
      this.form.get(input)?.patchValue('');
    }

    if (pos >= 0 && pos < this.formInput.length) {

      this.rows.nativeElement.form[pos].focus();

    }
    else {
      console.error(`Invalid position: ${pos}`);
      this.rows.nativeElement.form[0].focus();
    }


    this.otp = [];

    this.otp.push(...this.form?.value?.input1, ...this.form?.value?.input2, ...this.form?.value?.input3, ...this.form?.value?.input4, ...this.form?.value?.input5);

    if (this.otp.length === 5 && !["ArrowLeft", "ArrowRight"].includes(key)) {
      console.log("otp", this.otp.join('').toString())
      if (this.otp.join('').toString() === this.otpSend) {
        this.showErrorMessage = true;
        return;
      } else {
        this.onSubmit();
      }
    }
  }

  getPos(event: any, index?: any) {

    let pos = index;
    pos = pos + 1;
  
  }


/**
 * 
 * @returns This method will be called to check if otp entered by the user is valid or not and if it is valid then call
 * the next method to display workspace information to the user
 */
    onSubmit() {
      if (this.otp.length == 0) {
        console.log("otp blank")
        return;
      }
  
      this.form.patchValue({
        input1: '',
        input2: '',
        input3: '',
        input4: '',
        input5: ''
      })
  
      let st = window.location.toString();
      let arr = st?.split("/");
  
      if (this.otp.length == 0) {
        this.showErrorMessage = true;
        return;
      }

  
      let postData = {
        'EmailId': this.workspaceForm.value.email,
        "verificationCode": this.otp.join('').toString()
      };
  
      this.data.ValidateOTP(postData).subscribe((response: any) => {
        this.otpSend = postData.verificationCode
        if (response.isSuccess) {
          console.log("otp verified")
          this.otpVerificationCompleted = true;
          sessionStorage.setItem("otpVerificationCompletedCheck", this.otpVerificationCompleted.toString())
         // this.workspaceListDisplay = true;
          //this.foundWS = false;

          interface Workspace {
            wsStatus: string;
            imgSrc?: string;
           
        }
        
        this.getWorkspaceDetails();
        

       } 
    
    else {
      this.showErrorMessage = true;
    }
  })
    
    }

    /**
     * This method will display workspace list to the user associated with his/her email id.
     */
    getWorkspaceDetails() {
      this.isLoading = true;
      this.foundWS = false;
      this.wsNotFound = false;
      this.findWS = false;
      this.workspaceListDisplay = false;
                const requestData = {
                  
                  emailId: this.workspaceForm.value.email || this.emailId,
                
                };
             
              this.findWorkspaceService.sendWorkspaceList(requestData).subscribe(
                (response) => {
                    this.isWSLoaded = false;
                    
                    console.log('Workspace information sent successfully:', response);
                    
                    if (response.isSuccess) {
                        console.log("workspaceList");
                        this.documents = response.data;
                        
                        for (const workspace of response.data) {
                          const data = {
                            wsoid: Number(workspace.wsoid), fileName: workspace.wsoid + '.png', folderName: 'profileimage'
                        };
                        this.findWorkspaceService.sendImageUrl(data).subscribe(
                          (blobResponse) => {
                            if (blobResponse.body instanceof Blob && blobResponse.body.size > 0) {
                            const blob = new Blob([blobResponse.body], { type: 'image/png' });
                            const imageUrl = URL.createObjectURL(blob);
                            workspace.iconPath = imageUrl;
                            console.log("iconpath2", imageUrl);
                            }
                            else {
                              workspace.showInitialSAvatar = true;
                            }
                          
                            this.isLoading = false;           
                                this.workspaceListDisplay = true;
                                this.scrollToTop();
                                this.foundWS = false;
                                this.wsNotFound = false;
                                this.findWS = false;
                                this.hidefoundemail = true;
                          },
                          (error) => {
                            // Handle any errors that occur during the request
                            console.error('Error updating workspace icon:', error);
                        }
                        );
                      }
                       
                        // const iconPathRequests = this.documents.map(workspace => {
                        //     return this.findWorkspaceService.sendImageUrl({ wsoid: Number(workspace.wsoid), fileName: workspace.wsoid + '.png', folderName: 'profileimage' });
                        //   // return this.findWorkspaceService.sendImageUrl({ wsoid: 346221, fileName: '346221' + '.png', folderName: 'profileimage' });
                        // });
                          
                        // forkJoin(iconPathRequests).subscribe(
                        //     (imageUrlResponses: any[]) => {
                        //         imageUrlResponses.forEach((imageUrlResponse, index) => {
                        //             const workspace = this.documents[index];
                        //           if (imageUrlResponse.isSuccess) {
                        //             if (imageUrlResponse.body instanceof Blob && imageUrlResponse.body.size > 0) {
                        //               const blob = new Blob([imageUrlResponse.body], { type: 'image/png' });
                        //               const blobUrl = URL.createObjectURL(blob);
                        //               let iconPath = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
                        //               console.log("iconpath2", iconPath);
                        //               workspace.iconPath = iconPath;
                        //               workspace.showInitialSAvatar = false;
                        //             } else {
                                  
                        //               //let iconPath = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
                        //               workspace.showInitialSAvatar = true;
                        //               console.log("running1")
                        //               //console.log("iconpath3", iconPath);
                        //             }
                        //           }
                        //           else {
                        //             //let iconPath = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
                        //             workspace.showInitialSAvatar = true;
                        //             console.log("running2")
                        //               //console.log("iconpath1", iconPath);
                        //           }
                                    
                                   
                        //         });       
                        //         this.isLoading = false;           
                        //         this.workspaceListDisplay = true;
                        //         this.foundWS = false;
                        //         this.wsNotFound = false;
                        //         this.findWS = false;
                        //         this.hidefoundemail = true;
                        //     },
                        //     (error: any) => {
                        //         console.error('Error fetching iconPaths:', error);
                        //     }
                        // );
                        
                    }
                },
                (error) => {
                    console.error('Error sending workspace list:', error);
                }    
            ); 
          
    }

    scrollToTop() {
      window.scrollTo(0, 0);
    }
    

    /**
     * 
     * @param wsStatus This method will set the workspace image based on the ws status.
     * @returns 
     */
   getImageSrcBasedOnStatus(wsStatus: string) {
      switch (wsStatus) {
          case 'Active':
              return '../../../assets/Image/active_status.svg';
          case 'Deleted':
          case 'To Be Deleted':
              return '../../../assets/Image/deleted_status.svg';
          case 'Suspended':
              return '../../../assets/Image/suspended_status.svg';
          case 'Disabled':
              return '../../../assets/Image/disabled_status.svg';
          default:
              return '';
      }

    
 }
  

    /**
 * remove Special Characters
 */
    removeSpecialCharactersCode(event:KeyboardEvent) {
      const regex = new RegExp("^[0-9]*$");
      const regPattern = /^[0-9]+$/;
      let key = event.key;

      if (!regex.test(key) || !regPattern.test(key)) {
        event.preventDefault();
      }
    }

      /**
 * paste copied verification code
 * @param event
 */
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData?.getData('text').replace(/ /g, "")?.split('');

    if (pastedText !== undefined) {
    this.form.patchValue({
      input1: pastedText[0] || '',
      input2: pastedText[1] || '',
      input3: pastedText[2] || '',
      input4: pastedText[3] || '',
      input5: pastedText[4] || '',
    })
    }
  }

  /**
   * This method will redirect the user to sign up page of create workspace
   * @param wslink 
   */
  redirectToSignUp(wslink: string) {
    console.log("wslink", wslink)
    const signUpUrl = 'https://' + wslink + '/onboarding/sign-up/';
    window.open(signUpUrl, '_blank');
  }

  /**
   * This method will redirect the user to sign in page
   * @param wslink 
   */
  redirectToSignIn(wslink: string) {
    console.log("wslink", wslink)
    const signInUrl = 'https://' + wslink + '/onboarding/sign-in/';
    window.open(signInUrl, '_blank');
  }

  /**
   * This method will redirect the user to change password page
   * @param wslink 
   */
  redirectToChangePassword(wslink: string) {
    console.log("wslink", wslink)
    const changePswdUrl = 'https://' + wslink + '/onboarding/change-password/';
    window.open(changePswdUrl, '_blank');
  }

  /**
   * This method will redirect the user to  crate workspace page
   */
  goTocreateWS() {
    window.open('https://app.dox2u.com/onboarding/create-workspace/47', '_blank');
  }
}




