import { Component, Input, OnInit } from '@angular/core';
import { productDucomentH } from 'src/app/interfaces/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-r-image-left-text',
  templateUrl: './product-r-image-left-text.component.html',
  styleUrls: ['./product-r-image-left-text.component.scss']
})
export class ProductRImageLeftTextComponent implements OnInit {
  constructor(private router: Router){}
  @Input() imgUrl:string="";
  
  @Input () content!:productDucomentH;
  blue_circle:string="../../../../assets/Image/document-handling/blue-circle-tick.svg"

  ngOnInit(): void {
    
  }

  navigateTo() {
    if(this.content.textHyper === 'Guest access') {
      this.navigateToGuestaccess();
    }
    console.log("text", this.content.textHyper)

    if(this.content.textHyper === 'Try now') {
      this.navigateToGettingStarted();
    }

    if(this.content.textHyper === 'See how Guest Access works') {
      this.navigateToGuestAccessPage();
    }

  }

  navigateToGuestaccess() {
    this.router.navigate(['guest-access']);
  }

  navigateToGuestAccessPage() {
    this.router.navigate(['guest-access']);
  }

  navigateToGettingStarted() {
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
      const viewportHeight = window.innerHeight;
      const elementHeight = gettingStartedElement.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
    const scrollOptions: ScrollToOptions = {
      top: gettingStartedElement.offsetTop - yOffset,
      behavior: 'smooth',
    };
    window.scrollTo(scrollOptions);
     // gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
