<section class="help-business common-padd overflow-hidden">
    <div class="container">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="tabs-section-Tbar">
                    <h3 class="size-fourty mb-3">How can we help your business?</h3>
                    <p class="common-para"> Maximize efficiency with simplicity</p>
                </div>

                <div class="go-paperless-tabs">
                    <ul class="nav nav-pills mb-3 d-flex justify-content-center align-items-center" id="pills-tab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Save time?</a>
                          
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Reduce effort?</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Make money?</a>
                        </li>
                      </ul>
                      
                    <div class="tab-content home-tabs-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <div class="row d-flex align-items-center mtop80">
                            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 TEXTCENTER">
                                    <!-- <h2 class="size-fourty">Information Security</h2> -->
                                    <p class="common-para-information mb-4 centertext">Instant access to information, leading to faster and more informed decision making by</p>
                                   <div class="minwidt350" style="width:100%">
                                 <div class="d-flex common-para2 mb-2">
                                 <li></li>Streamlining workflows
                                 </div>
                                 <div class="d-flex common-para2 mb-2"><li></li>Centralizing access to documents</div>
                               
                                   <div class="d-flex common-para2 mb-2"><li></li>Avoiding hunt for information</div>
                          <div class="d-flex common-para2 mb-2 "><li></li>Eliminating dependencies on co-workers</div>
                                <div class="d-flex common-para2 mb-2"><li></li>Enabling quick search and retrieval</div>
                                   </div>
                                </div>
                                <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none "></div>

                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <img src="../../../assets/Image/homepage/dox2u_home_paperless.svg" style="height:276px"  alt="Save time" class="img-fluid drop-shadow"
                                        data-aos="fade-left" data-aos-duration="1000" loading="lazy">
                                </div>
                            </div>

                        </div>

                        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                            aria-labelledby="pills-profile-tab">
                            <div class="row d-flex align-items-center small-views mtop80">
                            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 TEXTCENTER">
                                    <!-- <h2 class="size-fourty">Information Security</h2> -->
                                    <p class="common-para-information mb-4 centertext">No more manual data entry! Intelligent document processing reduces effort by</p>
                                   <div class="minwidt350" style="width:100%">
                                 <div class="d-flex common-para2 mb-2">
                                 <li></li>Converting unstructured docs to structured info
                                 </div>
                                 <div class="d-flex common-para2 mb-2"><li></li>Automating information capture</div>
                               
                                   <div class="d-flex common-para2 mb-2"><li></li>Validation of extracted information</div>
                          <div class="d-flex common-para2 mb-2"><li></li>Identify missing & incomplete information</div>
                                <div class="d-flex common-para2 mb-2"><li></li>Document classification</div>
                                   </div>
                                </div>
                                <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none"></div>

                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <img src="../../../assets/Image/homepage/dox2u_home_effort.svg" alt="Easy Retrieval" height="276" class="" width="100%"
                                        data-aos="" data-aos-duration="2500" loading="lazy">
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                            aria-labelledby="pills-contact-tab">
                            <div class="row d-flex align-items-center small-views mtop80">
                                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 TEXTCENTER">
                                    <!-- <h2 class="size-fourty">Information Security</h2> -->
                                    <p class="common-para-information mb-4 centertext">Tangible contributions to bottom-lines, avoid costs of
                                        managing info on paper with</p>
                                   <div class="minwidt350" style="width:100%">
                                 <div class="d-flex common-para2 mb-2">
                                 <li></li>Improved efficiency
                                 </div>
                                 <div class="d-flex common-para2 mb-2"><li></li>Reduced costs</div>
                               
                                   <div class="d-flex common-para2 mb-2"><li></li>Faster decision-making</div>
                          <div class="d-flex common-para2 mb-2"><li></li>Enhanced collaboration</div>
                                <div class="d-flex common-para2 mb-2"><li></li>Higher compliance </div>
                                   </div>
                                </div>
                                <div class="col-xxl-1 col-xl-1 col-lg-1 d-lg-block d-md-none"></div>

                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                                    <img src="../../../assets/Image/homepage/dox2u_home_savemoney.svg"  height="276"width="85%" alt="Information Security"
                                        class="" data-aos="" data-aos-duration="2500"
                                        loading="lazy">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
