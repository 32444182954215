<section class="right-image-section common-padd overflow-hidden">
    <div class="container">
        <div class="row d-flex align-items-center small-views content-container">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0">
                <div class="content-box">
                    <h6 class="heading-6 text-primary">{{content.heading}}</h6>
                    <h3 class="size-fourty">{{content.subTitle}}</h3>
                    <p class="common-para">{{content.description}}<b>{{content.boldText}}</b>{{content.description1}}<a *ngIf="anchorTags" class="tally-solutions" href="https://help.tallysolutions.com/tally-prime/tdls-and-add-ons/deploy-tdls-and-add-ons-tally/" target="_blank">click here</a><b>{{content.boldText1}}</b></p>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center startposition endposition
            ">
                <img alt="online document management system" src={{imgUrl}}
                class="img-fluid img-size" loading="lazy" draggable="false" data-aos="fade-left" data-aos-duration="2500" loading="lazy">
            </div>
        </div>
    </div>
</section>
