<section class="common-padding-80 common-padd overflow-hidden">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="blog-top-bar text-center">
                    <h6 class="heading-6">TESTIMONIALS</h6>
                    <h3 class="size-fourty">Find out why our customers & partners love us</h3>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-7 col-md-9 col-sm-12">
             <div id="myCarousel1" class="carousel slide custom-carousel" data-ride="carousel">
                  <ol class="carousel-indicators" id="myCarousel11">
                    <li *ngFor="let item of testimonialItems; let i = index" [attr.data-target]="'#myCarousel1'" [attr.data-slide-to]="i" [class.active]="i === 0"></li>
                  </ol>
                  <div class="carousel-inner">
                    <div
                      *ngFor="let item of testimonialItems; let i = index"
                      class="carousel-item"
                      [class.active]="i === 0">
                      <div class="car-active-first">
                        <span><img src="../../../assets/Image/testimonials/imagetest1.svg" class="mb-2 pb-2" /></span>
                        <p class="d-flex text-center common-para">{{ item.content }}</p>
                        <span class="d-flex justify-content-end"
                          ><img src="../../../assets/Image/testimonials/imagetest2.svg"
                        /></span>
                      </div>
                      <img [src]="item.imageUrl" class="image-post" alt="..." />
                      <div class="d-flex justify-content-center flex-column align-items-center car-active-sec">
                        <p class="common-para mb-0 text-center mb-2 pb-1 color-00">{{ item.author }}</p>
                        <p class="common-para mb-0 text-center mb-0 color-00">{{ item.company }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- <button class="carousel-control-prev testimonial-prev arrowbg" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next testimonial-next arrowbg" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
              </button> -->
                  <a class="carousel-control-prev" href="#myCarousel1" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#myCarousel1" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
            </div>
        </div>
       
    </div>
</section>
